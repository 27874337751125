.seller-mobile-menu-wrapper {
  width: 232px;
  box-shadow: 0 6px 12px 0 $color-grey-2300;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;

  .seller-mobile-menu {
    display: flex;
    align-items: center;
    padding: 18px 15px;
    gap: 15px;
    border-bottom: solid 1px $color-grey-1000;
    cursor: pointer;
    &:last-child {
      border-bottom: unset;
    }
    &:hover {
      background-color: $color-blue-900;
    }
    p {
      font-size: $fs-14;
      font-weight: 600;
      line-height: 1.71;
      color: $color-grey-3900;
      cursor: pointer;
    }
    span {
      font-size: $fs-24;
      color: $color-gray-200;
    }
  }
}
.seller-mobile-menu-main {
  position: absolute;
  right: 0px;
  top: 55px;
  z-index: $z-index-1;
}

@media screen and (max-width: 560px) {
  .menu-bg-overlay {
    background-color: $color-grey-4300;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: $z-index-3;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .seller-mobile-menu-main {
    position: unset;
  }

  .seller-mobile-menu-wrapper {
    width: 100%;
    border-radius: 8px 8px 0 0;
    position: fixed;
    background-color: #fff;
    right: 0px;
    top: unset;
    bottom: 0;
    z-index: $z-index-7;
    padding: 25px 0;
    &::after {
      content: '';
      background-color: rgba(0, 0, 0, 0.2);
      height: 4px;
      width: 28px;
      display: block;
      position: absolute;
      top: 15px;
      border-radius: 2px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
