.categories-section {
  padding: 40px 0;
  border-bottom: 6px solid $color-grey-4100;

  &_wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    align-items: baseline;

    &_header {
      font-size: $fs-24;
      font-weight: bold;
      line-height: 1.25;
    }

    a {
      color: $color-primary;
      font-size: $fs-12;
      font-weight: 600;
      line-height: 2;
      text-align: left;
      cursor: pointer;
    }
  }

  .swiper-inner-wrapper {
    padding: unset;
    margin: 0 31px;
  }
}

@media screen and (max-width: 1024px) {
  .categories-section {
    .swiper-inner-wrapper {
      padding: unset;
      margin: 0 32px;
      overflow-x: scroll;
    }
  }
}

@media screen and (max-width: 768px) {
  .categories-section {
    .swiper-inner-wrapper {
      padding: 0 39px;
      margin: unset;
    }
  }
}

@media screen and (max-width: 560px) {
  .categories-section {
    padding: 30px 0 0;

    &_wrapper {
      align-items: flex-start;
      margin-bottom: 15px;
      flex-direction: column;
      padding: 0 20px;

      &_header {
        font-size: $fs-16;
      }

      a {
        display: none;
      }
    }

    .swiper-inner-wrapper {
      padding: 0 20px;
      margin: unset;
    }
  }
}
