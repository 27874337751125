/**
 * Please file all your z-index values in here
 * and sort them correctly by value.
 */

$z-index-0: 0; //default
$z-index-1: 100; //use this for any layer above the default
$z-index-111: 111; // should be removed
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;

$zindex-overlay-header-category: $z-index-1;
$zindex-overlay-language: $z-index-111;
$zindex-header-category: $z-index-2;
$zindex-overlay-header-search: $z-index-3;
$zindex-header-search: $z-index-4;
$zindex-overlay-top-level: $z-index-5; // top level overlay above anything including header ( overlay of modal , Offcanvas ..etc )
$zindex-top-level: $z-index-6; // content of top level overlay
