.progressbar {
  width: 100%;
  margin-left: 14px;
  position: relative;
  text-align: start;

  label {
    font-size: $fs-11;
    letter-spacing: -0.25px;
  }

  &__top {
    color: $color-black-true;
    font-weight: 600;
  }

  &__inside {
    position: absolute;
    left: 6px;
    color: $color-black-true;
    text-transform: none;
    white-space: nowrap;
    top:1px;
  }

  progress {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid $color-grey-1000;
    box-shadow: 0 3px 6px 0 $color-grey-3300;
    height: 14px;
    border-radius: 1px;
    width: 100%;
    background-color: white;
  }

  progress::-webkit-progress-value {
    background-image: linear-gradient(
                    to right,
                    $color-orange-100,
                    $color-orange-400
    );
    background-color: $color-orange-100;
  }

  progress[value]::-webkit-progress-bar {
    background-color: white;
  }

  progress[value]::-moz-progress-bar {
    background-image: linear-gradient(
                    to right,
                    $color-orange-100,
                    $color-orange-400
    );
  }
}

body[dir='rtl'] {
  .progressbar {
    margin-left: initial;
    margin-right: 0.625rem;

    &__inside {
      bottom:1.5px;
      top: unset;
    }
  }
}

@media only screen and (max-width: 560px) {
  .progressbar label {
    font-size: $fs-11;
    font-weight: 500;
  }
}
