.button-cancel {
  color: $color-grey-2300;
  background-color: $color-grey-3200;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 200ms;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
}
.button-cancel--medium {
  width: 32px;
  height: 32px;
  .button-cancel__icon {
    font-size: 24px;
  }
}
.button-cancel--small {
  width: 24px;
  height: 24px;
  min-width: 24px;
  .button-cancel__icon {
    font-size: 18px;
    line-height: 1px;
  }
}
.button-cancel:hover {
  color: $color-grey-3000;
}
.button-cancel:focus,
.button-cancel:focus-visible {
  color: $color-primary;
}
