.auth-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 28px 375px 23px 375px;
  &__legal-links {
    display: flex;
    justify-content: space-between;
    font-size: $fs-12;
    font-weight: 500;
    a {
      color: $color-gray-200 !important;
    }
    a:hover {
      color: $color-blue-200 !important;
      transition: 200ms !important;
    }

    &__contactus-page::after,
    &__terms-page::after {
      content: '|';
      color: $color-grey-1000;
      margin: 0 10px;
      height: 2px;
    }
  }
  &__right-reserved {
    font-size: $fs-12;
    font-weight: 500;
    color: $color-black-true;
  }
}

@media only screen and (max-width: 912px) {
  .auth-footer-container {
    display: block;
    padding: 30px 0px;
    &__right-reserved {
      text-align: center;
      .mazadatInc {
        display: block;
      }
      .footer-copyright {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__legal-links {
      display: none;
    }
  }
}

@media only screen and (min-width: 913px) and (max-width: 1280px) {
  .auth-footer-container {
    padding: 28px 89px 23px 60px;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1500px) {
  .auth-footer-container {
    padding: 28px 87px 23px 110px;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 1700px) {
  .auth-footer-container {
    padding: 28px 250px 23px 180px;
  }
}
