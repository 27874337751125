.consent-banner {
  position: fixed;
  bottom: 0;
  padding: 0px 28% 0px 28%;
  height: 221px;
  width: 100%;
  background: url(../../../assets/images/letUsTalk.svg),
    linear-gradient(97deg, $color-violet-300, $color-blue-100);
  z-index: $z-index-2;
  display: flex;
  align-items: center;
  &__cookiesIcon {
    img {
      width: 117px;
      height: 117px;
      opacity: 1;
    }
  }

  &__content {
    padding: 30px 0px 36px 0px;
    display: flex;
    margin-left: 30px;
    flex-direction: column;

    h3 {
      color: #fff;
      margin-bottom: 20px;
      font-size: 21px;
      font-weight: 600;
    }
    p {
      color: #fff;
      font-size: 15px;
      margin-bottom: 20px;
      line-height: 1.6;
      width: auto;
    }
    button {
      width: 186px;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
body[dir='rtl'] {
  .consent-banner {
    padding: 0px 31% 0px 31%;
    &__cookiesIcon {
      img {
        transform: scaleX(-1);
      }
    }
    &__content {
      margin-right: 30px;
      margin-left: 0px;
    }
  }
}
@media only screen and (max-width: 912px) {
  .consent-banner {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 1024px) {
  .consent-banner {
    height: auto;
    padding: 0px 34px 0 34px;
    &__cookiesIcon {
      img {
        display: none;
      }
    }
    &__content {
      padding: 36px 0px 30px 0px;
      margin-left: 0;
      margin-right: 0;
      align-items: center;

      h3 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
        margin-bottom: 30px;
        line-height: 1.7;
        width: auto;
        text-align: center;
      }
    }
  }
  body[dir='rtl'] {
    .consent-banner {
      padding: 0px 34px 0 34px;
      display: block;
      &__content {
        margin-right: 0px;
        margin-left: 0px;
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px) {
  .consent-banner {
    &__content {
      padding: 0px;
      margin-left: 30px;
      margin-right: 0;

      h3 {
        margin-bottom: 10px;
        font-size: 18px;
      }
      p {
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
  }
  body[dir='rtl'] {
    .consent-banner {
      &__content {
        margin-right: 30px;
        margin-left: 0px;
      }
    }
  }
}

@media only screen and (min-width: 1271px) and (max-width: 1450px) {
  .consent-banner {
    padding: 0px 23% 0px 23%;
  }
  body[dir='rtl'] {
    .consent-banner {
      padding: 0px 24.5% 0px 24.5%;
    }
  }
}

@media only screen and (min-width: 1451px) and (max-width: 1650px) {
  .consent-banner {
    padding: 0px 25% 0px 25%;
  }
  body[dir='rtl'] {
    .consent-banner {
      padding: 0px 27% 0px 27%;
    }
  }
}

@media only screen and (min-width: 1651px) and (max-width: 1760px) {
  .consent-banner {
    padding: 0px 26% 0px 26%;
  }
  body[dir='rtl'] {
    .consent-banner {
      padding: 0px 29% 0px 29%;
    }
  }
}
