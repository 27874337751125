.registration-wrapper {
  align-items: flex-start !important;
}

.registration-form {
  width: 370px;

  .info {
    &__title {
      text-align: center;
    }
  }

  &_password-wrapper {
    .text-field__underline {
      &__strength-password {
        margin-top: 3px;
        margin-right: 26px;
      }

      .error-message {
        display: none;
      }
    }

    position: relative;

    .discover-more {
      .question-mark {
        position: absolute;
        font-size: $fs-14;
        top: 50px;
        right: 16px;
      }
    }
  }

  form {
    width: 100%;
  }

  &__footer {
    font-size: $fs-12;
    font-weight: 600;
    text-align: center;
  }

  .already-account {
    padding: 20px 0;

    p {
      font-size: $fs-14;
      font-weight: 600;
      line-height: 1.29;
      color: $color-grey-3900;
    }

    a {
      color: $color-primary;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 1.3px;
        text-underline-position: under;
      }
    }
  }

  .policy-terms-mobile {
    color: $color-jungle-1000;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 1.3px;
      text-underline-position: under;
    }
  }

  .icon {
    font-size: $fs-14;
    color: $color-gray-200;
    margin-left: 4px;
  }

  .recaptcha-reg {
    margin: 30px 0px;
    display: flex;
    justify-content: center;
  }

  .registration-discover-more {
    display: flex;
    align-items: center;
    gap: 8px;

    &__label {
      white-space: nowrap;
    }

    &__content {
      .popover-wrapper {
        top: 32px;

        &::before {
          right: initial;
        }
      }
    }
  }

  .registration-discover-more.registration-discover-more--invitation {
    .popover-wrapper::before {
      left: 123px;
    }
  }

  .registration-discover-more.registration-discover-more--registered {
    .popover-wrapper::before {
      left: 160px;
    }
  }
}

body[dir='rtl'] {
  .registration-form {
    &_password-wrapper {
      .text-field__underline {
        &__strength-password {
          margin-right: unset;
          margin-left: 26px;
        }
      }

      .discover-more {
        .question-mark {
          right: unset;
          left: 16px;
        }
      }
    }
  }

  .registration-form_password-wrapper .discover-more .popover-wrapper {
    &::before {
      right: unset;
      left: 18px;
    }
  }
}

.registration-form_password-wrapper .discover-more .popover-wrapper {
  top: 75px;

  &::before {
    right: 18px;
  }
}

.endLabel-icon {
  font-size: $fs-24;
}

@media only screen and (max-width: 912px) {
  .registration-form {
    width: 100%;

    &__footer {
      text-align: center;

      br {
        display: none;
      }

      .by-contine-text {
        display: block;
      }
    }
  }
}

body[dir='rtl'] {
  .registration-form {
    .icon {
      margin-left: 0px;
      margin-right: 4px;
    }
  }

  .registration-form
  .registration-discover-more.registration-discover-more--invitation
  .popover-wrapper::before {
    right: 72px;
    left: initial;
  }

  .registration-form
  .registration-discover-more.registration-discover-more--registered {
    .popover-wrapper::before {
      right: 96px;
      left: initial;
    }
  }
}

@media screen and (max-width: 560px) {
  .registration-form {
    &__phone-wrapper {
      gap: 15px;

      .text-field--phone-input {
        width: 68%;
      }

      .text-field--code-input {
        width: 27%;
      }
    }

    .by-contine-text {
      font-size: $fs-10;
      font-weight: 500;
      line-height: 1.5;
      color: $color-gray-200;
    }

    .policy-terms-mobile {
      font-size: $fs-10;
      font-weight: 600;
      color: $color-grey-3900 !important;

      &:hover {
        color: $color-jungle-1000 !important;
        text-decoration: none;
      }
    }
  }
}
