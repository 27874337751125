.mazadat-wrapper {
  display: flex;
  flex-direction: column;
  a {
    color: $color-gray-200;
    font-size: $fs-12;
    margin-bottom: 10px;
    font-weight: 500;
  }
  a:hover {
    color: $color-blue-200;
    transition: 200ms;
  }
  h5 {
    margin-bottom: 10px;
    font-size: $fs-13;
    font-weight: 600;
  }
}
