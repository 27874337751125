.auth-wrapper {
  display: flex;
  width: 100vw;
  transition: left 0.5s;
  padding: 145px 25% 185px 17%;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - (173px + 72px));
  gap: 130px;

  &__img {
    max-width: 616px;
    max-height: 618px;
    aspect-ratio: 3.9/3;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    max-width: 370px;
    min-width: 330px;
  }

  &.registration-wrapper-data {
    padding: 125px 24% 160px 5%;

    .auth-wrapper__img {
      max-width: 835px;
      width: 835px;
    }
  }
}

body[dir='rtl'] {
  .auth-wrapper {
    padding: 145px 17% 185px 25%;

    &.registration-wrapper-data {
      padding: 125px 5% 160px 24%;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .auth-wrapper {
    gap: 30px;
    padding: 80px 14% 90px 12%;

    &.registration-wrapper-data {
      padding: 125px 10% 160px 5%;
    }
  }
  body[dir='rtl'] {
    .auth-wrapper {
      padding: 80px 12% 90px 14%;

      &.registration-wrapper-data {
        padding: 125px 5% 160px 10%;
      }
    }
  }
}

@media only screen and (max-width: 1380px) {
  .auth-wrapper {
    padding: 80px 11%;

    &.registration-wrapper-data {
      padding: 125px 5% 160px;
    }
  }
  body[dir='rtl'] {
    .auth-wrapper {
      &.registration-wrapper-data {
        padding: 125px 5% 160px;
      }
    }
  }
}

@media only screen and (max-width: 912px) {
  .auth-wrapper {
    flex-direction: column;
    padding: 0 20px 0 20px;
    justify-content: initial;
    width: 100%;
    gap: initial;

    &__content {
      max-width: unset;
      min-width: unset;
      margin: 0 auto;
      width: 90%;
    }

    &.registration-wrapper-data {
      padding: 30px 20px 0;

      .auth-wrapper__img {
        width: 265px;
      }
    }
  }
  .auth-wrapper__img {
    width: 265px;
    max-height: 110px;
    margin: 30px auto;
  }
  body[dir='rtl'] {
    .auth-wrapper {
      padding: 0 20px 0 20px;

      &.registration-wrapper-data {
        padding: 30px 20px 0;
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .auth-wrapper__content {
    width: 100%;
  }
}
