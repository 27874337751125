.tab-container {
  &__item {
    display: inline-block !important;
    padding: 0.25rem 0.5rem;
    color: rgb(153, 150, 150);
    cursor: pointer;
    font-size: $fs-20;
  }
  &__item:hover,
  &__item:active {
    color: $color-blue-100;
  }
  .react-tabs__tab--selected {
    color: $color-blue-100;
  }
}
.react-tabs__tab-list {
  margin: unset;
  padding: unset;
}
