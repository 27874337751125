/**
 * Please file all font-size values in here
 */

$fs-16-px: 16px;
$fs-8: 0.5 * $fs-16-px;
$fs-9: 0.6 * $fs-16-px; /* 9px */
$fs-10: 0.63 * $fs-16-px; /* 10px */
$fs-11: 0.6875 * $fs-16-px; /* 11px */
$fs-12: 0.75 * $fs-16-px; /* 12px */
$fs-13: 0.8125 * $fs-16-px; /* 13px */
$fs-14: 0.875 * $fs-16-px; /* 14px */
$fs-15: 0.9375 * $fs-16-px; /* 15px */
$fs-16: 1 * $fs-16-px; /* 16px */
$fs-18: 1.125 * $fs-16-px; /* 18px */
$fs-20: 1.25 * $fs-16-px; /* 20px */
$fs-21: 1.3125 * $fs-16-px; /* 21px */
$fs-22: 1.375 * $fs-16-px; /* 22px */
$fs-24: 1.5 * $fs-16-px; /* 24px */
$fs-25: 1.56 * $fs-16-px; /* 25px */
$fs-27: 1.68 * $fs-16-px; /* 27px */
$fs-28: 1.75 * $fs-16-px; /* 28px */
$fs-29: 1.8125 * $fs-16-px; /* 29px */
$fs-32: 2 * $fs-16-px; /* 32px */
$fs-40: 2.5 * $fs-16-px; /* 40px */
$fs-42: 2.625 * $fs-16-px; /* 42px */
$fs-48: 3 * $fs-16-px; /* 48px */
$fs-54: 3.6 * $fs-16-px; /* 54px */
$fs-57: 3.6 * $fs-16-px; /* 57px */
$fs-60: 3.75 * $fs-16-px; /* 60px */
$fs-78: 4.875 * $fs-16-px; /* 78px */
