.downloadApp-wrapper {
  display: flex;
  flex-direction: column;
  h5 {
    margin-bottom: 18px;
    font-size: 14px;
    font-weight: 600;
  }
  .appstores-logo {
    display: flex;
    flex-wrap: wrap;
    img {
      height: 40.2px;
      width: 122.7px;
    }
    a:nth-child(2) {
      margin-left: 10px;
      margin-right: 17px;
    }
  }
}
body[dir="rtl"] {
  .appstores-logo {
    .a:nth-child(2) {
      margin-right: 10px;
      margin-left: 17px;
    }
  }
}

@media only screen and (max-width: 540px){
  .downloadApp-wrapper .appstores-logo img {
    height: 35.2px;
    width: 119.7px;
  }
}
