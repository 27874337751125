$header-height: 68px;
$mobile-header-height: 50px;
$footer-height: 0px;

$content-height: calc(800px - #{$header-height} - #{$footer-height});

// override the default value of react image gallery css to compensate for our header
.post-details .fullscreen .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 310px);
}

// Override autofill background color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: $color-gray-200;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
  background: transparent;
  color: $color-gray-200;
}
