.bread-crumb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: $fs-12;
  font-weight: 600;
  border-bottom: 1px solid $color-gray-800;
  padding: 10px 30px;
  align-items: center;
  gap: 20px;
  background-color: $color-jungle-900;

  .bread-crumb__left-side {
    flex: 1;
  }

  .bread-crumb__right-side {
    display: flex;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: url(../../../assets/icons/right-arrow.svg);
    padding: 0;
    display: block;
    width: 12px;
    height: 12px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 2px;
  }

  .breadcrumb-item.disabled {
    a {
      color: $color-gray-200;
      cursor: unset;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
      text-decoration: none;

      &:hover {
        color: $color-gray-200;
        text-decoration: none;
      }
    }
  }

  .breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 10px;

    a {
      color: $color-primary;
      transition: 200ms;
      cursor: pointer;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
    }

    a:hover {
      color: $color-secondary;
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}

.breadcrumb {
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: nowrap;
}

body[dir='rtl'] {
  .breadcrumb-item + .breadcrumb-item::before {
    transform: rotate(180deg);
    margin-bottom: unset;
    margin-top: 2px;
  }
}

@media screen and (max-width: 560px) {
  .bread-crumb__right-side {
    display: none !important;
  }

  .bread-crumb {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 912px) {
  .bread-crumb {
    .breadcrumb-item:not(:first-child):not(:last-child) {
      display: none;
    }
  }
}
