.pagination {
  margin: 0.25rem 0.75rem 0.25rem 0;
  text-align: center;

  &__item {
    border-radius: 5px;
    color: black;
    display: inline-block !important;
    padding: 0.25rem 0.75rem;
    background-color: #f1efef;
    margin: 0 0.2rem;
    border: none;
    cursor: pointer;
  }

  &__prev,
  &__next {
    background-color: #f1efef;
    border-radius: 5px;
    color: black;
    display: none;
    text-align: center;
    padding: 0.2rem 0.5rem;
    border: none;
    cursor: pointer;
  }

  &__item:hover,
  &__item--selected {
    background-color: #e7e6e6;
  }

  &__item--after {
    display: none;
  }

  &__item--before {
    display: none;
  }
}
