.home-widgets {
  margin: 50px 15%;
  .widgets-container {
    display: flex;
    gap: 2%;
  }
}
.widgets{
  padding:20px 95px;
  .slick-list{
    padding: 0 25px;
  }
  .slick-dots {
    bottom: 0;
    left: 0;
  }
  .slick-slide img{
    display: unset !important; //overrides slack image style
  }
}

@media screen and (max-width: 1705px) {
  .home-widgets {
    margin: 50px 12%;
  }
}

@media screen and (max-width: 1570px) {
  .home-widgets {
    margin: 50px 9%;
  }
}
@media screen and (max-width: 1440px) {
  .home-widgets {
    margin: 50px 4%;
  }
}
@media screen and (max-width: 1300px) {
  .home-widgets {
    .widgets-container {
      gap: 0.5%;
    }
  }
}

@media screen and (max-width: 1250px) {
  .home-widgets {
    margin: 50px 2%;
    .widgets-container {
      gap: 2%;
      .widget-item {
        &__content {
          h4 {
            font-size: $fs-11;
          }
          p {
            font-size: $fs-10;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .home-widgets {
    .widgets-container {
      display: grid;
      grid-template-columns: 38% 38%;
      justify-content: center;
      gap: 2.8%;
    }
  }
}

@media screen and (max-width: 642px) {
  .home-widgets {
    .widgets-container {
      grid-template-columns: 48% 45%;
      gap: 25px;
    }
  }
}

@media screen and (max-width: 400px) {
  .home-widgets {
    .widgets-container {
      grid-template-columns: 53% 45%;
      gap: 15px;
      .widget-item {
        &__content {
          h4 {
            font-size: $fs-9;
          }
        }
      }
    }
  }
}
