/**
 * Please file all colors here
 * and sort them correctly by value,
 * prefer using steps of 100 for color gradients
 */

/* Pure colors. */
$color-black-true: #000;
$color-white-true: #fff;

//@TODO
$color-yellow-100: #ffce00;
$color-attention-500: #cd3c14;
$color-attention-600: #f3d3ca;
$color-success-500: #32c832;
// @TODO : remove unused colors
$color-gray-100: rgba(0, 0, 0, 0.7);
$color-gray-200: rgba(0, 0, 0, 0.6);
$color-gray-300: #091f1f61;
$color-gray-400: #909090;
$color-gray-500: #808080;
$color-gray-550: #d4d6de;
$color-gray-600: #dddddd;
$color-gray-650: #c8cccf;
$color-gray-700: #f3f4f5;
$color-gray-800: #dddeec;
$color-gray-850: #f8f8f8;
$color-grey-900: #091f1ff2;
$color-grey-1000: rgba(0, 0, 0, 0.12);
$color-grey-1100: #091f1fb3;
$color-grey-1200: #091f1f33;
$color-grey-1300: #ffffffde; //rgba(255, 255, 255, 0.87);
$color-grey-1400: #bfc2ce;
$color-grey-1500: #ffffff80;
$color-grey-1600: rgba(0, 0, 0, 0.06);
$color-grey-1700: #ffffff45;
$color-grey-1800: #00000066;
$color-grey-1900: #fbfbfb;
$color-grey-2000: rgba(0, 0, 0, 0.08);
$color-grey-2100: #091f1f66;
$color-grey-2200: #1a1818;
$color-grey-2300: rgba(0, 0, 0, 0.2);
$color-grey-2500: rgba(0, 0, 0, 0.3);
$color-grey-2550: rgba(0, 0, 0, 0.4);
$color-grey-2400: #091f1f94;
$color-grey-2600: #e2e2e2;
$color-grey-2700: #eeeff1;
$color-grey-2800: #6e768a;
$color-grey-2900: rgba(0, 0, 0, 0.15);
$color-grey-3000: rgba(0, 0, 0, 0.2);
$color-grey-3100: #9d9d9d;
$color-grey-3200: rgba(0, 0, 0, 0.05);
$color-grey-3300: rgba(0, 0, 0, 0.03);
$color-grey-3400: rgba(0, 0, 0, 0.6);
$color-grey-3500: rgba(0, 0, 0, 0.16);
$color-grey-3600: rgba(0, 0, 0, 0.5);
$color-grey-3700: rgba(0, 0, 0, 0.38);
$color-grey-3800: #dedede;
$color-grey-3900: rgba(0, 0, 0, 0.87);
$color-grey-4000: rgba(0, 0, 0, 0.26);
$color-grey-4100: rgba(0, 0, 0, 0.06);
$color-grey-4200: rgba(0, 0, 0, 0.8);
$color-grey-4300: rgba(0, 0, 0, 0.95);
$color-grey-4400: rgba(0, 0, 0, 0.04);
$color-grey-4500: #3a3a3a;

$color-white-200: rgba(255, 255, 255, 0.2);
$color-white-300: rgba(255, 255, 255, 0.3);
$color-white-500: rgba(255, 255, 255, 0.5);
$color-white-600: rgba(255, 255, 255, 0.6);
$color-white-700: rgba(255, 255, 255, 0.7);
$color-white-800: rgba(255, 255, 255, 0.8);
$color-white-2600: rgba(255, 255, 255, 0.26);
$color-white-1600: rgba(255, 255, 255, 0.16);

$color-blue-50: #e7ebf2;
$color-blue-100: #1a4081;
$color-blue-200: #00777c;
$color-blue-300: #60a5ca;
$color-blue-400: #006da6;
$color-blue-500: #0d2e52;
$color-blue-900: #f3f5f9;
$color-blue-1000: #d2e5ef;
$color-blue-1100: #527edb;
$color-blue-1200: #f0f5ff;
$color-blue-1300: #00b8d9;
$color-blue-1400: #0c1f35;
$color-blue-1500: #16356e;
$color-blue-1600: #0d1e47;
$color-blue-1700: #2c77f1;
$color-blue-1800: #38a1f1;
$color-blue-1900: #2666b1;
$color-blue-2000: (0, 120, 178, 0.3);
$color-blue-2100: #425f97;

$color-jungle-100: #00b0b8;
$color-jungle-200: #00fff5;
$color-jungle-300: #00c3e6;
$color-jungle-400: #e1ecf2;
$color-jungle-500: rgba(0, 176, 184, 0.8);
$color-jungle-900: #e8f4f6;
$color-jungle-1000: #00aeb6;
$color-jungle-1100: rgba(0, 176, 184, 0.4);
$color-jungle-1200: #d0e9eb;
$color-jungle-1300: #9cdade;
$color-jungle-1400: #34bec5;

$color-green-100: #00b283;
$color-green-200: #e5f7f2;
$color-green-300: #00878c;
$color-green-400: rgba(118, 245, 245, 0.2);
$color-green-500: #eaf7f2;
$color-green-700: #dff0f1;
$color-green-800: #e8f5f0;
$color-green-900: rgba(0, 120, 178, 0.2);
$color-green-1000: #00777c;
$color-green-1100: #005a5d;

$color-violet-200: #e8e3ff;
$color-violet-300: #802080;
$color-violet-400: #f1e7f1;
$color-violet-500: #80217f;
$color-violet-600: #631863;
$color-violet-700: #80217f;
$color-violet-800: #f2e8f2;

$color-purple-100: #6554c0;
$color-purple-700: #f5f4ff;

$color-orange-100: #ffab00;
$color-orange-200: #ffab2a;
$color-orange-300: #ff8034;
$color-orange-400: #ff6c00;
$color-orange-700: #fff6e5;
$color-orange-800: #fff2ea;

$color-tomato-100: #a51d13;
$color-tomato-200: #ed4d41;
$color-tomato-300: #ff2f00;
$color-tomato-400: rgba(237, 77, 65, 0.3);
$color-tomato-700: #f6e8e7;
$color-tomato-800: #fff2ef;

/*gradient backgrounds*/
$color-jungle-100-gradient: linear-gradient(
                254deg,
                rgba(0, 120, 178, 0.99) 30%,
                $color-jungle-100
);
$color-violet-300-gradient: linear-gradient(
                97deg,
                $color-violet-300 43%,
                $color-violet-600 96%
);

$color-blue-1400-gradient: linear-gradient(
                127deg,
                $color-blue-1400 -27%,
                $color-blue-100 74%
);
$color-blue-1500-gradient: linear-gradient(
                101deg,
                $color-blue-100,
                $color-blue-1500 99%
);

/* social colors*/
$color-facebook: #4267b2;
$color-instagram: #8a3ab9;
$color-youtube: #ff0000;
$color-linkedin: #0077b5;

/* Theme colors. */
$color-primary: $color-jungle-1000;
$color-secondary: $color-blue-100;
$color-primary-medium: $color-blue-900;
$color-secondary-medium: $color-blue-200;
$color-main: $color-grey-1000;
$color-support-primary: $color-violet-300;
$color-support-secondary: $color-blue-300;
$color-gray-icon: rgb(121, 121, 121);
$color-success: $color-green-100;
$color-warn: $color-orange-100;
$color-failure: $color-tomato-100;
$color-info: $color-blue-1300;
$color-discount: #a51d13;
