.sold {
  display: flex;
  align-items: flex-start;
  color: $color-tomato-200 !important;

  &__label {
    font-size: 12px;
    font-weight: 600;
    color: $color-tomato-200 !important;
  }

  .icon-sold_out {
    font-size: 16px;
    color: $color-tomato-200;
    margin-right: 5px;
  }
}

body[dir='rtl'] {
  .sold .icon-sold_out {
    margin-left: 5px;
    margin-right: initial;
  }
}
