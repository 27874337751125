.views {
  display: flex;
  align-items: center;
  line-height: normal;

  .icon-eye {
    font-size: $fs-14;
  }

  &__value {
    font-size: $fs-11;
    margin-left: 4px;
    font-weight: 600;

    p {
      line-height: initial;
    }
  }
}

.views--light {
  .views__value {
    color: $color-white-true;
  }

  .icon-eye {
    color: $color-white-700;
  }
}

.views--dark {
  .views__value,
  .icon-eye {
    color: $color-gray-200;
    opacity: 0.2;
  }
}

body[dir='rtl'] {
  .views__value {
    margin-right: 4px;
    margin-left: initial;
  }
}
