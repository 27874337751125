.check-list {
  display: flex;

  &__icon {
    font-size: 18px;
  }

  &__text {
    font-size: $fs-13;
    font-weight: 500;
    margin: 0 5px;
  }
}

.check-list--valid {
  .check-list__icon {
    color: $color-primary;
  }

  .check-list__text {
    color: white;
  }
}

.check-list--not-valid {
  .check-list__icon {
    color: $color-white-200;
  }

  .check-list__text {
    color: $color-white-700;
  }
}

.check-list-group {
  padding: 15px 20px !important;
  bottom: 80px;
  top: auto;
}

.check-list-group.popover--top.popover-wrapper::before {
  right: 20px;
  left: initial;
}

body[dir='rtl'] .check-list-group.popover-wrapper::before {
  left: 18px;
  right: initial;
}
