.login-form {
  padding: 40px 0;

  .radio-group {
    display: flex;
    gap: 42px;
    align-items: center;
    padding: 33px 0 23px 0;
    justify-content: center;

    &__label {
      font-size: $fs-14;
      color: $color-grey-3900;
      font-weight: 500;
      line-height: 1.71;
      white-space: nowrap;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__input {
      margin: 0 14px 0 0;
    }

    input[type='radio'] {
      -webkit-appearance: none;
      width: 15px;
      height: 15px;
      border: 1px solid $color-gray-400;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
    }

    input[type='radio']:before {
      content: '';
      display: block;
      height: 69%;
      margin: 10%;
      border-radius: 50%;
    }

    input[type='radio']:checked {
      border: 1px solid $color-primary;
    }

    input[type='radio']:checked:before {
      background: $color-primary;
      margin: 2px;
    }
  }

  .login-forget-password {
    display: flex;
    justify-content: end;
    padding-bottom: 20px;

    a {
      font-size: $fs-12;
      font-weight: 600;
      line-height: 2;
      color: $color-blue-100;

      &:hover {
        color: $color-jungle-1000;
      }
    }
  }
}

body[dir='rtl'] {
  .login-form {
    .radio-group {
      &__input {
        margin: 0 0 0 14px;
      }
    }
  }
}

.login-form__form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.login-form__heading {
  font-size: $fs-32;
  font-weight: bold;
  line-height: 1.5;
  color: $color-grey-3900;
  text-align: center;
}

.login-form__form-group {
  margin-bottom: 20px;

  input {
    border: solid 1px $color-gray-500;
  }
}

.dont-account {
  padding: 20px 0;
  text-align: center;

  p {
    font-size: $fs-14;
    font-weight: 600;
    line-height: 1.29;
    color: $color-grey-3900;
  }

  a {
    color: $color-primary;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 1.3px;
      text-underline-position: under;
    }
  }
}

@media only screen and (max-width: 560px) {
  .login-form {
    padding: 0;

    .radio-group {
      justify-content: center;
      gap: 24px;
      align-items: center;
    }

    .forget-password {
      display: flex;
      justify-content: end;
      padding-bottom: 20px;
      margin-top: -15px;

      a {
        font-size: $fs-12;
        font-weight: 600;
        line-height: 2;
        color: $color-blue-100;
      }
    }
  }

  .login-form__heading {
    font-size: $fs-21;
    text-align: center;
    margin: -15px 0 -20px;
  }

  .dont-account {
    text-align: center;
  }
}
