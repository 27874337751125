.feedback {
  &__popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &__text h3 {
      font-size: $fs-24;
      font-weight: 600;
      color: $color-black-true;
      margin-bottom: 20px;
    }
    &__description {
      font-size: $fs-16;
      font-weight: 500;
      text-align: center;
      color: $color-gray-200;
      margin-bottom: 35px !important;
    }
    &__footer {
      button {
        color: $color-blue-200;
        font-size: $fs-13;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 22px;
      }
    }
  }
}
