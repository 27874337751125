.timer-small {
    &__lastChance{
      color: $color-orange-300;
      font-size: $fs-11;
      font-weight: 600;
    }
    small{
      font-size: $fs-12;
    }
    &__end-in{
      font-size: $fs-12;
      font-weight: 500;
      &__daysCountdown {
        font-weight: 600;
      }
      &__hourCountdown {
        color: $color-orange-100;
        font-weight: 600;
      }
    }
}
.timer-small--light{
  .timer-small__end-in{
    color:$color-grey-1300;
  }
  .timer-small__end-in__daysCountdown {
    color: $color-white-true;
  }
}
.timer-small--dark{
  .timer-small__end-in{
    color:$color-gray-200;
  }
  .timer-small__end-in__daysCountdown {
    color: $color-secondary;
  }
}

