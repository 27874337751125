.add-button {
  border-radius: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  transition: 200ms;
  width: fit-content;
  &__text {
    font-weight: 600;
    font-size: 13px;
    padding: 12px 15px 12px 10px;
    border-radius: 0 15px 15px 0;
    height: 100%;
  }
  &__icon-container {
    border-radius: 15px 0 0 15px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 6px;
    .icon {
      font-size: $fs-32;
      color: $color-white-true;
      transition: 200ms;
    }
  }
}
.add-button--light {
  background-color: transparent;
  height: 40px;
  .add-button__text {
    color: $color-primary;
    background-color: $color-white-true;
  }
  .add-button__icon-container {
    background-color: $color-primary;
    .icon {
      color: $color-white-true;
    }
  }
  .add-button__text:hover {
    background-color: $color-blue-900;
  }
}
.add-button--primary {
  background-color: $color-primary;
  height: 48px;
  .add-button__text {
    color: $color-white-true;
    background-color: $color-primary;
    padding: 16px 15px 16px 2px;
  }
  .add-button__icon-container {
    background-color: $color-primary;
    padding: 10px 7px;
    .icon {
      color: $color-white-true;
    }
  }
}
.add-button--primary:hover {
  background-color: $color-jungle-500;
  .add-button__text,
  .add-button__icon-container {
    background-color: $color-jungle-500;
  }
}
.add-rotated {
  transform: rotate(45deg);
  transition: 200ms;
}
body[dir='rtl'] {
  .add-button {
    &__text {
      padding: 10px 18.54px;
      border-radius: 15px 0 0 15px;
      margin-left: 18px;
    }
    &__icon-container {
      border-radius: 0 15px 15px 0;
      padding: 4px 6px 4px 4px;
    }
  }
  .add-button--light {
    .add-button__text {
      line-height: 22px;
    }
  }
  .add-button--primary {
    .add-button__text {
      line-height: 28px;
    }
  }
}
