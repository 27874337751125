.half-and-half-blue-wrapper {
  background-color: $color-jungle-900;
  min-height: 549px;
  position: relative;
  overflow: hidden;
  padding-bottom: 40px;
}

.half-and-half-blue-wrapper::before {
  content: '';
  background-image: $color-blue-1400-gradient;
  height: 100%;
  width: 45%;
  position: absolute;
  left: -110px;
  bottom: 0;
  z-index: $z-index-0;
  transform: skew(-18deg);
}

body[dir='rtl'] {
  .half-and-half-blue-wrapper::before {
    right: -110px;
    left: initial;
    transform: skew(8deg);
  }
}

@media only screen and (max-width: 1750px) {
  .half-and-half-blue-wrapper {
    min-height: 457px;
  }
}

@media only screen and (max-width: 1440px) {
  .half-and-half-blue-wrapper {
    min-height: 411px;
  }
}

@media only screen and (max-width: 1366px) {
  .half-and-half-blue-wrapper {
    min-height: 411px;
  }
}

@media only screen and (max-width: 1280px) {
  .half-and-half-blue-wrapper {
    min-height: 366px;
  }
}

@media only screen and (max-width: 1024px) {
  .half-and-half-blue-wrapper {
    min-height: 292px;
  }
}

@media only screen and (max-width: 912px) {
  .half-and-half-blue-wrapper {
    min-height: 260px;
  }
}

@media only screen and (max-width: 700px) {
  .half-and-half-blue-wrapper {
    padding: 30px 0;
  }
  .half-and-half-blue-wrapper::before {
    height: 37%;
    width: 100%;
    left: 0;
    z-index: 0;
    top: -57px;
    transform: skew(0deg, -9deg);
  }
  body[dir='rtl'] {
    .half-and-half-blue-wrapper::before {
      right: 0;
      transform: skew(0deg, 9deg);
    }
  }
}
