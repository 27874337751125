.try-with-whatsapp {
  font-size: $fs-14;
  font-weight: 600;
  color: $color-grey-4000;
  text-align: center;
  cursor: pointer;
}
.whatsapp-code-sent-success {
  color: $color-success-500;
  text-align: center;
  margin-top: 10px;
}
