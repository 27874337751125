.footer-area {
  .footer-banner {
    height: 400px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .footer-wrapper {
    padding: 3.22rem 17.6rem 3.22rem 17.6rem;
  }

  .fixed-bottom-layout {
    z-index: 109;
  }
}

@media only screen and (min-width: 1695px) and (max-width: 1855px) {
  .footer-wrapper {
    padding: 3.22rem 12rem 3.22rem 12rem !important;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1694px) {
  .footer-wrapper {
    padding: 3.22rem 4rem 3.22rem 4rem !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1499px) {
  .footer-wrapper {
    padding: 3.22rem 2rem 3.22rem 2rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .footer-wrapper {
    padding: 1rem 1rem 1rem 1rem !important;
  }
}
