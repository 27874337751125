.banner-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  &.disabled {
    cursor: default;
  }
  &.widget {
    margin-bottom: 20px;
    border-radius: 10px;
  }
  &.home-banner {
    aspect-ratio: 4/1;
  }
  &.footer {
    aspect-ratio: 24/5;
  }
}
@media only screen and (max-width: 1366px) {
  .banner-img {
    &.footer {
      aspect-ratio: 683/200;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .banner-img {
    &.footer {
      aspect-ratio: 1024/381;
    }
  }
}
@media only screen and (max-width: 912px) {
  .banner-img.widget {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 768px) {
  .banner-img {
    &.home-banner {
      aspect-ratio: 64/35;
    }
    &.footer {
      aspect-ratio: 32/15;
    }
  }
}
@media only screen and (max-width: 640px) {
  .banner-img {
    &.home-banner {
      aspect-ratio: 640/361;
    }
    &.footer {
      aspect-ratio: 2/1;
    }
  }
}
@media only screen and (max-width: 540px) {
  .banner-img {
    height: 100% !important;
  }
}
@media only screen and (max-width: 360px) {
  .banner-img {
    &.home-banner {
      aspect-ratio: 9/8;
    }
    &.footer {
      aspect-ratio: 9/7;
    }
  }
}
