.post-card-horizontal {
  text-align: start;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid $color-grey-1000;
  transition: 200ms;
  height: 120px;
  width: 100%;
}

.post-card-horizontal--light {
  background-color: $color-white-true;
}

.post-card-horizontal:hover {
  box-shadow: 0 4px 8px 0 $color-grey-2000;
}
