.scrollable-section {
  width: 100%;
  &__wrapper {
    display: flex;
    width: max-content;
    align-items: center;
    gap: 30px;
    .contain {
      &:last-child {
        margin-right: 30px;
      }

      &:first-child {
        margin-left: 30px;
      }
    }
  }
}

body[dir='rtl'] {
  .scrollable-section {
    .contain {
      &:last-child {
        margin-right: unset;
        margin-left: 30px;
      }
      &:first-child {
        margin-left: unset;
        margin-right: 30px;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .scrollable-section {
    &__wrapper {
      gap: 20px;
      .contain {
        &:last-child {
          margin-right: 20px;
        }

        &:first-child {
          margin-left: 20px;
        }
      }
    }
  }
  body[dir='rtl'] {
    .scrollable-section {
      &__wrapper {
        .contain {
          &:last-child {
            margin-right: unset;
            margin-left: 20px;
          }
          &:first-child {
            margin-left: unset;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
