.productInfo-wrapper {
  display: flex;

  .productInfo {
    margin-left: 10px;

    .highlightedText {
      font-weight: bold;
      color: $color-black-true;
    }

    &__title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      display: inline-grid;

      h3 {
        margin-top: 0;
        font-size: $fs-12;
        cursor: pointer;
        transition: 200ms;
        overflow: hidden;
        text-overflow: ellipsis;
        inline-size: 100%;
        white-space: nowrap;
      }

      h3:hover {
        color: $color-blue-200;
      }
    }

    &__list-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-top: 5px;
      gap: 5px;

      .group-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .productInfo__list {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          &:first-child::before {
            content: '';
            margin: unset;
          }

          &::before {
            content: '|';
            color: $color-grey-2300;
            margin: 0 6px;
            font-weight: normal;
          }
        }
      }

      .discount-small-view__old-price {
        display: none;
      }
    }

    &__list {
      font-size: $fs-11;
      font-weight: 500;
    }

    .available-quantity {
      color: $color-blue-200;
      font-weight: 600;
      padding: 0;
      text-transform: capitalize;
    }

    .bidding-count {
      color: $color-primary;
      font-weight: 600;
      font-size: $fs-11;
    }

    .views-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .icon-eye {
      font-size: $fs-12;
      color: $color-grey-3000;
      margin-right: 5px;
    }

    .views-counter {
      color: $color-gray-200;
      line-height: normal;
      font-size: $fs-11;
      font-weight: 500;
    }
  }

  .imageContainer {
    width: 50%;
  }
}

.activeSellerProducts {
  align-items: center;
  margin-bottom: 15px;
  overflow: hidden;

  .productInfo {
    h3 {
      font-size: $fs-12;
      font-weight: 600;
      color: $color-black-true;
      line-height: 1.33;
      text-align: left;
    }

    .expiry-date {
      color: $color-gray-200;
      width: 100%;

      .timer-small .timer-small__end-in {
        font-size: $fs-11;

        small {
          font-size: $fs-11;
        }
      }
    }
  }
}

.activeSellerProducts:last-child {
  margin-bottom: initial;
}

.searchProducts {
  align-items: center;
  margin-top: 16px;

  .productInfo {
    h3 {
      font-size: $fs-12;
      font-weight: 500;
      color: $color-gray-200;
    }

    .expiry-date {
      color: $color-gray-200;
      font-size: $fs-11;
    }
  }
}

body[dir='rtl'] {
  .productInfo-wrapper {
    .productInfo {
      margin-right: 0.715rem;
      margin-left: unset;

      .views-icon {
        width: 10px;
        margin-right: 8px;
        margin-left: unset;
      }

      .icon-eye {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .searchProducts .productInfo h3 {
    font-size: $fs-12;
  }

  .searchProducts {
    margin-top: 20px;
  }
}
