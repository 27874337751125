.legal-container {
  display: flex;
  padding: 30px 5% 50px 17%;

  &_links {
    margin-right: 30px;

    .links-wrapper {
      position: sticky;
      top: 10px;
    }
  }

  &_btn {
    margin-left: 15%;

    &_items {
      background-color: $color-primary;
      width: 60px !important;
      height: 60px;
      border-radius: 50%;
      padding: 6px 18px 15px;
      position: sticky;
      // position: -webkit-sticky;
      top: 85vh;
    }

    &_wrapper {
      display: flex;
      flex-direction: column;

      .icon-arrow-top {
        font-size: $fs-24;
      }
    }
  }
}

body[dir='rtl'] {
  .legal-container {
    padding: 30px 17% 50px 5%;

    &_links {
      margin-right: unset;
      margin-left: 30px;
    }

    &_btn {
      margin-left: unset;
      margin-right: 15%;
    }
  }
}

@media screen and (max-width: 1440px) {
  .legal-container {
    padding: 30px 3% 50px 15%;
  }
}

@media screen and (max-width: 1280px) {
  .legal-container {
    padding: 30px 3% 50px 10%;
  }
  body[dir='rtl'] {
    .legal-container {
      padding: 30px 10% 50px 3%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .legal-container {
    &_btn {
      margin-left: 5%;

      &_items {
        top: 80vh;
      }
    }
  }
  body[dir='rtl'] {
    .legal-container {
      &_btn {
        margin-left: unset;
        margin-right: 5%;
      }
    }
  }
}

@media screen and (max-width: 913px) {
  .legal-container {
    &_btn {
      margin-left: 2%;
    }
  }
  body[dir='rtl'] {
    .legal-container {
      &_btn {
        margin-left: unset;
        margin-right: 2%;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .legal-container {
    padding: 30px 2% 50px 2%;

    &_links {
      margin-right: 15px;
    }
  }
  body[dir='rtl'] {
    .legal-container {
      &_links {
        margin-right: unset;
        margin-left: 15px;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .legal-container {
    display: block;
    padding: 30px 20px 50px;

    &_btn {
      &_items {
        width: 40px !important;
        height: 40px;
        font-size: $fs-12;
        padding: 10px 6px 17px;
        position: fixed;
        right: 10px;
        bottom: 70px;
        top: unset;
      }

      &_wrapper {
        .icon-arrow-top {
          font-size: $fs-14;
        }
      }
    }
  }
  body[dir='rtl'] {
    .legal-container {
      &_btn {
        &_items {
          right: unset;
          left: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .legal-container {
    padding: 30px 10px 50px;
  }
}
