.post-type-wrapper {
  display: flex;
  align-items: center;
  text-align: start;
  padding: 7px 19px 7px;
  .icon-circle-container {
    display: inline-block;
    padding: 5.5px;
    border-radius: 20px;
    .icon-Bid,
    .icon-bag {
      color: $color-white-true;
      font-size: 21px;
    }
  }
  .post-type-details {
    width: 100%;
    margin-left: 10px;
    line-height: 2;
    .subtitle-text {
      color: $color-gray-200 !important;
      font-size: 11px;
    }
    h3 {
      color: $color-black-true;
      font-weight: 600;
      font-size: 12px;
    }
    .fees-container {
      display: flex;
    }
  }
  .icon-arrow-right {
    color: $color-gray-200 !important;
  }
  .icon-arrow-left {
    color: $color-gray-200 !important;
  }
}
.post-type-wrapper:hover {
  background-color: $color-blue-900;
  transition: 200ms;
}

body[dir='rtl'] {
  .post-type-wrapper {
    .post-type-details {
      margin-right: 10px;
      margin-left: unset;
    }
  }
}
