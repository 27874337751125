.toolbar {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;
  position: sticky;
  top: 0px;
  min-height: 50px;
  backdrop-filter: blur(20px);
  width: 100%;
}

.toolbar__heading {
  text-align: center;
  padding: 10px;

  &__title {
    font-size: $fs-16;
    font-weight: 700;
  }

  &__subtitle {
    font-size: $fs-13;
    color: $color-gray-200;
  }
}

.toolbar__right-items {
  flex-direction: row-reverse;
  flex: 1;
  padding: 10px;
  display: flex;

  .toolbar__button {
    margin-left: 20px;

    &:last-child {
      margin: 0;
    }
  }
}

.toolbar__left-items {
  flex: 1;
  padding: 10px;
  display: flex;

  .toolbar__button {
    margin-right: 20px;

    &:last-child {
      margin: 0;
    }
  }
}
