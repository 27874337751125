.about-seller {
  margin-top: 30px;
  margin-bottom: 40px;
}

.seller-Info {
  background-color: $color-jungle-900;
  padding: 15px;
  border-radius: 15px;

  &__container {
    padding-left: 15px;
    margin-top: 15px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px dashed $color-grey-1000;

    &__key {
      color: $color-grey-3900;
      font-weight: 500;
      font-size: $fs-14;
      line-height: normal;
    }

    &__value {
      color: $color-black-true;
      font-weight: bold;
      line-height: normal;
      font-size: $fs-14;
    }
  }

  &__list:last-child {
    border-bottom: unset;
    margin-bottom: unset;
  }
}

.about-seller__collapsed .add-review {
  margin-top: 20px;
}

.about-seller__collapsed .load-more {
  margin: 0 20px;
  justify-content: flex-start;
}

.review__collapse {
  &__close,
  &__open {
    min-width: 133px;
  }
}

.Actions {
  display: none;
}

body[dir='rtl'] {
  .seller-Info {
    padding: 14.8px 15px 13.3px 13.5px;

    &__container {
      padding-right: 20px;
      padding-left: initial;
    }
  }
}

@media only screen and (max-width: 920px) {
  .about-seller {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 786px) {
  .Actions {
    display: none;
  }
}

@media only screen and (max-width: 560px) {
  .seller-Info {
    margin: 15px 0;

    &__container {
      padding-left: 0;
    }
  }

  .Actions {
    display: flex;
    flex-direction: column;
    gap: 15px 0;
    margin-top: 15px;

    button {
      width: 100%;
    }
  }

  .reviews-list,
  .add-review {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  .about-seller {
    margin: 10px 0  0;
  }
}
