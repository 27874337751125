.download-app-mobile {
  display: none;
  height: 100%;
  padding: 159px 30px 30px;
  position: relative;
  border-radius: 15px 15px 0 0;
  &__img {
    position: absolute;
    left: 0;
    width: 100vw;
    text-align: center;
    bottom: 197px;
    img {
      width: 241px;
      height: 273px;
    }
  }
  &__content {
    text-align: center;
    .title {
      font-size: $fs-21;
      font-weight: bold;
      margin-bottom: 30px;
      line-height: 1.52;
    }
    &__buttons > button {
      padding: 15px 0;
      border-radius: 15px;
    }
  }
  &__download-buttons {
    text-align: center;
    margin-bottom: 29px;
    a {
      margin: 0 5px;
      background-color: $color-primary;
      color: white;
      padding: 16px 20px 15px;
      border-radius: 5px;
      font-size: $fs-18;
      font-size: $fs-13;
      font-weight: 600;
    }
  }
  &__close {
    color: $color-primary;
    font-size: $fs-14;
    font-weight: 600;
    margin-top: 20px;
    transition: 200ms;
  }
  &__close:hover {
    color: $color-secondary;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

@media only screen and (max-width: 710px) {
  .custom-modal-download .modal-content {
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 0 !important;
    width: 100%;
    height: auto;
  }
  .custom-modal-download {
    .download-app-mobile {
      background-color: white;
      display: block;
    }
  }
  body[dir='rtl'] {
    .download-app-mobile__img {
      bottom: 220px;
    }
  }
}
@media only screen and (max-width: 616px) {
  .download-app-mobile {
    &__img {
      bottom: 232px;
    }
  }
}

@media only screen and (max-width: 326px) {
  .download-app-mobile__img {
    bottom: 290px;
    width: 100%;
  }
}
