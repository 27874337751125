.socialMedia-wrapper {
  img {
    margin-bottom: 20px;
    width: 169px;
    height: auto;
    aspect-ratio: 169/38;
  }
}
.socialMedia {
  display: flex;
  flex-wrap: wrap;
  .socialMedia-icon {
    margin-right: 20px;
    line-height: normal;
    display: inline-block;
    font-size: 18px;
    span {
      width: 24px;
      height: 13px;
      color: $color-gray-200;
    }
    span:hover {
      transition: 200ms;
      color: $color-blue-200;
    }
  }
}
body[dir='rtl'] {
  .socialMedia-wrapper {
    .socialMedia {
      .socialMedia-icon {
        margin-left: 19px;
        margin-right: unset;
      }
    }
  }
}
@media only screen and (max-width: 540px) {
  .socialMedia-wrapper img {
    width: 100px;
  }
}
