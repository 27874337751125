.we-are-here {
  display: flex;
  padding: 60px 19.5% 45px;
  align-items: center;
  gap: 4%;

  &__img {
    img {
      width: 625.5px;
      height: 448.8px;
      object-fit: contain;
    }
  }
  &__info {
    color: $color-black-true;
    &__title {
      font-size: $fs-32;
      font-weight: 600;
      margin-bottom: 24px;
    }
    &__description {
      font-size: $fs-18;
      font-weight: 500;
      margin-bottom: 31px !important;
    }
  }
  &__details {
    &__list {
      margin-bottom: 33px;
      color: $color-black-true;
      &__title {
        font-size: $fs-16;
        font-weight: 600;
        margin-bottom: 16px;
      }
      &__description {
        font-size: $fs-16;
        font-weight: 500;
        &.direction {
          direction: initial;
          text-align: end;
        }
      }
    }
    &__list:last-child {
      margin-bottom: initial;
    }
  }
}
@media only screen and (max-width: 1440px) {
  .we-are-here {
    padding: 60px 12% 45px;
  }
}
@media only screen and (max-width: 1280px) {
  .we-are-here {
    justify-content: center;
    padding: 60px 5% 45px;
    &__img {
      img {
        width: 400px;
      }
    }
    &__info__description {
      margin-bottom: 20px !important;
    }
    &__details__list {
      margin-bottom: 20px;
    }
    &__details__list__title {
      margin-bottom: 8px;
    }
  }
}
@media only screen and (max-width: 820px) {
  .we-are-here {
    padding: 20px;
  }
  .we-are-here__img img {
    width: 400px;
  }
  .we-are-here__info__title {
    font-size: 28px;
    margin-bottom: 18px;
  }
}
@media only screen and (max-width: 700px) {
  .we-are-here {
    flex-direction: column;
    padding: 36px 10px 0;
    justify-content: center;
    align-items: unset;
    &__img {
      img {
        width: 90%;
        height: initial;
      }
    }
    &__text {
      text-align: start;
      padding: 42px 5px 32px;
    }
    &__info__title {
      font-size: $fs-32;
      margin-bottom: 12px;
    }
    &__info__description {
      font-size: $fs-16;
      margin-bottom: 21px !important;
    }
    &__details {
      &__list {
        margin-bottom: 21px;
        &__title {
          margin-bottom: 10px;
        }
      }
    }
  }
}
