.no-found-content {
  h2 {
    font-size: $fs-32;
    font-weight: bold;
    line-height: 1.5;
    color: $color-grey-3900;
    max-width: 357px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $color-gray-200;
    max-width: 349px;
    margin-bottom: 30px;
  }
  .no-found-buttons {
    display: flex;
    gap: 17px;
  }
}

@media screen and (max-width: 912px) {
  .no-found-content {
    text-align: center;
    h2 {
      max-width: unset;
    }
    p {
      max-width: unset;
    }
    .no-found-buttons {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 560px) {
  .no-found-content {
    h2 {
      font-size: $fs-24;
      line-height: 1.33;
      color: $color-black-true;
      padding: 0 30px;
    }
    p {
      font-size: $fs-16;
      color: $color-black-true;
      line-height: 1.5;
    }
    .no-found-buttons {
      flex-direction: column;
    }
  }
}
