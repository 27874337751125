.mobile-expire-and-quantity {
  background-color: $color-blue-100;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  max-height: 32px; //@TODO : should be handled by another way, as the content may increase
  display: flex;
  .expiry-group {
    display: flex;
    align-items: center;
  }
  &__icon {
    font-size: 17.5px;
    color: rgba(255, 255, 255, 0.38);
    margin: 0 5px;
  }
  &__first-part p {
    font-size: 11px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.87);
    white-space: nowrap;
    span {
      font-weight: 500;
      color: white;
    }
  }
  &__second-part {
    max-width: 65%;
    height: 17px;
    progress {
      border: none !important;
    }
  }
}
