.main-page {
  .legal-container {
    padding: 30px 5% 50px 1%;

    .legal-container_btn,
    .links-wrapper {
      display: none;
    }
  }
  .nav-links {
    margin: auto;
    text-align: center;
    a {
      color: $color-violet-500;
      text-decoration: none;
      font-size: $fs-16;
      margin-top: 25px;
      &:hover {
        color: $color-purple-100;
      }
    }
  }
}
body[dir='rtl'] {
  .main-page {
    .legal-container {
      padding: 30px 1% 50px;
    }
  }
}

.PrivacyPolicyEN,
.PrivacyPolicyAR,
.TermsAndConditionsEN,
.TermsAndConditionsAR,
.productsPolicyEN,
.productsPolicyAR,
.businessRegistrationEN,
.businessRegistrationAR,
.ItemConditionGuideEN,
.ItemConditionGuideAR,
.FeesPolicyEN,
.FeesPolicyAR {
  section{
    padding-top: 20px;
  }
  &_title {
    font-size: $fs-18 !important;
    font-weight: bold;
    color: $color-black-true;
    display: flex;
  }
  &_wrapper {
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 20px;
    border: solid 1px $color-grey-1000;
    border-radius: 15px;
  }
  &_subtitle {
    font-size: $fs-16;
    font-weight: bold;
  }
  &_text {
    font-size: $fs-16;
    font-weight: 500;
    color: $color-gray-100;
  }
  &_item {
    font-size: $fs-16;
    font-weight: 500;
    color: $color-gray-100;
    margin-left: 50px;
    display: flex;
  }
  .table-wrapper {
    overflow-x: auto;

    table {
      border: 0;
      width: 100%;
      border-width: 0;
      thead,
      .thead {
        background-color: $color-blue-200;
        height: 40px;
        border: none;
        width: 100%;

        td {
          padding: 10px;
          color: $color-white-true;
          font-size: $fs-12;
          font-weight: 600;
          border-width: 0;
        }
      }
      tbody {
        tr {
          &.blue {
            background-color: $color-blue-900;
          }
        }
        td {
          border: 1px solid $color-grey-1000;
          border-width: 1px;
          padding: 10px;
          font-size: $fs-12;
          font-weight: 500;
        }
      }
    }
  }

  .arrow-right:after {
    content: '';
    display: inline-block !important;
    width: 0;
    height: 0;
    border-left: 6px solid $color-violet-500;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    margin-right: 5px;
  }
}

body[dir='rtl'] {
  .PrivacyPolicyAR,
  .TermsAndConditionsAR,
  .productsPolicyAR,
  .businessRegistrationAR,
  .ItemConditionGuideAR,
  .FeesPolicyAR {
    .arrow-right:after {
      border-right: 6px solid $color-violet-500;
      border-left: unset;
      margin-left: 5px;
      margin-right: unset;
    }
    &_item {
      margin-left: unset;
      margin-right: 50px;
    }
  }
}

@media screen and (max-width: 370px) {
  .FeesPolicyEN,
  .FeesPolicyAR {
    &_wrapper {
      padding: 15px;
    }
  }
}
