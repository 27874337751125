.category-card {
  width: 176px;
  height: 128px;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  transition: 200ms;
  &_image {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  &_name {
    position: absolute;
    top: 18px;
    right: 44px;
    bottom: 19px;
    left: 14px;
    font-size: $fs-13;
    color: $color-white-true;
    font-weight: 600;
    line-height: 1.31;
    letter-spacing: -0.1px;
  }
  &:hover {
    box-shadow: 0 6px 12px 0 $color-grey-2500;
  }
}

@media screen and (max-width: 560px) {
  .category-card {
    border-radius: 20px;
    width: 150px;
    height: 109px;
    &_image {
      width: 100%;
      height: 100%;
    }
    &_name {
      top: 16px;
      right: 38px;
      bottom: 15px;
      left: 12px;
      font-size: $fs-12;
      line-height: 1.42;
      letter-spacing: -0.09px;
    }
  }
}

body[dir="rtl"] {
  .category-card {
    &_name {
      left: 44px;
      right: 14px;
    }
  }
}
