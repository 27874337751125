.mazadatInfo-wrapper {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  .mazadatInfo-smallWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
}
