.share-wrapper {
  min-height: 58px;
}

.share-group {
  border: 1px solid $color-grey-2300;
  padding: 10px 0;
  border-radius: 10px;
  margin: 10px 0 5px 0;

  &__input,
  #shareLinkInput {
    border: none !important;
    color: $color-gray-200;
    padding: 0 0 0 15px;
    overflow: hidden;
    box-shadow: none;
    font-weight: 500;
    font-size: $fs-10;
  }

  &__input:focus {
    border-color: unset;
    box-shadow: none;
  }

  &__button {
    border: none;
    background-color: unset;
    color: $color-primary;
    font-size: $fs-11;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 10px;
  }

  &__message {
    font-size: $fs-11;
    font-weight: 500;
    color: $color-green-100;
    text-align: center;
  }
}

body[dir='rtl'] {
  #shareLinkInput {
    padding: 0 15px 0 0;
  }
}
