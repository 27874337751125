.post-card-prices {
  display: flex;
  justify-content: start;

  &__vertical-separator {
    border-left: 1px solid $color-grey-1000;
  }
  &__list {
    padding: 5px 10px 5px;
    width: calc(100% / 2);
    &__label {
      font-size: $fs-11;
    }
    &__price {
      font-size: $fs-16;
    }
    &__currency {
      font-size: $fs-11;
      top: 6px;
      right: 1px;
      padding-left: 1.5px;
    }
  }
}
.post-card-prices--light {
  border-top: 1px solid $color-grey-1000;
}
body[dir='rtl']{
  .post-card-prices__list__currency{
    top: 4.5px;
    right:1px;
    order:2;
    padding-left: initial;
  }
}
