.tooltip-content {
    &__icon {
        position: relative;
        display: inline-block;

        .tooltiptext {
            visibility: hidden;
            max-width: 275px;
            min-width: 200px;
            background-color: $color-black-true;
            color: $color-white-true;
            text-align: left;
            border-radius: 10px;
            padding: 16px;
            position: absolute;
            z-index: 1;
            top: 135%;
            right: 0;
            margin-left: -60px;
            font-size: $fs-12;
            font-weight: 500;
        }

        .tooltiptext::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 93%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent $color-black-true transparent;

        }
    }
}

.tooltip-content__icon:hover .tooltiptext {
    visibility: visible;
}

body[dir='rtl'] {
    .tooltip-content {
        &__icon {
            position: relative;
            display: inline-block;
            .tooltiptext {
                text-align: right;
                left: 0;
                right: initial;
                margin-left: -10px;

            }

            .tooltiptext::after {
                right: 90%;

            }
        }
    }
}