.review-sort-by-wrapper {
  position: relative;

  .review-sort-by {
    display: flex;
    gap: 10px;
    align-items: center;

    p {
      font-size: $fs-12;
      font-weight: 500;
      line-height: 1.92;
      color: $color-black-true;
    }

    h5 {
      font-size: $fs-12;
      font-weight: 600;
      line-height: 1.92;
      color: $color-primary;
      cursor: pointer;
    }
  }

  .sortby-options {
    position: absolute;
    background: #fff;
    max-width: fit-content;
    border-radius: 8px;
    z-index: $z-index-1;
    box-shadow: 0 6px 12px 0 $color-grey-2300;
    top: 30px;
  }
}

@media screen and (max-width: 560px) {
  .review-sort-by-wrapper {
    .sortby-options {
      position: unset;
    }
  }
}
