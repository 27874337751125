.mazadat-valet {
  position: absolute;
  top: 184px;
  height: 40px;
  width: 260px;
  background-color: $color-jungle-900;
  box-shadow: 0 3px 6px 0 $color-grey-1600;
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 200ms;
  cursor: pointer;
  &:hover {
    background-color: $color-jungle-1200;
  }
  &_wrapper {
    display: flex;
    align-items: center;
    height: 21px;
    &_icon {
      width: 21px;
      height: 21px;
    }
    &_title {
      color: $color-grey-3900;
      font-size: $fs-12;
      font-weight: bold;
      text-align: start;
      margin-left: 10px;
      line-height: 1.5;
    }
    &_label {
      width: 34px;
      border-radius: 5px;
      background-color: $color-green-100;
      color: $color-white-true;
      padding: 3px 5px 2px;
      height: 18px;
      font-size: $fs-10;
      font-weight: 600;
      letter-spacing: 0.08px;
      text-align: center;
      line-height: 1.4;
    }
    .icon-arrow-right {
      font-size: $fs-18;
      margin-left: 10px;
    }
  }
  &.web {
    border-radius: 0 0 10px 10px;
    top: 183px;
    left: 10px;
  }
  &.mobile {
    border-radius: 10px 10px 0 0;
    top: unset;
    bottom: 189px;
  }
}

body[dir='rtl'] {
  .mazadat-valet {
    padding: 10px 20px 10px 10px;
    &.web {
      left: unset;
      right: 10px;
    }
    &_wrapper {
      &_title {
        margin-left: unset;
        margin-right: 10px;
      }
      .icon-arrow-left {
        font-size: $fs-18;
        margin-right: 10px;
      }
      &_icon {
        transform: scaleX(-1);
      }
      &_label {
        line-height: 1;
      }
    }
  }
}
