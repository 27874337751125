.total-rating {
  font-size: $fs-14;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: space-between;
  white-space: nowrap;
  color: $color-black-true;
  flex-wrap: wrap;

  &__icon {
    display: flex;
    align-items: center;
    span {
      color: #ffce00;
      margin-left: 5px;
    }
  }

  &__stars {
    margin: 0 12px;
    line-height: 15px;
  }

  &__label {
    line-height: 1.5;
    font-size: $fs-12;
    font-weight: 600;
    color: $color-black-true;
    min-width: 70px;
    text-align: end;
  }
}
body[dir='rtl']{
  .total-rating__icon span{
    margin-left: unset;
    margin-right: 5px;
  }
  .total-rating__label{
    min-width: 50px;
  }
}

@media only screen and (max-width: 425px) {
  .total-rating {
    gap: 10px 0;
    &__icon {
      flex-direction: row-reverse;
    }
  }
}
