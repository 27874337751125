.tabs-main {
  border: 1px solid $color-grey-1000;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px 0;

  .tabs {
    padding: 18px 14px;
    width: 100%;
    background: $color-white-true;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    &:hover h2,
    &:hover span {
      color: $color-primary;
    }
    h2 {
      font-size: $fs-14;
      font-weight: 600;
      line-height: 1.29;
      color: $color-grey-3900;
    }

    span {
      font-size: $fs-20;
    }
  }

  .active-tabs {
    background: $color-jungle-900;

    h2 {
      color: $color-primary;
    }

    span {
      color: $color-primary;
    }
  }
}

.content-tabs {
  width: 80%;
}

.content {
  display: none;

  .reviews-zero-state {
    .noData-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      background: $color-jungle-900;
      border-radius: 15px;
      border: none;
      padding: 30px 0;

      img {
        height: 90px;
        width: 90px;
        margin-right: unset;
      }
    }
  }

  .review-list {
    &__content {
      background-color: $color-jungle-900;
    }
  }
}

.active-content {
  display: block;
}

@media screen and (max-width: 768px) {
  .tabs-main {
    display: none;
  }
  .content-tabs {
    width: 100%;
    padding: 0 20px;

    .seller-reviews {
      margin: unset;
    }
  }
}
