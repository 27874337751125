.imageWithLabel-wrapper {
  position: relative;

  .main-image {
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
    width: auto;
    height: 65px;
    aspect-ratio: 4/3;
  }

  //@TODO: We need to handle default style.
  .label {
    position: absolute;
    padding: 3px;
    font-size: $fs-9;
    font-weight: 600;
    left: 0;
    top: 0;
    margin: 5px;
    border-radius: 5px;
    background-color: $color-white-true;
    color: $color-black-true;
    text-transform: capitalize;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    font-family: 'montserrat';
  }

  //@TODO: Label should be independent of translation file key.
  .label-newText {
    background-color: $color-green-200;
    color: $color-green-100;
  }

  .label-mint {
    background-color: $color-violet-200;
    color: $color-violet-700;
  }

  .label-defective {
    background-color: $color-orange-800;
    color: $color-orange-300;
  }

  .label-used {
    background-color: $color-blue-50;
    color: $color-blue-100;
  }
}

.secured {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;

  .post-icon {
    width: 19px;
    height: 19px;
    margin-right: 0;
  }
}

body[dir='rtl'] {
  .imageWithLabel-wrapper {
    .label {
      right: 0;
      top: 0;
      left: unset;
    }

    .secured {
      right: unset;
      left: 0;
    }
  }
}
