.seller-info-main {
  width: 20%;
}

.seller-avatar {
  display: flex;
  justify-content: center;
  .avatar {
    cursor: default;
  }

  .avatar-effect {
    width: 212px;
    height: 212px;
    border-radius: 50%;
    border: 1px solid $color-jungle-1200;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .avatar-inner-effect {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    border: 1px solid $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .avatar-round-effect {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 1px solid $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .not-verifed-color {
    border: 1px solid $color-attention-500;
  }

  .not-verifed-color-light {
    border: 1px solid $color-attention-600;
  }

  .avatar {
    position: relative;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
    }

    .verify-icon {
      height: 32px;
      width: 32px;
      position: absolute;
      right: -6px;
      top: -6px;
    }
  }
}

.seller-details {
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 10px 0 20px;
  border-bottom: 1px solid $color-grey-1000;

  h2 {
    font-size: $fs-18;
    font-weight: bold;
    line-height: 1.17;
    text-align: center;
    color: $color-grey-3900;
  }

  p {
    font-size: $fs-11;
    font-weight: 600;
    text-align: center;
    line-height: 1.91;
    color: $color-grey-3400;
  }

  .seller-rating {
    border-left: 1px solid $color-grey-3700;
    padding-left: 12px;
  }
}

.seller-contact-main {
  display: flex;
  justify-content: center;
  padding: 20px 0;

  .seller-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 9px 16px;
    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-jungle-900;
      cursor: pointer;
      &:hover {
        background-color: $color-jungle-1200;
      }
      span{
        color: $color-jungle-1000;
        font-size: $fs-24;
      }
    }
    h3 {
      font-size: $fs-14;
      font-weight: 600;
      color: $color-grey-3900;
      line-height: 1.29;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

body[dir='rtl'] {
  .seller-rating {
    border-right: 1px solid $color-grey-3700;
    border-left: unset;
    padding-right: 12px;
  }
}

.menu-dots-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .seller-info-main {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .seller-details-mobile {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .seller-avatar {
    .avatar {
      position: relative;

      img {
        height: 94px;
        width: 94px;
        border-radius: 50%;
      }

      .verify-icon {
        height: 25.5px;
        width: 25.5px;
        position: absolute;
        right: -2px;
        top: -2px;
      }
    }
  }

  .seller-details {
    border-bottom: unset;

    .seller-rating {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
    }
  }

  .seller-contact-main {
    display: none;
  }

  .seller-info-main {
    width: 100%;
    border-bottom: 1px solid $color-grey-1000;
    padding: 0 20px 30px;
    margin-bottom: 30px;
  }

  .seller-detail-sec {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    position: relative;
  }

  .menu-dots-icon {
    display: block;

    span {
      font-size: $fs-22;
    }
  }
}

@media screen and (max-width: 560px) {
  .menu-dots-icon {
    display: block;
    position: absolute;
    top: -168px;
    right: 0;
  }
  .seller-detail-sec {
    display: block;
  }
  .seller-details-mobile {
    display: block;
  }
}
