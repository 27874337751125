.buynow-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &__button-group {
    display: flex;
    button,
    .quantity-input-group {
      height: 36px;
      padding: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  &__label {
    font-size: $fs-12;
  }
  &__price {
    font-size: $fs-18;
    color: $color-secondary;
    line-height: 20px;
    font-weight: bold;
  }
  &__currency {
    color: $color-secondary;
    font-size: $fs-11;
    top: 8px;
    margin-right: 2px;
  }
}
body[dir='rtl'] {
  .buynow-mobile__button-group button,
  .buynow-mobile__button-group .quantity-input-group {
    margin-left: unset;
    margin-right: 15px;
  }
  .buynow-mobile__currency{
    top:6px;
    order:2;
  }
}
