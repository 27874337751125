.select-country {
  width: 320px;
  padding: 24px 0 16px;
  border-radius: 6px;
  background-color: $color-white-true;

  &_title {
    font-size: 16px;
    font-weight: 600;
    color: $color-black-true;
    padding: 0 16px;
  }
  &_search {
    margin-bottom: 16px;
    padding: 0 16px;
  }
  hr {
    margin: 16px 0;
  }
  &_item {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;

    &_wrapper {
      display: flex;
      gap: 12px;
      cursor: pointer;
      width: 100%;
      transition: 200ms;
    }

    &_img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
    &_name {
      font-size: 14px;
      font-weight: 500;
    }
    &_icon {
      color: $color-green-100;
      font-size: 18px;
    }
  }
  &_button {
    width: 100%;
    padding: 0 16px;
    margin-top: 24px;
    button {
      width: 100%;
    }
  }
}

.custom-modal-country {
  .modal-dialog {
    width: max-content;
    .modal-content {
      .modal-body {
        padding: 0;
      }
    }
    .modal-header {
      padding: 0;
      button {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .select-country {
    width: 100%;
  }
  .custom-modal-country {
    .modal-content {
      width: 100%;
    }
  }
}
