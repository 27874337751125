.bid-now {
  padding: 20px 0;
  border-bottom: 2px solid $color-grey-1000;
  justify-content: space-between;
  align-items: flex-end;
  &__title {
    color: $color-violet-300;
    font-size: $fs-11;
    margin-bottom: 9px;
    font-weight: 600;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__buttons {
      display: flex;
    }
    &__watch-list {
      margin-left: 10px;
    }
  }
  &__group {
    display: flex;
    align-items: center;
  }
  &__label {
    font-size: $fs-12;
    color: $color-gray-200;
    margin-bottom: 4px;
  }
  &__price {
    color: $color-primary;
    font-size: $fs-32;
    line-height: 32px;
    margin-bottom: 9px !important;
  }
  &__currency {
    color: $color-primary;
    font-size: $fs-16;
    top: 11px;
    margin-right: 1px;
  }
}
.view-bidding-history {
  display: flex;
  text-align: center;
  margin-right: 20px;
  small,
  button {
    font-size: $fs-12;
    font-weight: 600;
    text-transform: capitalize;
  }
  small {
    color: $color-grey-3900;
    padding: 0 3px;
  }
}
body[dir='rtl'] {
  .view-bidding-history {
    margin-right: unset;
    margin-left: 20px;
  }
  .bid-now__content__watch-list {
    margin-left: initial;
    margin-right: 10px;
  }
  .bid-now__currency{
    order:2;
    top: 6.5px;
  }
}
