.home-sections {
  padding: 40px 0;
  background-color: $color-white-true;

  &_divider {
    padding: 0 20px;
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 31px;
    padding: 0 17%;
    align-items: baseline;

    &_header {
      font-size: $fs-24;
      font-weight: bold;
      line-height: 1.25;
    }

    a {
      font-size: $fs-12;
      font-weight: 600;
      line-height: 2;
      letter-spacing: 0.02px;
      color: $color-primary;
      cursor: pointer;
    }
  }

  &.blue {
    background-color: $color-jungle-900;
    border-bottom: none;
  }

  body[dir='rtl'] {
    .home-sections {
      &_wrapper {
        padding: 0 17%;
        direction: rtl;
      }
    }
  }

  .swiper-inner-wrapper {
    padding: unset;
    margin: 0 74px;
  }
}

@media screen and (max-width: 1550px) {
  .home-sections {
    padding: 40px 0;
  }
}

@media screen and (max-width: 1024px) {
  .home-sections {
    padding: 40px 0;

    &_wrapper {
      padding: 0 14%;

      &_header {
        font-size: $fs-24;
      }
    }

    .swiper-inner-wrapper {
      padding: unset;
      margin: 0 32px;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-sections {
    .swiper-inner-wrapper {
      padding: 0 39px;
      margin: unset;
    }
  }
}

@media screen and (max-width: 560px) {
  .home-sections {
    padding: 30px 0 30px 0;
    border-bottom: 6px solid $color-grey-4100;

    &:last-child {
      border-bottom: none;
    }

    &_wrapper {
      padding: 0 20px;
      margin-bottom: 8px;

      &_header {
        font-size: $fs-16;
        line-height: 1.44;
        font-weight: 600;
      }
    }

    .swiper-inner-wrapper {
      padding: 0 20px;
      margin: unset;
      overflow-x: scroll;
    }
  }
  body[dir='rtl'] {
    .home-sections {
      &_wrapper {
        padding: 0 20px;
      }
    }
  }
}
