/* Remove margins and padding. */

body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

textarea {
  resize: none !important;
}

/* Remove trailing margins from nested lists. */

li > {
  ol,
  ul {
    margin-bottom: 0;
  }
}

/**
 * Remove the gap between audio, canvas, iFrames, images, videos and the bottom
 * of their containers: https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

//solution for showing iframe in the body,
//TODO : maybe another solution later 
body > iframe {
  display: none !important;
}

/**
 * Remove default table spacing.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*
    solution for zooming in IOS devices
*/
input:focus-within,
input,
input:focus {
  box-shadow: none !important;
  outline: none !important;
}

@media (max-width: 912px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: $fs-16 !important;
  }
}

a:focus-within, a:focus {
  outline: none !important;
}

button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  line-height: initial;
}

// Override default password reveal icon on Edge/Safari
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear,
input[type=password]::-webkit-contacts-auto-fill-button,
input[type=password]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
