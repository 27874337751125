.under-construction-content {
  h2 {
    font-size: $fs-32;
    font-weight: bold;
    line-height: 1.5;
    color: $color-grey-3900;
    max-width: 367px;
    margin-bottom: 22px;
  }
  p {
    font-size: $fs-18;
    font-weight: bold;
    line-height: 1.5;
    color: $color-black-true;
    max-width: 367px;
  }
}

.app-download {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0 calc((100% - 1170px) / 2);
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.download-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
  img {
    object-fit: fill;
    width: 165px;
    background-color: $color-black-true;
    border-radius: 6.7px;
  }
}

@media screen and (max-width: 944px) {
  .under-construction-content {
    h2,
    p {
      max-width: 327px;
    }
  }
}

@media screen and (max-width: 920px) {
  .download-links {
    img {
      width: 150px;
    }
  }
}

@media screen and (max-width: 912px) {
  .under-construction-content {
    text-align: center;
    h2 {
      max-width: unset;
    }
    p {
      max-width: unset;
    }
  }
  .app-download {
    justify-content: center;
  }
}

@media screen and (max-width: 560px) {
  .under-construction-content {
    h2 {
      font-size: $fs-24;
      line-height: 1.33;
      color: $color-black-true;
      padding: 0 30px;
    }
    p {
      font-size: $fs-16;
      color: $color-black-true;
      line-height: 1.5;
    }
  }
  .download-links {
    img {
      width: 100px;
    }
  }
}
