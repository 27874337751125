.download-app-web {
  display: flex;
  align-items: center;
  position: relative;
  &__img {
    position: initial;
    z-index: $z-index-1;
    img {
      width: 296px;
      height: 336px;
      object-fit: contain;
    }
  }
  &__content {
    position: absolute;
    right: 0;
    bottom: 23px;
    background-color: white;
    width: 440px;
    padding: 25px 53px 30px 103px;
    border-radius: 0 30px 30px 0;
    .title {
      font-size: $fs-24;
      font-weight: bold;
      margin-bottom: 30px;
      line-height: 1.33;
    }
    &__buttons {
      display: flex;
    }
  }
  &__download-buttons {
    max-width: 140px;
    a {
      &:last-child img {
        width: 123px;
      }
      img {
        width: 120px;
        height: 40.2px;
      }
    }
    a img:first-child {
      margin-bottom: 6px;
    }
  }
  &__qr {
    width: 86px;
  }
}
.custom-modal-download {
  .modal-body {
    padding: 0 !important;
  }
  .modal-dialog-centered {
    justify-content: center;
  }
  .modal-content {
    background-color: transparent;
    height: 350px;
    max-width: 710px;
    width: 657px;
  }
  .modal-header {
    position: absolute;
    right: 0px;
    top: 2px;
  }
  .btn-close {
    z-index: $z-index-1;
    position: absolute;
    top: 76px;
    right: 15px;
  }
}
body[dir='rtl'] {
  .download-app-web__content {
    right: unset;
    left: 0;
    padding: 25px 103px 30px 53px;
    border-radius: 30px 0 0 30px;
  }
  .custom-modal-download {
    .modal-header {
      right: unset;
      left: 0;
    }
    .btn-close {
      top: 80px;
      right: -27px;
    }
  }
  .download-app-web {
    &__download-buttons {
      a {
        &:last-child img {
          width: 120px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .custom-modal-download {
    .modal-dialog {
      min-width: fit-content;
    }
  }
}
@media only screen and (max-width: 710px) {
  .custom-modal-download {
    .modal-dialog {
      margin: auto;
    }
    .modal-header {
      display: none;
    }
    .download-app-web {
      display: none;
    }
  }
}
