.history-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0;

  p {
    font-weight: 600;
  }

  button {
    text-decoration: underline;
    color: $color-jungle-1000;
  }
}
