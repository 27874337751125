.post-bids {
  display: flex;
  align-items: center;

  &__label {
    font-size: $fs-12;
    font-weight: 600;
  }
}

.post-bids--light {
  .post-bids__label {
    color: $color-primary;
  }
}

.post-bids--primary,
.post-bids--secondary {
  .post-bids__label {
    color: $color-white-true;
  }
}
