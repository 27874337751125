.total{
    margin: 15px 0 15px 0;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__number-box{
        border-radius: 5px;
        border: solid 1px $color-grey-2300;
        padding: 5px 6px;
        color: $color-black-true;
        font-size: $fs-18;
        display: inline-block;
        min-width: 32px;
        min-width: 32px;
        text-align: center;
        line-height: normal;
        font-weight: 600;
    }
    &__label , &__label-mobile{
        margin: 0 10px;
    }
    &__label{
        font-size: $fs-14;
        color: $color-black-true;
        font-weight: 600;


    }
    &__label-mobile{
        font-size: $fs-14;
        color: $color-black-true;
        font-weight: 600;


    }
    &__right-content > button{
        color: $color-blue-200;
        font-size: $fs-12;
        font-weight: 600;
    }
}