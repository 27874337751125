.button-transparent-with-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  transition: 200ms;
  font-weight: 600;
  text-transform: capitalize;
  gap: 6px;

  &__text {
    color: $color-primary;
    font-size: $fs-12;
    font-weight: 600;
  }

  &__icon {
    font-size: $fs-18;
    color: $color-gray-200;
  }

  &:hover {
    .button-transparent-with-icon__icon {
      color: $color-grey-3900;
    }
  }
}

