.snackbox {
  text-align: center;
  padding: 26px 0;
  width: 100%;
  position: fixed;
  box-shadow: 0 3px 6px 0 $color-grey-3500;
  z-index: $z-index-7;
  justify-content: center;
  align-items: center;

  p {
    font-size: $fs-14;
    color: white;
    line-height: 1.71;
  }

  &--info {
    background-color: $color-info;
  }

  &--success {
    background-color: $color-success;
  }

  &--warn {
    background-color: $color-warn;
  }

  &--failure {
    background-color: $color-failure;
  }
}

@media only screen and (max-width: 560px) {
  .snackbox {
    padding: 17px 10px;

    p {
      font-size: $fs-13;
    }
  }
}
