.galleryIcon {
  display: flex;
  align-items: center;
  line-height: normal;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5rem 0.5rem 0.5rem;
  border-radius: 1.2rem;
  .icon-eye,
  .icon-heart,
  .icon-dots {
    font-size: $fs-14;
  }

  .icon-image {
    color: white;
  }

  &__value {
    font-size: $fs-11;
    margin-left: 4px;
    font-weight: 600;
    p {
      line-height: initial;
    }
  }
}

.galleryIcon--light {
  .galleryIcon__value,
  .icon-heart,
  .icon-dots,
  .icon-heart-filled {
    color: white;
  }
}
.galleryIcon--dark {
  .galleryIcon__value,
  .icon-heart,
  .icon-dots {
    color: $color-gray-200;
    opacity: 0.2;
  }
}
body[dir='rtl'] {
  .galleryIcon__value {
    margin-right: 4px;
    margin-left: initial;
  }
}
