.not-available {
  display: flex;
  align-items: center;
  color: $color-white-true;
  white-space: nowrap;
  .icon-sold_out {
    margin-right: 5px;
  }
  small{
    line-height: 1px;
  }
}

body[dir='rtl'] {
  .not-available {
    .icon-sold_out {
      margin-left: 5px;
      margin-right: 0px;
    }
  }
}
