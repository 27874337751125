.share-content {
  min-width: 128px;
  &__title {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: $fs-16;
    color: $color-black-true;
  }
  &__subtitle {
    font-size: $fs-12;
    font-weight: 600;
    color: $color-black-true;
  }

  .socials {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 20px 20px;
    span {
      padding: 12px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      font-size: $fs-21;
    }

    .icon-facebook {
      color: $color-blue-1700;
    }
    .icon-whatsapp {
      color: $color-green-100;
    }
    .icon-twitter {
      color: $color-blue-1800;
    }
    .icon-instagram {
      color: $color-grey-4500;
    }
    .icon-linkedin::before {
      background-color: $color-blue-1900;
      color: $white;
      border-radius: 3px;
    }
  }
}
@media only screen and (max-width: 560px) {
  .share-content {
    padding-bottom: 9px;
  }
}
