.add-to-watch-list {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  .icon {
    transition: 200ms;
  }
}

.add-to-watch-list--light {
  background-color: $color-jungle-900;

  .icon {
    color: $color-jungle-1200;
  }

  .icon:hover {
    color: $color-jungle-1300;
  }
}

.add-to-watch-list--filled {
  background-color: $color-white-true;
  box-shadow: 0 1.5px 3px 0 $color-grey-1000;

  .icon {
    color: $color-jungle-1000;
  }

  .icon:hover {
    color: $color-jungle-1300;
  }
}

.add-to-watch-list--dark {
  background-color: $color-white-true;
  box-shadow: 0 1.5px 3px 0 $color-grey-1000;

  .icon {
    color: $color-jungle-1200;
  }
}

.add-to-watch-list--outlined {
  color: white;

  .icon-heart-filled:before {
    content: '\e916';
  }

  .icon:hover {
    color: #008080;
  }
}

.add-to-watch-list--medium {
  padding: 8px 9px;
  border-radius: 15px;

  .icon {
    font-size: 31px;
  }
}

.add-to-watch-list--sm {
  height: 24px;
  width: 24px;
  border-radius: 5px;

  .icon {
    font-size: 18px;
  }

  .icon:hover {
    color: $color-primary; //add in colors
  }
}

.add-to-watch-list--sm {
  height: 24px;
  width: 24px;
  border-radius: 5px;

  .icon {
    font-size: 18px;
  }

  .icon:hover {
    color: rgba(0, 120, 178, 0.3); //add in colors
  }
}

//@TODO : should be removed from here --> not the right place
.post-details-watchlist {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  right: -40px;
  margin-top: 0;
  padding: 5px;
}

.post-details-watchlist .icon:hover {
  color: #008080;
}
