.display-inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.text-gray {
  color: $color-grey-1300;
}

.text-primary {
  color: $color-primary !important;
}

.text-secondary {
  color: $color-secondary !important;
}

.text-white {
  color: $color-white-true;
}

.text-black {
  color: $color-black-true !important;
}

.text-light-blue {
  color: $color-blue-300 !important;
}

.bg-white {
  background-color: $color-white-true;
}

.bg-black {
  background-color: $color-black-true;
}

.bg-secondary {
  background-color: $color-secondary !important;
}

.bg-green {
  background-color: $color-green-1000 !important;
}

.bg-violate {
  background-color: $color-violet-500;
}
.bg-light-violate {
  background-color: $color-violet-800;
}
.text-violate {
  color: $color-violet-500;
}
.text-orange {
  color: $color-orange-100;
}

.text-green {
  color: $color-green-1000;
}

.text-blue {
  color: $color-blue-200;
}

.bg-primary {
  background-color: $color-primary !important;
}
.bg-light-primary {
  background-color: $color-jungle-900;
}

.bg-lightgray {
  background-color: $color-gray-700;
}

.bg-lightBlue {
  background-color: $color-blue-300;
}

.text-lightBlue {
  color: $color-blue-300;
}

.grey-container {
  background-color: $color-gray-700;
  padding: 0 0.5rem;
  border-radius: 5px;
}

.blue-container {
  background-color: $color-jungle-900;
  padding: 8px 10.5px;
  border-radius: 10px;
  text-align: center;

  &__icon {
    width: 24px;
    height: 24px;
  }
}

.purple-container {
  background-color: $color-purple-700;
  padding: 0.25rem 0.5rem;
  border-radius: 10px;
}

.text-purple {
  color: $color-purple-100;
}

.yellow-container {
  background-color: $color-orange-700;
  padding: 10px;
  border-radius: 10px;
}

.orange-container {
  background-color: $color-orange-800;
  padding: 0.25rem 0.5rem;
  border-radius: 10px;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-transparent {
  background: unset;
  border: unset;
  padding: unset;
  color: unset;
}

.button-hyperlink {
  background: unset;
  border: unset;
  transition: 200ms;
}
.button-hyperlink--gray {
  color: $color-grey-4000;
}
.button-hyperlink--gray:hover {
  color: $color-primary;
}
.button-hyperlink--borderd {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.button-hyperlink--borderd:hover {
  text-decoration: none;
}

.button-hyperlink--secondary {
  color: $color-jungle-1000;
}

.button-hyperlink--secondary:hover {
  color: $color-primary;
}

.button-hyperlink--primary {
  color: $color-primary;
}

.button-hyperlink--primary:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.button-small-main-transparent {
  color: $color-grey-2500;
  padding: 6px 5px;
  transition: 200ms;
  border-radius: 10px;
}

.button-small-main-transparent:hover {
  color: $color-primary;
  background-color: $color-blue-900;
}

.scrollable {
  overflow-x: auto;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

.scrollable::-webkit-scrollbar {
  display: none !important;
}

.scrollable-mobile {
  overflow-x: unset;
}

.screen {
  padding: 0 9.948vw 10px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  background-color: white;
}

.border-bottom {
  padding-bottom: 20px;
  border-bottom: 1px solid $color-grey-2300 !important;
}

.border-top {
  border-top: 1px solid $color-grey-1000 !important;
}

.status {
  color: $color-white-true;
  font-weight: 500;
  width: fit-content;
  height: fit-content;
  padding: 2px 5px;
  border-radius: 5px;

  &--completed,
  &--closed {
    background: $color-success-500;
  }

  &--cancelled {
    background: $color-attention-500;
  }

  &--pending,
  &--created {
    background: $color-yellow-100;
  }
}

.active {
  background-color: $color-blue-100;
}

.modal-header .btn-close {
  margin: unset !important;
}

.btn-close {
  background-color: $color-grey-3200 !important;
  background-size: 26px 26px !important;
  border-radius: 50% !important;
  opacity: 1;
  background: transparent url(../../../assets/icons/close-icon.svg) center/1em
    auto no-repeat;
  width: 12px;
  height: 12px;
  padding: 10px !important;
  transition: 200ms;
}

.btn-close:hover {
  background: transparent url(../../../assets/icons/close-icon-hover.svg)
    center/1em auto no-repeat;
  opacity: 1;
}

.btn-close:focus {
  background: transparent url(../../../assets/icons/close-icon-focus.svg)
    center/1em auto no-repeat;
  opacity: 1;
}

.btn-close:focus {
  box-shadow: initial;
}

.img-thumbnail {
  padding: 0;
}

.img-small {
  width: 32px;
  height: 32px;
}

.img-medium {
  width: 48px;
  height: 48px;
}

.badge {
  display: inline-flex;
  border-radius: 5px;
}

.badge--carousel {
  padding: 5px;
}

.badge--card {
  height: min-content;
  padding: 5px 5px 3.5px;
}

.badge--dark {
  background-color: $color-grey-4200;
}

.badge--right {
  position: absolute;
  z-index: $z-index-1;
  right: 10px;
}

.custom-shadow {
  box-shadow: 0 5px 6px 0 #00000029;
}

.d-web {
  display: block !important;
}

.d-web-flex,
.d-flex-only-web {
  display: flex !important;
}

.d-web-table {
  display: table !important;
}

.d-web-flex-inline {
  display: inline-flex;
}

.d-inline-web {
  display: inline-block;
}

.d-mobile,
.d-mobile-flex,
.d-inline-mobile,
.d-tablet {
  display: none !important;
}

.color-gray-icon {
  color: $color-gray-icon;
}

.bg-error {
  background-color: $color-tomato-100 !important;
}

.text-error {
  color: $color-tomato-100 !important;
}

.bg-orange {
  background-color: $color-yellow-100 !important;
}

.bg-lightgray {
  background-color: $color-grey-2300 !important;
}

.zindex-top-level {
  z-index: $zindex-top-level !important;
}

.devider::after {
  content: '|';
  color: $color-grey-2300;
  margin: 0 6px;
  font-weight: normal;
}
.p-relative {
  position: relative;
}

.question-mark {
  padding: 2px;
  border-radius: 50%;
  font-size: 20px;
  color: $color-grey-3400;
  transition: 200ms;
}

.question-mark--error {
  background-color: $color-tomato-700;
  color: $color-tomato-100;
}

.question-mark--active {
  background-color: $color-blue-900;
  color: $color-primary;
}

@media only screen and (max-width: 1024px) {
  .d-tablet {
    display: block !important;
  }

  .d-flex-only-web {
    display: none !important;
  }
}

@media only screen and (max-width: 912px) {
  .d-web,
  .d-web-flex,
  .d-web-table,
  .d-web-flex-inline,
  .d-inline-web {
    display: none !important;
  }

  .d-mobile {
    display: block !important;
  }

  .d-mobile-flex {
    display: flex !important;
  }

  .d-inline-mobile {
    display: inline-block !important;
  }

  .scrollable-mobile {
    overflow: auto;
  }

  .screen {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1700px) {
  .screen {
    padding: 0 1em 2.0625em 1em;
  }
}

.disabled {
  color: $color-grey-4000 !important;
  cursor: unset;

  p,
  small,
  sup,
  span {
    color: $color-grey-4000 !important;
  }
}
