.otp-form {
  background-color: $color-white-true;
  width: 470px;
  text-align: center;
  padding: 30px 50px;
  border-radius: 20px;

  hr {
    display: none;
  }

  img {
    margin-bottom: 30px;
    width: 100px;
  }

  &__header {
    .info {
      &__title {
        font-size: $fs-24;
        margin-bottom: 20px;
      }

      &__description {
        font-size: $fs-14;
        color: $color-grey-3400;
      }
    }
  }

  form {
    width: 100%;

    .submit-otp {
      margin-bottom: 20px;
    }

    .input-group-otp {
      height: unset;
      display: grid;
      margin-bottom: 30px;

      input {
        margin: auto;
        position: relative;

        &::placeholder {
          top: -5%;
          font-weight: bold;
          color: $color-grey-3000;
        }
      }

      .error-message {
        text-align: center;
        margin-top: 5px;
        font-size: $fs-11;
      }
    }

    .counter {
      justify-content: center;
    }
  }

  .text-field {
    width: 100%;
  }

  &__footer {
    margin-top: 19px;
  }

  .icon {
    font-size: $fs-14;
    color: $color-gray-200;
  }
}

.otp-mobile {
  display: flex;
  justify-content: center;

  &__text {
    font-size: $fs-18;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
  }

  &__button {
    font-size: $fs-12;
    font-weight: 600;
    margin: 0 10px;
  }
}

.custom-modal-otp {
  background-color: $color-grey-4300;
  opacity: 1;

  .modal-content {
    border-radius: 25px;
    background-color: transparent;

    .modal-header {
      button {
        position: absolute;
        z-index: 100;
        top: 20px;
        right: 10px;
      }
    }
  }
}

body[dir='rtl'] {
  .custom-modal-otp {
    .modal-content {
      .modal-header {
        button {
          right: 39px;
        }
      }
    }
  }
}

@media only screen and (max-width: 912px) {
  .otp-form {
    max-width: 100%;
  }
  .custom-modal-otp {
    .modal-content {
      .modal-header {
        button {
          right: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .otp-form {
    width: 100%;
    padding: 20px;
    border-radius: 20px 20px 0 0;

    img {
      margin-bottom: 20px;
      width: 80px;
    }

    input {
      padding: 35px;
    }

    input[type='number']::placeholder {
      font-size: 14px;
      letter-spacing: normal;
      top: 40%;
      text-align: center;
    }

    hr {
      display: block;
      height: 4px;
      width: 28px;
      margin: auto;
      margin-bottom: 20px;
      color: $color-grey-1000;
      border-radius: 2px;
      opacity: 1;
    }

    &__header {
      .info {
        &__title {
          font-size: $fs-18;
        }
      }
    }
  }
  .otp-mobile {
    justify-content: center;
  }
  .custom-modal-otp {
    .modal-content {
      .modal-header {
        button {
          display: none;
        }
      }
    }

    .modal-body {
      padding: 0;
    }
  }
}

body[dir='rtl'] {
  .otp-mobile {
    &__text {
      direction: ltr;
    }
  }
}
