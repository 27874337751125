.post-timer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-bottom: initial;

  &__title {
    display: inline-block;
    font-size: $fs-13;
    font-weight: 600;
    color: $color-black-true;
    text-align: left;
  }
}

.post-timer:only-child {
  width: 100%;
  margin-right: 0;

  br {
    display: none;
  }
}

.yellow-container {
  .post-timer__title {
    color: $color-black-true;
  }
}

.orange-container {
  padding: 15px 0;

  .post-timer__last-Chance-title {
    width: 100%;
    color: $color-orange-300;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: $fs-14;
      font-weight: 600;
      padding: 0 5px;
    }

    img {
      height: 24px;
      width: 24px;
    }
  }
}

.post-timer--loading {
  height: 62px;
  width: 100%;
  background-color: $color-gray-700;
  width: 321px;
  border-radius: 5px;
}

.post-timer-zero-state {
  background-color: $color-gray-800;
  font-size: 14px;
  color: $color-grey-3700;
  font-weight: 600;
  padding: 20px 0;
  border-radius: 10px;
  flex: 1 0;

  span {
    font-size: 24px;
    margin: 0 10px;
  }

  .icon-sold_out {
    font-size: 24px;
    margin: 0 5px;
  }

  .zero-state-text {
    font-size: $fs-14;
    line-height: normal;
  }
}

body[dir='rtl'] {
  .post-timer {
    &__title {
      text-align: right;
    }
  }

  .post-timer-zero-state {
    margin-right: initial;
    margin-left: 10px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1377px) {
  .post-timer {
    width: 100%;
  }
}
