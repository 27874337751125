.input-group-otp {
  height: 113px;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.input-otp {
  width: 100%;
  height: 82px;
  border: solid 1px $color-grey-2300;
  border-radius: 15px;
  line-height: 0.45;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.13;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 20px;
}

.input-otp::placeholder {
  position: relative;
  top: 10px;
  font-size: 16px;
  color: $color-grey-2500;
  letter-spacing: normal;
}

.input-otp.input-otp-error,
.input-otp.input-otp-error:focus,
.input-otp.input-otp-error:focus-visible {
  border-color: $color-tomato-100 !important;
  box-shadow: none !important;
}

.input-otp:focus,
.input-otp:focus-visible {
  border-color: $color-primary !important;
  box-shadow: none !important;
  outline: none !important;
}

body[dir='rtl'] {
  .input-otp {
    direction: ltr;
  }
}

@media only screen and (max-width: 912px) {
  .input-otp {
    margin: auto;
  }
}

@media only screen and (max-width: 560px) {
  .input-otp {
    width: 100%;
    height: 71px;
  }
  .input-group-otp {
    height: 100px;
  }
}
