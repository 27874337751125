.current-price {
  font-weight: bold;
  &--vertical {
    display: inline-block;
  }
  &--horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__value {
    display: flex;
    line-height: 1;
    sup {
      font-weight: 700;
    }
  }
  &__small-label {
    font-weight: 600;
    text-transform: capitalize;
    color: $color-black-true;
  }
  &__button {
    font-size: $fs-12;
    color: $color-green-1100;
    text-decoration: underline;
    text-underline-offset: 2px;
    cursor: pointer;
    margin: 0 3px;
    font-weight: normal;
  }
}
