.category-items {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  .items-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    span {
      font-size: 0.8rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
