.no-found-wrapper {
  width: 52vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  img {
    width: 50%;
  }
  .no-found-content {
    h2 {
      font-size: $fs-32;
      font-weight: bold;
      line-height: 1.5;
      color: $color-grey-3900;
      max-width: 357px;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: $color-gray-200;
      max-width: 349px;
      margin-bottom: 30px;
    }
    .no-found-buttons {
      display: flex;
      gap: 17px;
    }
  }
}

@media screen and (max-width: 1530px) {
  .no-found-wrapper {
    width: 60vw;
  }
}

@media screen and (max-width: 1399px) {
  .no-found-wrapper {
    width: 70vw;
  }
}

@media screen and (max-width: 1199px) {
  .no-found-wrapper {
    width: 80vw;
  }
}

@media screen and (max-width: 912px) {
  .no-found-wrapper {
    width: 90vw;
    flex-direction: column;
    gap: 30px;
  }
  .no-found-content {
    text-align: center;
    h2 {
      max-width: unset;
    }
    p {
      max-width: unset;
    }
    .no-found-buttons {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 560px) {
  .no-found-content {
    h2 {
      font-size: $fs-24;
      line-height: 1.33;
      color: $color-black-true;
      padding: 0 30px;
    }
    p {
      font-size: $fs-16;
      color: $color-black-true;
      line-height: 1.5;
    }
    .no-found-buttons {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 475px) {
  .no-found-wrapper {
    img {
      width: 100%;
    }
  }
}
