/**
 * Please file all font-face values in here
 */

@font-face {
  font-family: "Montserrat";
  src: url(./fonts/Montserrat-Regular.woff2) format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(./fonts/Montserrat-Medium.woff2) format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(./fonts/Montserrat-SemiBold.woff2) format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(./fonts/Montserrat-Bold.woff2) format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSansArabic";
  src: url(./fonts/IBMPlexSansArabic-Regular.woff2) format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSansArabic";
  src: url(./fonts/IBMPlexSansArabic-Medium.woff2) format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSansArabic";
  src: url(./fonts/IBMPlexSansArabic-SemiBold.woff2) format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSansArabic";
  src: url(./fonts/IBMPlexSansArabic-Bold.woff2) format("woff2");
  font-weight: 700;
  font-display: swap;
}
