.quantity-input-group {
  border: 1px solid $color-grey-2300;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 5px;
  transition: 200ms;
  background-color: white;
  .add,.minus{
    font-size: 24px;
    cursor: pointer;
    transition: 200ms;
  }
  .icon-mins_circle,
  .icon-add_circle {
    color: $color-grey-2300;
  }
  .icon-mins_circle:hover,
  .icon-add_circle:hover ,
  .icon-add_cirlce_filled,
  .icon-minus_cirlce_filled{
    color: $color-primary;
  }
  .quantity-input {
    width: 35px;
    line-height: 24px;
    text-align: center;
    font-size: $fs-18;
    font-weight: 600;
    color: $color-black-true;
  }
  .quantity-button{
    margin: 0;
    max-height: 24px;
  }
  .quantity-button[disabled] ,
  .quantity-button:disabled{
    background-color: unset;
  }
}
.quantity-input-group--disabled,
.quantity-input-group.quantity-input-group--disabled:hover {
  border-color: $color-grey-1000;
  .quantity-input,
  .add,
  .minus {
    color: $color-grey-1000;
  }
}
.quantity-input-group:hover {
  border-color: $color-primary;
}
