.card-horizontal-lg {
  z-index: $z-index-1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  position: relative;
  gap: 30px;
  margin: auto 17%;

  .watch-list {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-gray-200;
    border-radius: 32px;
    padding: 8px;
    box-sizing: content-box;
  }

  &__vertical-text-mobile {
    text-align: center;
    color: $color-white-true;
    line-height: 0.94;
    margin-bottom: 20px;
    display: none;

    h2 {
      font-size: $fs-32;
      font-weight: 900;
    }
  }

  &__figure {
    position: relative;
    aspect-ratio: 4/3;
    position: relative;

    &__header {
      display: flex;
      position: absolute;
      z-index: 100;
      padding: 30px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      .group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
      }
    }

    img {
      border-radius: 5px;
      outline: solid 20px $color-white-2600;
      outline-offset: -20px;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:hover {
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
      }
    }

    &__vertical-text {
      position: absolute;
      left: -77px;
      bottom: 0;
      height: 100%;
      width: 100px;
      display: flex;

      h2 {
        transform: rotate(180deg);
        white-space: nowrap;
        position: absolute;
        bottom: -4px;
        left: 3px;
        writing-mode: vertical-lr;
        color: $color-white-true;
        opacity: 0.3;
        letter-spacing: 0.3px;
        font-weight: 900;
        font-size: $fs-78;
      }
    }
  }

  .progressbar label {
    font-size: $fs-12;
    color: $color-grey-3900;
  }

  &__left {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__content {
    flex: 1;
    transition: 200ms;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__title:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    > div {
      margin-bottom: 20px;
      width: 100%;
    }

    div:last-child {
      margin-bottom: initial;
    }

    h3 {
      font-size: $fs-24;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      overflow-wrap: break-word;
    }

    &__quantity {
      max-width: 280px;

      &__progress {
        margin-left: initial;
        margin-right: initial;

        laber {
          font-size: $fs-12;
        }
      }
    }

    &__timer {
      &__title {
        font-size: $fs-14;
        font-weight: 600;
        color: $color-grey-3900;
        margin-bottom: 15px;
      }

      &__box:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  &__price {
    font-size: $fs-24;
    color: $color-secondary;
    font-weight: bold;

    sup {
      font-weight: bold;
    }
  }

  &__currency {
    font-size: $fs-14;
    top: 8.5px;
    right:2px;
    padding-left: 2px;
  }

  &__buttons {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;

    button {
      line-height: 18px;
    }
  }

  &__share button {
    width: 50px;
    padding: 9px 0;

    .icon {
      font-size: $fs-32;
      color: $color-grey-2550;

      &:hover {
        color: $color-grey-3400;
      }
    }
  }

  .discount-small-view__old-price {
    margin: 0 15px;
  }
  &__price-group{
    display: flex;
  }
}

.share-and-watchlist {
  display: flex;
  align-items: center;
}

body[dir='rtl'] {
  .card-horizontal-lg {
    &__content {
      &__timer__box:not(:last-child) {
        margin-right: initial;
        margin-left: 15px;
      }
    }
    &__currency {
      order: 2;
      top: 6px;
      right: 1px;
    }

    &__figure__vertical-text {
      right: -94px;
      left: unset;
      z-index: -1;

      h2 {
        transform: unset;
        position: unset;
        text-align: center;
      }
    }

    .progressbar {
      margin-right: initial;
      margin-left: initial;
    }
  }
}

@media screen and (max-width: 1920px) {
  .card-horizontal-lg {
    max-width: 1170px;
    margin: auto;
  }
}

@media only screen and (max-width: 1280px) {
  .card-horizontal-lg {
    padding: 130px 30px 5px;
    max-width: unset;
    width: 100%;

    &__figure__vertical-text {
      position: absolute;
      left: unset;
      bottom: unset;
      top: 14px;
      height: unset;
      width: unset;
      display: flex;

      h2 {
        opacity: unset;
        transform: unset;
        bottom: 0;
        left: unset;
        writing-mode: unset;
        font-size: $fs-48;
        margin-bottom: 30px;
      }
    }
  }
  body[dir='rtl'] {
    .card-horizontal-lg__figure__vertical-text {
      right: 0;
      z-index: -1;
      top: -83px;
    }
  }
}

@media only screen and (max-width: 820px) {
  .card-horizontal-lg {
    &__left {
      flex: 1.7;
    }

    &__figure__vertical-text {
      top: 11px;

      h2 {
        font-size: $fs-40;
      }
    }

    &__content__title {
      h3 {
        font-size: $fs-16;
        text-transform: capitalize;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .card-horizontal-lg {
    display: block;
    padding: 0 20px 0;

    &__content {
      max-width: unset;
    }

    &__left {
      display: initial;
    }

    &__vertical-text-mobile {
      display: block;

      h2 {
        font-weight: bold;
      }
    }

    &__buy-now,
    &__buy-now button {
      width: 100%;
    }

    &__figure {
      width: unset;
      max-width: unset;

      img {
        border-radius: unset;
      }
    }

    &__share {
      position: absolute;
      top: 79px;
      right: 80px;
      z-index: 100;
      height: 32px;
      width: 32px;

      button {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $color-grey-3400;

        &:hover {
          background-color: $color-grey-3900;
        }

        .icon {
          font-size: $fs-20;
          color: $color-white-true;

          &:hover {
            color: $color-white-true;
          }
        }
      }
    }

    .watch-list {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      padding: 6px;
      // @todo: need to handle sm size
      .icon-heart,
      .icon-heart-filled {
        font-size: $fs-20;

        &:hover {
          background-color: $color-grey-3900;
        }
      }
    }
  }
  .card-horizontal-lg__figure__vertical-text {
    display: none;
  }
  .card-horizontal-lg__content {
    padding: 24px 0 0 0;
  }
  .card-horizontal-lg__figure img {
    outline: solid 10px rgba(255, 255, 255, 0.26);
    outline-offset: -10px;
  }
  .card-horizontal-lg__figure__header {
    padding: 20px;
  }
  .card-horizontal-lg__content > div {
    margin-bottom: 15px;
  }
  .card-horizontal-lg__content {
    div:last-child {
      margin-bottom: initial;
    }
  }
  body[dir='rtl'] {
    .card-horizontal-lg__share {
      right: unset;
      left: 80px;
    }

    .card-horizontal-lg__content {
      padding: 24px 0 0 0;
    }
  }
}

@media screen and (max-width: 560px) {
  .card-horizontal-lg__content__quantity {
    max-width: unset;
  }
  .card-horizontal-lg__buttons {
    flex: 1;
  }
}
