.popover-wrapper {
  cursor: auto;
  position: absolute;
  z-index: $z-index-2;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 232px;
  font-size: $fs-11;
  padding: 12px 0 10px 0;
  right: 0;
  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 1rem;
    border: 10px solid transparent;
    border-top: none;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-top-right-radius: 10px;
  }
}
.popover--light {
  background-color: $color-white-true;
  color: $color-black-true;
}
.popover--light.popover-wrapper::before {
  border-bottom-color: $color-white-true;
}
.popover--dark {
  background-color: $color-black-true;
  color: $color-white-true;
  padding: 15px;
}
.popover--dark.popover-wrapper::before {
  border-bottom-color: $color-black-true;
}
.popover--top.popover-wrapper::before {
  transform: rotate(180deg);
  bottom: -7px;
}
.popover--bottom.popover-wrapper::before {
  top: -7px;
}
.popover--fullWidth {
  width: 100% !important;
}

body[dir='rtl'] {
  .popover-wrapper {
    left: 0;
    right: unset;
    &::before {
      left: 1rem;
      right: unset;
    }
  }
}

@media screen and (max-width: 560px) {
  .popover-wrapper {
    width: 320px;
    &::before {
      right: 2.4rem;
    }
  }

  body[dir='rtl'] {
    .popover-wrapper {
      &::before {
        left: 2.4rem;
        right: unset;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .popover-wrapper {
    width: 260px;
    &::before {
      right: 2.4rem;
    }
  }
}
