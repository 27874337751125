.header-icons {
  font-size: $fs-24;
  display: flex;
  align-items: baseline;
  margin-left: 12px;

  .button-transparent {
    .icon-heart {
      position: relative;

      &__indicator {
        position: absolute;
        top: -2px;
        right: 0;
        width: 10px;
        height: 10px;

        svg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }

        svg > g > circle:first-child {
          fill: $color-tomato-400;
          stroke: $color-tomato-400;
          border-color: $color-tomato-400;
          r: 15px;
        }

        svg > g > circle:last-child {
          fill: $color-tomato-200;
          stroke: $color-tomato-200;
          border-color: $color-tomato-200;
          r: 15px;
        }
      }
    }
  }

  &_profile {
    .popover-wrapper {
      top: 62px;
      right: -11px;
      padding: 5px 0;
    }
  }

  button,
  a {
    margin-left: 18px;
    color: $color-white-true;
    opacity: 1;
    display: flex;
    align-items: center;
    transition: 200ms;
  }

  button:hover,
  a:hover {
    opacity: 0.5;
  }

  &__language {
    color: $color-white-true;
    font-size: $fs-16;
  }

  &__sign-up {
    color: $color-white-true;
    font-size: $fs-12;
    font-weight: 600;
  }
}

body[dir='rtl'] {
  .header-icons {
    margin-left: unset;
    margin-right: 12px;
    display: flex;
    gap: 20px;
    align-items: baseline;

    .button-transparent {
      .icon-heart {
        position: relative;

        &__indicator {
          left: 0;
          right: unset;
        }
      }
    }

    &_profile {
      .popover-wrapper {
        right: unset;
        left: 8px;
      }
    }

    button,
    a {
      margin-left: unset;
    }
  }
}

.login-user-avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-top: -4px;
  cursor: pointer;
}

@media only screen and (max-width: 912px) {
  .header-icons {
    margin: unset;

    button,
    a {
      margin-left: 20px;
    }
  }
  body[dir='rtl'] {
    .header-icons {
      margin-right: unset;
    }
  }
}

@media only screen and (max-width: 320px) {
  .header-icons {
    margin: unset;

    button,
    a {
      margin-left: 8px;
    }
  }
}
