.post-card-image {
  position: relative;
  width: 100%;

  img {
    height: auto;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 4/3;
  }

  &__labelsIcons-wrapper {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &__label-views-container {
      display: flex;
      margin-top: 10px;
      margin-left: 10px;
    }

    &__icons-container {
      display: flex;
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}

body[dir='rtl'] {
  .post-card-image {
    &__labelsIcons-wrapper {
      &__label-views-container {
        margin-right: 10px;
        margin-left: 0px;
      }

      &__icons-container {
        margin-left: 10px;
        margin-right: 0px;
      }
    }
  }
}
