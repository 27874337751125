.search-header {
  background-color: $color-blue-100;
  z-index: $zindex-header-search;
  position: relative;
  height: $header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  .logo-navigation {
    .mazadat-logo-header {
      width: 178.4px;
      height: auto;
      aspect-ratio: 89/20;
    }

    .logo-without-text {
      margin: 0 10px;
      width: 37px;
    }
  }

  .icons-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
}

.searchBar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-grey-4300;
  z-index: $zindex-overlay-header-search;
  opacity: 1;
  cursor: pointer;
}

body[dir='rtl'] {
  margin: 0;
  padding: 0;

  .search-header {
    .logo-navigation {
      margin-right: 0;
    }

    .icons-wrapper {
      img {
        margin-left: 0;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .search-header .logo-navigation .mazadat-logo-header {
    width: 145px;
  }
}

@media only screen and (max-width: 912px) {
  .search-header {
    height: $mobile-header-height;
    padding: 0 20px;

    &__mobile-icon {
      font-size: $fs-16;
    }

    .logo-navigation .mazadat-logo-header {
      width: 111px;
    }

    .icons-wrapper {
      position: unset;
    }
  }
  body[dir='rtl'] {
    .search-header {
      &__mobile-icon {
        margin-left: initial;
        margin-right: 20px;
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  body[dir='rtl'] {
    .search-header {
      &__mobile-icon {
        margin-left: initial;
        margin-right: 8px;
      }
    }
  }
}

@media only screen and (max-width: 321px) {
  body[dir='rtl'] {
    .searchBar-overlay {
      width: 500px;
      right: -100px;
    }
  }
}

@media only screen and (max-width: 280px) {
  .search-header {
    padding: 0 5px;
  }
}
