.create-post {
  position: relative;

  &__popover {
    top: 61px;
  }
  .popover-wrapper {
    width: 280px;
    box-shadow: 0 3px 6px 0 $color-grey-1600;
    left: 0;
    &::before {
      left: 1rem;
      right: unset;
    }
  }
}

body[dir='rtl'] {
  .create-post {
    .popover-wrapper {
      right: 0;
      left: unset;
      &::before {
        left: unset;
        right: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .create-post {
    .add-button__text {
      font-size: $fs-11;
      padding: 12px 12px 12px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
