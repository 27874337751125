.post-label {
  margin-right: 5px;
  min-width: 36px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;

  &__condition-wrapper {
    top: 0;
    border-radius: 5px;
    background-color: $color-white-true;
    color: $color-black-true;
    padding: 4px 5px;
    line-height: initial;

    &__post-condition {
      font-size: $fs-10;
      font-weight: 600;
    }
  }

  .label-newText {
    background-color: $color-green-200;
    color: $color-green-100;
  }

  .label-mint {
    background-color: $color-violet-800;
    color: $color-violet-700;
  }

  .label-defective {
    background-color: $color-orange-800;
    color: $color-orange-300;
  }

  .label-used {
    background-color: $color-blue-50;
    color: $color-blue-100;
  }
}

body[dir='rtl'] {
  .post-label {
    margin-left: 5px;
    margin-right: 0;
  }
}
