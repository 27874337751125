.blue-container{
    &__icon{
        margin-right: 1.5%;
    }
    &__label{
        font-weight: 600;
        color: $color-black-true;
        line-height: normal;
    }
}
body[dir='rtl']{
    .blue-container{
        &__icon{
            margin-right: initial;
            margin-left: 1.5%;
        }
    }
}