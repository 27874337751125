.question-list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    margin-top: 3px;

    &__answers {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
        justify-content: flex-start;
        align-items: center;



        &--avatar {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
        }

        &--viewMore.button-transparent-with-icon {
            font-size: $fs-11;
            margin-left: 5px;

        }

        &--hideReplies.button-transparent-with-icon {
            font-size: $fs-11;
            justify-content: flex-start;
            margin-top: 30px;
        }


        .reply-border {
            position: relative;
            margin-bottom: 0;

            &::before {
                content: "";
                position: absolute;
                border: 0 solid transparent;
                border-radius: 0 0 0px 10px;
                left: -20px;
                width: 20px;
                height: 70px;
                top: -50px;
                border-bottom: 1px solid $color-grey-2300;
                border-left: 1px solid $color-grey-2300;
                ;
            }

            img {
                position: relative;
                left: -10px;
                margin: 0;
                height: 28px;
                width: 28px;
            }

            img:first-child {
                left: 0;
            }
        }

        .remaining-answers {
            width: 28px;
            height: 28px;
            background-color: $color-gray-800;
            color: $color-blue-100;
            border-radius: 50%;
            padding: 6px;
            position: relative;
            left: -20px;
            font-size: $fs-11;
            font-weight: 600;
            text-align: center;

        }



    }



    &__container {
        display: flex;
        flex-direction: column;
        gap: 10px;

    }

    &__reply {
        font-size: $fs-11;
    }

    &__avatar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        img {
            width: 32px;
            height: 32px;
            margin: 0px 10px 0px 0;
            border-radius: 50%;
        }
    }

    &__box {
        background-color: $color-jungle-900;
        border-radius: 0px 10px 10px 10px;
        padding: 12px;
        color: $color-black-true;
        font-size: $fs-14;
        font-weight: 600;
        white-space: pre-wrap;

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
            word-break: break-all;
        }

    }

    &__small-Text {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-left: 25px;


        &__name {
            font-size: $fs-15;
            color: $color-black-true;
            line-height: 1.6;
            letter-spacing: -0.12px;
            text-align: start;
            font-weight: 600;

        }

        &__time {
            font-size: $fs-11;
            color: $color-gray-200;
            padding: 0 0.25rem;
            font-weight: 500;

        }
    }

}

button.loadMore {
    font-size: $fs-11;
    margin-top: 30px;
}

body[dir='rtl'] {
    .question-list {
        &__box {
            border-radius: 25px 0 25px 25px;
        }

        &__box:before {
            left: initial;
            right: -3px
        }

        &__small-Text {
            margin-right: 25px;
        }

        &__avatar {
            img {
                margin: 0 0 0 10px;
            }
        }

        &__answers {
            margin-right: 20px;

            .reply-border {
                &::before {
                    content: "";
                    border-radius: 0px 0px 10px 0px;
                    border-right: 1px solid rgba(0, 0, 0, 0.2);
                    right: -22px;
                    border-left: none;
                }

                img {
                    right: -20px;
                }

                img:first-child {
                    right: 0;
                }
            }

            .remaining-answers {
                right: -40px;

            }
        }
    }
}