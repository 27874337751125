.questions-and-answers {
  margin-top: 30px;
  &__collapsed {
    display: flex;
    flex-direction: column;
  }

  &__ask {
    display: flex;
    align-items: center;

    .icon-add {
      font-size: 24px;
      margin: 0 5px;
    }

    p {
      font-size: $fs-12;
    }
  }

  &__button {
    margin-top: 20px;
  }

  .add-question-container {
    margin-top: 20px;
  }

  .ask-question {
    display: flex;
    align-items: center;
    justify-content: center;

    &__plus {
      color: $color-grey-3000;
      font-size: 14px;
      margin: 0 4px;
    }
  }
  &--loadMore {
    font-size: $fs-11;
    margin-top: 30px;
    justify-content: center;
  }
}

@media only screen and (max-width: 912px) {
  .questions-and-answers {
  }
}

@media only screen and (max-width: 425px) {
  .questions-and-answers {
    margin: 10px 0;
    padding: 0;
  }
}
