.avatar {
  display: inline-block;
  position: relative;
  cursor: pointer;
  &__img {
    border-radius: 50%;
    opacity: 0.7;
  }
  &__img--small {
    height: 60px;
    width: 60px;
  }
}
.avatar--selected {
  .avatar__img {
    opacity: 1;
  }
  .avatar__icon{
    transition: 200ms;
    position: absolute;
    top: 0px;
    right: -5px;
    .icon{
        font-size: $fs-24;
        color: $color-primary;
        background-color: white;
        border-radius: 50%;
    }
  }
}
body[dir='rtl']{
    .avatar--selected  .avatar__icon{
        right: initial;
        left: -5px;
    }
}
