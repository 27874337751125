/* default mood */
/* light mood */
.menu-flags {
  color: red;
  background-color: blue;
}
.text-field {
  position: relative;
  min-width: 60px;
  width: 100%;
  height: 75px;
  &__underline {
    display: flex;
    justify-content: end;
    &__strength-password {
      font-size: $fs-11;
      padding: 0 10px;
      font-weight: 500;
    }
  }
  &.text-field--phone-input {
    width: unset;
    fieldset {
      width: 100%;
    }
  }
  &.text-field--code-input {
    width: unset;
    fieldset {
      padding-left: 16px;
      width: 100%;
      .text-field__input {
        display: none;
      }
    }
  }

  &__counter {
    color: $color-grey-3700;
    padding: 0 16px;
    font-size: $fs-11;
    font-weight: bold;
  }

  ::placeholder {
    color: $color-gray-200;
    font-size: $fs-14;
  }

  fieldset {
    padding-left: 14px;
    border: 1px solid $color-grey-2300 !important;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
  }

  .border:focus,
  .border:focus-within {
    border: 1px solid $color-primary !important;
  }

  .legend-placeholder {
    font-size: $fs-11;
    font-weight: 600;
    text-align: end;
    width: auto;
    padding: 0 3px;
    border-bottom: none;
    color: $color-gray-200;
    float: none;
    text-transform: capitalize;
  }

  &__input {
    width: 100%;
    display: flex;
    text-indent: 3px;
    padding: 9px 9px 14px 0;
    border: none;
    background: transparent;
    font-size: $fs-14;
    color: rgba(0, 0, 0, 0.87);
    -webkit-tap-highlight-color: transparent;
    font-weight: 500;
    max-height: 100%;
    &.no-title {
      padding: 9px 9px 9px 0;
    }
  }

  &__input:focus {
    outline: none;
  }

  .PhoneInput {
    padding: 4px 5px 12px;

    input[type~='tel'] {
      width: 100%;
      background-color: transparent;
      border: none;
      font-size: $fs-14;
      color: rgba(0, 0, 0, 0.87);
    }

    input[type~='tel']:focus-within {
      outline: none;
    }

    .PhoneInputCountryIcon--border {
      box-shadow: 0px 0px 4px 4px hsla(0, 2%, 77%, 0.15);
    }

    .PhoneInputCountrySelect:focus
      + .PhoneInputCountryIcon
      + .PhoneInputCountrySelectArrow {
      opacity: 1;
      color: $color-gray-200;
    }

    .PhoneInputCountrySelectArrow {
      border-style: solid;
      border-color: var(--PhoneInputCountrySelectArrow-color);
      border-top-width: 0;
      border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
      border-left-width: 0;
      border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    }
  }

  .fieldset-error,
  .fieldset-error:focus-within {
    border-color: $color-tomato-100 !important;

    .legend-placeholder {
      color: $color-tomato-100 !important;
    }
    .legend-placeholder {
      color: $color-gray-200;
    }
  }

  .fieldset-focus {
    border: 1px solid $color-primary !important;
  }

  &__end-label {
    padding: 4px 16px 10px 12px;
    font-size: $fs-11;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    text-transform: capitalize;
    &.no-title {
      padding: 4px 16px 4px 12px;
    }
  }

  &__end-button {
    padding: 9px 12px 13px 12px;
    font-size: $fs-14;
    color: $color-primary;
    text-transform: uppercase;
    font-weight: 600;
    background-color: transparent;
    border: none;
  }

  &__end-button:disabled,
  &__end-button:disabled:hover {
    color: rgba(0, 0, 0, 0.3);
    background-color: unset !important;
    border: none;
  }

  &__toggle-password {
    padding: 4px 16px 6px 12px;
    font-size: $fs-24;
    color: $color-grey-3400;
    background-color: transparent;
    border: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: $color-gray-200;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
    color: $color-gray-200;
  }

  &__end-label-top {
    width: 100%;
    font-weight: 600;
    text-align: end;
    color: $color-grey-3700;
    padding: 0 10px;

    small {
      font-size: $fs-10;
    }
  }
}

.text-field--textarea {
  fieldset {
    min-height: 120px;
    padding-bottom: 5px;
  }
}

body ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* dark mood */
.text-field--dark {
  .text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.7) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
  }

  .legend-placeholder {
    color: white !important;
  }

  .text-field__input {
    color: white !important;
    -webkit-text-fill-color: white !important;
  }

  fieldset {
    border: 1px solid white !important;
  }

  .text-field__end-label {
    padding: 8px 16px 9px 12px;
    font-size: $fs-10;
    color: rgba(255, 255, 255, 0.7);
    text-transform: capitalize;
  }
}

body[dir='rtl'] {
  .PhoneInput {
    input[type~='tel'] {
      direction: ltr;
      text-align: end;
    }

    .PhoneInputCountry {
      .PhoneInputCountrySelectArrow {
        margin-right: 5px !important;
      }
    }
  }

  fieldset {
    padding-left: initial;
    padding-right: 14px;
  }

  .text-field__input {
    padding: 9px 0px 12px 9px;
  }

  .text-field .legend-placeholder {
    padding: 0 5px;
  }
}
