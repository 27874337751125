.accountDropdown-item {
  display: flex;
  height: 45px;
  padding: 14px 15px 10px 20px;
  vertical-align: middle;
  justify-content: space-between;
  cursor: pointer;
  &_wrapper {
    font-size: $fs-14;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 6px;
    .icon-profile,
    .icon-login,
    .icon-logout,
    .icon-language {
      font-size: $fs-24;
    }
    &_img {
      margin-right: 10px;
    }
    &_title {
      color: $color-white-true;
      &_active {
        color: $color-black-true;
      }
    }
  }
  &:hover {
    background-color: $color-blue-900;
    color: $color-gray-200;
    border-radius: 10px;
  }
}

.accountDropdown-item_wrapper_title {
  color: $color-black-true;
}

@media only screen and (max-width: 560px) {
  .accountDropdown-item {
    &_wrapper {
      &_title {
        font-size: $fs-12;
      }
    }
  }
}
