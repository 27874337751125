.slack-custom {
  .slick-slide {
    margin-bottom: 25px;
  }
  .slick-dots li{
   margin:0 !important; //overrides slack slider
  }
  .slick-dots li button:before {
    font-size: 10px;
    color: $color-grey-1000;
    opacity: 1;
  }
  .slick-dots li.slick-active button:before {
    color: $color-primary !important;
  }
}

@media only screen and (max-width: 560px) {
  .slack-custom {
    .slick-slide {
      margin-bottom: 20px;
    }
  }
}
