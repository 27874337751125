.language-switcher {
  position: relative;

  &_wrapper {
    display: flex;
    align-items: center;

    &_title {
      align-items: center;
      color: $color-black-true;
      font-size: $fs-14;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        color: $color-jungle-1000;
      }
    }
  }
}
