$search-form-width: 43%;
.search-form {
  width: $search-form-width;
  align-items: center;
  flex-direction: column;
  max-width: 600px;

  .searchBar {
    position: relative;
    width: 100%;
    border-radius: 5px;
    background-color: $color-white-true;
    &.ar {
      input {
        text-align: right;
      }
    }

    input {
      border: none;
      font-size: 12px;
      font-weight: 500;
      height: 48px;
      padding: 12px 20px;
      width: 90%;
    }

    .search-icon,
    .clear-search,
    .loader-icon {
      position: absolute;
      cursor: pointer;
    }

    .search-icon {
      top: 12px;
      right: 23px;
    }

    .clear-search {
      right: 20px;
      top: 8px;
    }

    .loader-icon {
      right: 21px;
      top: 8px;
    }
  }

  .search-results {
    background-color: $color-white-true;
    position: absolute;
    z-index: $z-index-2;
    height: auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    top: $header-height;
    width: min(100%, 600px);

    &__loader-contaner {
      padding: 20px;
    }

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: $color-grey-1000;
      height: 1px !important;
    }

    a {
      color: $color-gray-200;
    }
  }

  .search-results::-webkit-scrollbar {
    display: none;
  }
}

body[dir='rtl'] {
  .search-form {
    .searchBar {
      &.en {
        input {
          text-align: left;
        }
      }
      .search-icon,
      .loader-icon {
        left: 17px;
        right: unset;
      }

      .clear-search {
        left: 16px;
        right: unset;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .search-form .searchBar {
    input {
      height: 40px;
    }

    .search-icon {
      top: 8px;
      right: 11px;
    }

    .clear-search,
    .loader-icon {
      right: 11px;
      top: 4px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .search-form .searchBar {
    position: relative;
    width: 90%;
    border-radius: 5px;
    background-color: #fff;
  }
}

@media only screen and (max-width: 912px) {
  .search-form {
    position: unset;

    .search-results {
      left: 0;
      width: 100%;
      margin-top: 0;
      top: 37px;
    }
  }
}
