.avatar-list {
    padding: 0.25rem 0.5rem 0.25rem 0;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;

    &__img-container {
        display: inline-block;

        img {
            border-radius: 50%;
        }
    }

    &__description-container {
        display: flex;
        margin: 0 0px 0px 10px;
        gap: 2px;
        flex-direction: column;
    }

    &__name {
        font-size: $fs-12;
        font-weight: bold;
        color: $color-black-true;
        text-transform: capitalize;
    }

    &__title {
        font-size: $fs-10;
        color: $color-gray-200;
        font-weight: 600;
    }

    &__description {
        font-size: $fs-14;
        color: $color-gray-100;
    }
}
body[dir='rtl'] {
    .avatar-list {
    padding: 0.25rem 0 0.25rem 0.25rem;
        &__description-container {
            margin: 0 10px 0px 0px;

        }
    }
}