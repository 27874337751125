.seller-reviews {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: $fs-24;
    font-weight: bold;
    line-height: 1.33;
    color: $color-grey-3900;
  }

  p {
    font-size: $fs-12;
    font-weight: 500;
    line-height: 1.5;
    color: $color-grey-3900;

    b {
      font-weight: 700;
    }
  }
}

.total-review-main {
  background-color: $color-jungle-900;
  border-radius: 15px;
  padding: 15px;
  margin: 20px 0;

  .total-review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: $fs-14;
      font-weight: 600;
      line-height: 1.5;
      color: $color-black-true;
    }

    .rating {
      display: flex;
      align-items: center;
      gap: 6px;

      span {
        font-size: $fs-18;
        color: $color-yellow-100;
      }
    }
  }

  .review-details {
    padding: 15px 0 0 15px;

    .review-detail-single {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
      border-bottom: 1px dashed $color-grey-1000;

      &:last-child {
        border-bottom: unset;
        padding-bottom: unset;
      }

      p {
        font-size: $fs-14;
        font-weight: 500;
        color: $color-grey-3900;
        line-height: 1.5;
      }

      h3 {
        font-size: $fs-14;
        font-weight: 600;
        line-height: 1.5;
        color: $color-black-true;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .rating-points-main {
    width: 30%;
  }
  .single-review {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .seller-header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: end;

    .view-other-section {
      display: block;
      font-size: $fs-12;
      font-weight: 600;
      line-height: 2;
      color: $color-primary;
    }
  }
  .seller-reviews {
    margin: 0 20px;
  }
}

@media screen and (max-width: 560px) {
  .seller-reviews {
    padding-bottom: 10px;
    border-bottom: 2px solid $color-grey-1000;

    h2 {
      font-size: $fs-16;
    }
  }
  .mobile-rating-wrapper {
    display: flex;
    gap: 5px;
    align-items: center;

    span {
      color: $color-yellow-100;
    }
  }
}
