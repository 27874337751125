.buy-now {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  .current-price {
    margin-right: 25px;
  }
  &__buttons {
    display: inline-flex;
    font-size: $fs-18;
    color: $color-blue-100;
    flex-wrap: wrap;
    justify-content: end;
    margin-top: 10px;
  }
  &__label {
    font-size: $fs-12;
    color: $color-gray-200;
    margin-bottom: 4px;
  }
  &__price {
    color: $color-secondary;
    font-size: $fs-32;
    line-height: 32px;
    margin-bottom: 9px !important;
  }
  &__currency {
    color: $color-secondary;
    font-size: $fs-16;
    top: 11px;
    margin-right: 1px;
  }
  &__shopping-cart {
    border-radius: 50%;
    font-size: 30px;
    width: 48px;
    height: 48px;
  }
}
body[dir='rtl'] {
  .buy-now {
    padding-top: 20px;
    .current-price {
      margin-right: initial;
      margin-left: 25px;
    }
    &__currency{
      order:2;
      top: 6.5px;
    }
  }
}
