.modal-header {
  border-bottom: none;
  font-weight: 600;
  padding: 10px 10px 0;
  align-items: flex-start;
}
.fade {
  &.modal {
    &.show {
      background-color: $color-grey-4300;
      opacity: 1;
    }
  }
}

.modal-content {
  border-radius: 9px;
  border: none;
}
.modal-body {
  padding: 0 30px 15px;
}
.modal-backdrop {
  z-index: $zindex-overlay-top-level;
  z-index: 600;
}
.modal-title {
  h3 {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: $fs-16;
    color: $color-black-true;
    padding: 20px 20px 0;
  }
}
.modal {
  z-index: $zindex-top-level;
}
@media only screen and (max-width: 560px) {
  .modal-dialog {
    margin: 0 !important;
  }
  .modal-content {
    border-radius: 9px 9px 0 0 !important;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .modal-title {
    font-size: $fs-21;
  }
  .modal-backdrop {
    width: 100% !important;
  }
}
