.post-quantity {
  display: flex;
  align-items: center;

  &__label {
    font-size: $fs-12;
    font-weight: 600;
  }
}

.post-quantity--light {
  .post-quantity__label {
    color: $color-green-1000;
  }
}

.post-quantity--primary,
.post-quantity--secondary {
  .post-quantity__label {
    color: $color-white-true;
  }
}
