.post-card {
  display: flex;
  flex-direction: column;
  border: solid 1px $color-grey-1000;
  overflow: hidden;
  border-radius: 5px;
  transition: 200ms;
  flex: 1;
  cursor: pointer;
}

.post-card:hover {
  box-shadow: 0 4px 8px 0 $color-grey-2000;
}
