.featured-post-title {
  font-size: $fs-24;
  font-weight: bold;
}

@media only screen and (max-width: 560px) {
  .featured-post-title {
    font-size: $fs-16;
  }
}
