.share-button {
  &__icon {
    color: $color-gray-icon;
    font-size: $fs-18;
  }

  &__icon-card {
    color: $color-grey-1300;
    margin: 0;
    font-size: $fs-18;

    &:hover {
      color: $color-primary;
    }
  }
}

body[dir='rtl'] {
  .share-button__icon {
    margin-left: initial;
    margin-right: 5px;
  }
}
