.productLoader-wrapper {
  display: flex;
  align-items: end;
  margin-top: 13px;
  &__content {
    display: flex;
    margin-left: 10px;
    align-items: center;
    flex-direction: column;
  }
}

body[dir="rtl"] {
  .productLoader-wrapper {
    &__content {
      margin-right: 10px;
      margin-left: unset;
    }
  }
}
