.info{
    &__title{
        font-size: $fs-32;
    color: $color-grey-3900;
        font-weight: bold;
            margin-bottom: 30px;
    }
    &__description{
        font-size: $fs-16;
        color: $color-grey-3900;
    font-weight: 500;
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 1280px){
    .info{
       &__title {
            font-size: $fs-24;
        }
    }
}
@media only screen and (max-width: 560px){
    .info{
       &__title {
           text-align: center;
            font-size: $fs-24;
            margin-bottom: 11px;
        }
        &__description{
            text-align: center;
            font-size: $fs-14;
        }
    }
}