.widget-item {
  display: flex;
  align-items: flex-start;

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;

    h4 {
      font-size: $fs-14;
      font-weight: 600;
      margin-left: 11px;
      color: $color-black-true;
      line-height: 1.5;
    }

    p {
      margin-top: 7px;
      margin-left: 14px;
      color: $color-gray-200;
      font-size: $fs-12;
      margin-bottom: 17px;
      font-weight: 500;
    }

  
  }
  img {
    width: 37px;
    height: 37px;
  }
}

@media screen and (max-width: 560px) {
  .widget-item {
    display: block;
    margin: auto;
    text-align: center;

    &__content {
      h4 {
        font-size: $fs-14;
        font-weight: 600;
        margin-top: 10px;
        color: $color-black-true;
      }

      p {
        display: none;
      }
    }
  }
}

body[dir='rtl'] {
  .widget-item {
    &__content {
      h4 {
        margin-left: unset;
        margin-right: 10px;
      }

      p {
        margin-right: 10px;
        margin-left: unset;
      }
    }
  }
}