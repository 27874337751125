.img-radio-group{
    display: flex;
    align-items: center;
    cursor: pointer;
    &__text{
        font-size: 14px;
        font-weight: 600;
        color : $color-grey-3700;
        margin: 0 10px;
    }
    &__text--active{
        color : $color-grey-3800;
    }
}