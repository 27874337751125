.post-details {
  min-height: 100vh;

  .post-info {
    margin: 33px 0;

    &__slider {
      padding: 0;
    }

    &__content {
      padding: 0 20px 20px 20px;
    }

    .title {
      &__label {
        font-size: $fs-21;
        font-weight: 600;
        margin-bottom: 10px;
        text-transform: capitalize;
        color: $color-black-true;
      }

      &__details {
        line-height: 30px;

        figure {
          display: inline-block;
          position: relative;
        }

        &--list {
          display: inline-flex;
          align-items: center;
          padding: 0 6px;
          position: relative;
          font-size: $fs-12;
          font-weight: 600;
          line-height: 16px;

          .mazadat-text {
            font-weight: 700;
          }

          p {
            display: inline-block;
            line-height: 10px;
            color: $color-black-true;
          }

          &__icon {
            font-size: $fs-21 !important;
            color: $color-gray-icon;
            margin-right: 5px;
          }

          &__avatar-container {
            margin-right: 5px;
          }

          &__avatar {
            height: 24px;
            width: 24px;
            border-radius: 50%;
          }

          &__avatar--icon {
            position: absolute;
            top: -9px;
            left: 4px;

            .icon-Verified {
              height: 36px;
              width: 36px;
              color: #36b37e;
            }
          }

          &__name {
            padding: 0;
            text-transform: capitalize;
          }
        }

        &--list:first-child {
          padding-left: 0 !important;
        }
      }
    }
  }

  .expire-and-quantity {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    flex-direction: row;
    gap: 5px;
  }
}

.price {
  padding-top: 20px;
  font-weight: bold;
  display: flex;
  margin: 0 auto;

  .post-details-price {
    font-size: $fs-24;
    color: $color-blue-100;
  }

  .post-details-currency {
    color: $color-blue-100;
    font-size: $fs-14;
    top: 9px;
    font-weight: bold;
    margin: 0 4px 0 0;
  }

  &__wrapper {
    margin-right: 9px;
  }
}

.brand-container {
  width: 100%;
}

.brand-name {
  font-size: $fs-12;
  width: 100%;

  &__list {
    display: flex;
    width: 50%;
    padding: 5px 0 5px 12px;

    &__key {
      padding: 5px 0 5px 10px;
      color: $color-grey-3900;
      text-transform: capitalize;
      font-weight: 500;
      font-size: $fs-12;
    }

    &__value {
      padding: 5px 0 5px 10px;
      color: $color-black-true;
      font-weight: 600;
      font-size: $fs-12;
    }
  }

  &__list-mobile {
    display: flex;
    padding: 5px 10px 4px 10px;
    margin-bottom: 1px;
    width: 100%;
    font-size: $fs-12;

    &__key {
      color: $color-gray-100;
      text-transform: capitalize;
      font-weight: 500;
      white-space: nowrap;
    }

    &__value {
      color: $color-black-true;
      font-weight: 600;
    }
  }

  &__list-mobile:nth-child(odd) {
    background-color: $color-jungle-900;
  }
}

.brand-name:nth-child(even) {
  background-color: $color-jungle-900;
}

.purchase {
  position: relative;
  z-index: 108;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: $fs-12;
    color: $color-grey-3400;
    font-weight: 500;
  }

  &__icons {
    display: flex;
    gap: 20px;
  }

  .report-abuse-container {
    display: flex;
    justify-content: end;
    margin-top: 21px;
  }
}

.price-mobile {
  line-height: 20px !important;

  sup {
    font-weight: bold;
    font-size: $fs-12;
    margin-left: 2px;
    top: -4px;
  }
}

body[dir='rtl'] {
  .title__details {
    &--list:first-child {
      padding-right: 0;
      padding-left: initial;
    }

    &--list {
      padding-right: initial;
      padding-left: 6px;

      &__icon {
        margin-right: initial;
        margin-left: 5px;
      }

      &__avatar-container {
        margin-right: initial;
        margin-left: 5px;
      }
    }

    &--list__avatar--icon {
      .icon-Verified {
        right: initial;
        left: -3px;
      }
    }
  }

  .brand-name {
    &__list {
      padding: 5px 12px 5px 0px;

      &__key {
        padding: 5px 12px 5px 0;
      }

      &__value {
        padding: 5px 12px 5px 0;
      }
    }
  }

  .price {
    &__wrapper {
      margin-right: initial;
      margin-left: 9px;
    }
    .post-details-currency{
      order:2;
      top:6px;
    }
  }
}

.post-details .image-gallery-slides {
  background: $color-black-true;
  border-radius: 10px;
}

.post-details .fullscreen .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 120px) !important;
  padding: 10px 0 30px;
}

.post-details .fullscreen .image-gallery-slide img {
  height: 100% !important;
  aspect-ratio: auto !important;
}

.post-details .image-gallery-slide {
  border: none;
}

.post-details .image-gallery-thumbnails {
  padding: 15px 0px !important;
  background: #fff;
}

.post-details .image-gallery-content.fullscreen {
  background: none;
}

.post-details .fullscreen-modal svg.image-gallery-svg {
  background-color: transparent !important;
  border: solid 2px rgba(255, 255, 255, 0.4) !important;
}

.post-details
.fullscreen-modal
.image-gallery-left-nav
> .image-gallery-svg
polyline,
.post-details
.fullscreen-modal
.image-gallery-right-nav
> .image-gallery-svg
polyline {
  color: rgba(255, 255, 255, 0.4) !important;
}

.post-details .fullscreen-modal .image-gallery-left-nav {
  left: 258px;
}

.post-details .fullscreen-modal .image-gallery-right-nav {
  right: 258px;
}

.post-details nav.image-gallery-thumbnails-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.post-details .fullscreen-modal .image-gallery-bullets {
  display: none;
}

.post-details-full-screen {
  position: relative;
  z-index: 9999;
}

.post-details .image-gallery-swipe {
  overflow: hidden;
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.post-details .fullscreen-modal .image-gallery-swipe {
  overflow: hidden;
  border-radius: unset;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.post-details button.image-gallery-icon.image-gallery-fullscreen-button svg {
  display: none;
}

.post-details button.image-gallery-icon.image-gallery-fullscreen-button {
  background: url('../../../../assets/icons/zoom-icon.svg'), rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  cursor: pointer;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  background-position: center;
  margin-bottom: 12px;
  margin-right: 12px;
}

.post-details button.image-gallery-icon.image-gallery-fullscreen-button:hover {
  background-color: rgba(0, 0, 0, 0.87);
}

.post-details
.fullscreen-modal
button.image-gallery-icon.image-gallery-fullscreen-button {
  background: url('../../../../assets/icons/maximize-icon.svg');
  border-radius: 50%;
  cursor: pointer;
  background-repeat: no-repeat;
  height: 44px;
  width: 44px;
  background-position: center;
  margin-bottom: 20px;
  margin-right: 20px;
  background-size: cover;
}

.post-details .image-gallery-icon {
  filter: unset;
}

.post-details .fullscreen-modal .image-gallery .image-gallery-thumbnail-image {
  object-fit: contain;
  border-radius: 10px;
}

.post-details .fullscreen-modal .image-gallery-thumbnail.active,
.fullscreen-modal .image-gallery-thumbnail:focus,
.fullscreen-modal .image-gallery-thumbnail:hover {
  outline: none;
  border: 2px solid #00aeb6;
  border-radius: 10px;
}

.post-details .fullscreen-modal .image-gallery-thumbnail-image {
  object-fit: contain;
  border-radius: 10px;
}

.post-details .fullscreen-modal .image-gallery-thumbnail {
  border-radius: 10px;
}

.post-details .image-gallery-left-nav > .image-gallery-svg polyline,
.post-details .image-gallery-right-nav > .image-gallery-svg polyline {
  color: #fff;
}

.post-details .image-gallery-left-nav > .image-gallery-svg,
.post-details .image-gallery-right-nav > .image-gallery-svg {
  height: 48px !important;
  width: 48px !important;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  transition: 0.3s;
  color: #fff;
}

.post-details .image-gallery-left-nav > .image-gallery-svg:hover,
.post-details .image-gallery-right-nav > .image-gallery-svg:hover {
  background-color: rgba(0, 0, 0, 0.87);
}

.post-details .image-gallery-left-nav > .image-gallery-svg polyline,
.post-details .image-gallery-right-nav > .image-gallery-svg polyline {
  color: #fff !important;
}

.post-details .image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(1.2);
  border: 1px solid #fff;
  background: #00aeb6 !important;
}

.post-details .image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  z-index: 4;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
  border-radius: 4px;
  max-width: fit-content;
}

.post-details .image-gallery-bullets .image-gallery-bullet {
  background: #fff;
}

.post-details .image-gallery {
  width: 100%;
}

.post-details-full-screen .image-gallery {
  width: unset;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 109;
}

.post-details .image-gallery-bullets .image-gallery-bullets-container {
  margin: 0 0 2px;
  padding: 0;
  text-align: center;
}

@media only screen and (max-width: 1730px) {
  .post-details .fullscreen-modal .image-gallery-left-nav {
    left: 180px;
  }
  .post-details .fullscreen-modal .image-gallery-right-nav {
    right: 180px;
  }
}

@media only screen and (max-width: 1600px) {
  .post-details .fullscreen-modal .image-gallery-left-nav {
    left: 120px;
  }
  .post-details .fullscreen-modal .image-gallery-right-nav {
    right: 120px;
  }

  .post-details-full-screen .image-gallery {
    width: unset;
  }
}

@media only screen and (max-width: 1450px) {
  .post-details .fullscreen-modal .image-gallery-left-nav {
    left: 80px;
  }
  .post-details .fullscreen-modal .image-gallery-right-nav {
    right: 80px;
  }

  .post-details .image-gallery {
    width: 410px;
  }

  .post-details-full-screen .image-gallery {
    width: unset;
  }
}

@media only screen and (max-width: 1200px) {
  .post-details .image-gallery {
    width: 300px;
  }

  .post-details-full-screen .image-gallery {
    width: unset;
  }

  .post-details
  .fullscreen-modal
  button.image-gallery-icon.image-gallery-fullscreen-button {
    margin-bottom: 36px;
    background-color: $color-grey-3400;
    border-radius: 15px;
    padding: 22px;
    margin-right: 39px;
    background-size: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .post-details-full-screen .image-gallery {
    width: unset;
  }
  .post-details .image-gallery {
    width: 100%;
  }
}

@media only screen and (max-width: 912px) {
  .price {
    padding: 14px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .purchase .report-abuse-container {
    margin-top: 0;
    padding: 0 5.5%;
  }

  .purchase__title {
    font-size: 12px;
    font-weight: 500;
  }

  .post-details .title__content {
    margin-right: 20px;
    margin-left: 20px;
  }

  .post-info {
    margin: 0 !important;
  }

  .brand-name__list-mobile__value {
    padding-left: 5px;
  }

  body[dir='rtl'] {
    .brand-name__list-mobile__value {
      padding-left: initial;
      padding-right: 5px;
    }
  }

  .price {
    .post-details-currency {
      font-size: $fs-13;
    }
  }

  .post-details .image-gallery-thumbnails {
    padding: 15px 0px !important;
    background: unset;
    display: none;
  }

  .post-details .fullscreen-modal .image-gallery-thumbnails {
    padding: 15px 0px !important;
    background: unset;
    display: block;
  }

  .post-details .image-gallery.fullscreen-modal .image-gallery-content {
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px 0px 15px;
    position: relative;
  }

  .post-details nav.image-gallery-thumbnails-container {
    display: flex;
    justify-content: left;
    padding: 0 0 0 30px;
    grid-gap: 10px;
    gap: 10px;
  }

  .post-details .fullscreen-modal svg.image-gallery-svg {
    display: none;
  }

  .post-details
  .fullscreen-modal
  button.image-gallery-icon.image-gallery-fullscreen-button {
    background: url('../../../../assets/icons/close-icon-white.svg'), #fff;
    background-color: rgba(240, 116, 116, 0.1);
    border-radius: 50%;
    position: absolute;
    top: -15px;
    right: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    background-position: center;
  }

  .post-details .fullscreen-modal .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 120px) !important;
    padding: 50px 0 0 0;
  }

  .post-details .image-gallery {
    width: 100%;
  }

  .post-details-full-screen .image-gallery {
    width: unset;
  }

  .post-details .sc-cmEail.fyqsHb {
    margin-top: unset;
  }

  .post-details .post-label {
    margin-top: unset;
  }

  .post-details .image-gallery-bullets .image-gallery-bullet {
    margin: 4px 5px 2px;
  }

  .post-details .image-gallery-slides {
    border-radius: 0;
  }

  .post-details button.image-gallery-icon.image-gallery-fullscreen-button {
    margin-bottom: 7px;
    margin-right: 7px;
  }

  .post-details .fullscreen-modal .image-gallery-left-nav {
    display: none;
  }
  .post-details .fullscreen-modal .image-gallery-right-nav {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .brand-container {
    padding-top: 20px;
  }
  .post-details .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
  }
  .post-details .image-gallery-swipe {
    border-radius: 0;
    border: none;
  }
  .post-details .fullscreen-modal .image-gallery-swipe {
    border: none;
  }
}

@media only screen and (max-width: 540px) {
  .post-details {
    .post-info {
      &__content {
        padding: 20px 0 0 0;
      }

      .title__label {
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .brand-container {
    padding-top: 30px;
    border-top: 1px solid $color-grey-2300;
  }

  .purchase__title {
    padding: 20px 0px 20px 0;
    margin: 0;
    background-color: transparent;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1377px) {
  .expire-and-quantity {
    flex-wrap: wrap;
  }
}
