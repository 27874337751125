.toggle-button {
  position: relative;
  border: 1px solid transparent;
  border-radius: 15px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  gap: 8px;
  font-size: $fs-14;
  color: $color-white-true;
  background-color: $color-primary;
  font-weight: 600;
  transition: 200ms;
  white-space: nowrap;

  &:focus {
    outline: 0;
  }
}

.toggle-button--inactive,
.toggle-button--inactive:hover,
button.toggle-button:disabled,
button.toggle-button[disabled],
button.toggle-button:disabled:hover,
button.toggle-button[disabled]:hover {
  background-color: $color-grey-1600;
  color: $color-grey-4000;
  opacity: 1;

  &:hover {
    color: $color-gray-200;
  }

  p,
  small,
  sup,
  span {
    color: $color-grey-4000 !important;

    &:hover {
      color: $color-gray-200 !important;
    }
  }
}

// size
.toggle-button--medium {
  padding: 16px 20px;
}

.toggle-button--small {
  padding: 8px 10px;
  font-weight: 600;
  font-size: $fs-12;
  border-radius: 5px;
}

.toggle-button--fullWidth {
  display: block;
  width: 100%;
}

// Palette
.toggle-button--primary {
  background-color: $color-primary;
}

.toggle-button--primary-bordered {
  background-color: $color-white-true;
  border: 1px solid $color-primary;
  color: $color-primary;
}

.toggle-button--primary-bordered:hover {
  background-color: $color-blue-900;
}

.toggle-button--secondary {
  background-color: $color-secondary;
}

.toggle-button--primary-medium {
  background-color: $color-primary-medium;
  color: $color-jungle-100;
}

.toggle-button--primary-medium:hover {
  background-color: $color-jungle-400;
}

.toggle-button--secondary-medium {
  background-color: $color-secondary-medium;
}

.toggle-button--main {
  background-color: $color-main;
  color: $color-black-true;
}

.toggle-button--main:hover {
  background-color: $color-grey-2900;
}

.toggle-button--support-primary {
  background-color: $color-support-primary;
}

.button--support-secondary {
  background-color: $color-support-secondary;
}

.toggle-button--light {
  background-color: $color-white-true;
  color: $color-primary;
}
