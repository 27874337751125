.post-card-info-horizontal {
  flex: 1;

  &__title {
    padding: 10px 6px;
    border-bottom: 1px solid $color-grey-1000;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .post-title {
      margin-bottom: 5px;

      h3 {
        font-size: $fs-13;
        line-height: 16px;
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;

      .post-quantity__label,
      .post-bids__label {
        font-size: $fs-12;
      }

      &__quantityAndBids {
        display: flex;

        div:not(:last-child)::after {
          content: '|';
          margin: 0 6px 0px;
          font-size: $fs-8;
          font-weight: 700;
          color: $color-grey-2300;
        }
      }
    }

    &__post-title h3 {
      font-size: $fs-13;
    }

    .share-button:hover span,
    .share-button:focus span {
      color: $color-primary;
    }
  }

  &__price {
    &__buyNow {
      border-bottom: 1px solid $color-grey-1000;
    }

    &__buyNow,
    &__bidNow {
      padding: 2.5px 6px;
      height: 22px;
    }

    &__label {
      font-size: $fs-12;
    }

    &__price {
      font-size: $fs-13;
    }

    &__currency {
      font-size: $fs-10;
      top: 6px;
      margin: 0 2px;
    }
  }
}

body[dir='rtl'] {
  .post-card-info-horizontal__title {
    padding: 5px 10px;

    .post-quantity {
      margin-left: 0;
    }

    &__info__quantityAndBids div:not(:last-child)::after {
      margin: 3px 6px 0px;
    }
  }
  .post-card-info-horizontal__price__currency{
    order:2;
    top: 3.5px;
  }
}
