@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?8tbr3b');
  src:  url('./icomoon.eot?8tbr3b#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?8tbr3b') format('truetype'),
    url('./icomoon.woff?8tbr3b') format('woff'),
    url('./icomoon.svg?8tbr3b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'], [class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-outline_arrow_down:before {
  content: '\e900';
}
.icon-outline_arrow_up:before {
  content: '\e901';
}
.icon-cancel_circle:before {
  content: '\e934';
}
.icon-circled_down_arrow:before {
  content: '\e94c';
}
.icon-circled_left_arrow:before {
  content: '\e94d';
}
.icon-circled_right_arrow:before {
  content: '\e94e';
}
.icon-circled_up_arrow:before {
  content: '\e94f';
}
.icon-crop:before {
  content: '\e950';
}
.icon-delete:before {
  content: '\e951';
}
.icon-flash:before {
  content: '\e952';
}
.icon-gallery:before {
  content: '\e953';
}
.icon-language:before {
  content: '\e954';
}
.icon-locate_me:before {
  content: '\e955';
}
.icon-login:before {
  content: '\e956';
}
.icon-logout:before {
  content: '\e957';
}
.icon-no_flash:before {
  content: '\e958';
}
.icon-question_mark:before {
  content: '\e959';
}
.icon-Redeemed:before {
  content: '\e95a';
}
.icon-rotate:before {
  content: '\e95b';
}
.icon-seller:before {
  content: '\e95c';
}
.icon-switch:before {
  content: '\e95d';
}
.icon-dots:before {
  content: '\e902';
}
.icon-abuse:before {
  content: '\e903';
}
.icon-filter:before {
  content: '\e949';
}
.icon-more_details:before {
  content: '\e942';
}
.icon-enlarge:before {
  content: '\e943';
}
.icon-reviews:before {
  content: '\e944';
}
.icon-active_posts:before {
  content: '\e945';
}
.icon-location_map:before {
  content: '\e946';
}
.icon-working_hours:before {
  content: '\e947';
}
.icon-edit_image:before {
  content: '\e948';
}
.icon-share:before {
  content: '\e91b';
}
.icon-seller_buyer:before {
  content: '\e941';
}
.icon-truck:before {
  content: '\e93f';
}
.icon-alert:before {
  content: '\e93e';
}
.icon-service_point:before {
  content: '\e940';
}
.icon-map_address:before {
  content: '\e93c';
}
.icon-call:before {
  content: '\e93d';
}
.icon-cart_bar:before {
  content: '\e938';
}
.icon-bidding_bar:before {
  content: '\e939';
}
.icon-categories_bar:before {
  content: '\e93a';
}
.icon-home_bar:before {
  content: '\e93b';
}
.icon-bold_arrow_left:before {
  content: '\e935';
}
.icon-sort:before {
  content: '\e936';
}
.icon-bold_arrow_right:before {
  content: '\e937';
}
.icon-category:before {
  content: '\e933';
}
.icon-minus_cirlce_filled:before {
  content: '\e930';
}
.icon-add_cirlce_filled:before {
  content: '\e931';
}
.icon-close_cirlce_filled:before {
  content: '\e932';
}
.icon-arrow_left_outline:before {
  content: '\e92e';
}
.icon-arrow_right_outline:before {
  content: '\e92f';
}
.icon-star_empty:before {
  content: '\e92b';
}
.icon-star_filled:before {
  content: '\e94a';
}
.icon-star_half:before {
  content: '\e94b';
}
.icon-hide_eye:before {
  content: '\e92d';
}
.icon-bid:before {
  content: '\e92c';
}
.icon-item_secured:before {
  content: '\e921';
}
.icon-item_condition:before {
  content: '\e922';
}
.icon-item_viewed:before {
  content: '\e923';
}
.icon-item_location:before {
  content: '\e924';
}
.icon-search:before {
  content: '\e925';
}
.icon-item_pickup:before {
  content: '\e926';
}
.icon-item_delivery:before {
  content: '\e927';
}
.icon-item_status:before {
  content: '\e928';
}
.icon-Timer:before {
  content: '\e904';
}
.icon-Verified:before {
  content: '\e905';
}
.icon-Sent:before {
  content: '\e906';
}
.icon-Bid:before {
  content: '\e907';
}
.icon-manual:before {
  content: '\e908';
}
.icon-sold_out:before {
  content: '\e929';
}
.icon-mins_circle:before {
  content: '\e92a';
}
.icon-add_circle:before {
  content: '\e909';
}
.icon-mark:before {
  content: '\e90a';
}
.icon-mark_ar:before {
  content: '\e960';
}
.icon-rotate1:before {
  content: '\e90b';
}
.icon-attach:before {
  content: '\e90c';
}
.icon-notification:before {
  content: '\e90d';
}
.icon-add-square:before {
  content: '\e90e';
}
.icon-mins-square:before {
  content: '\e90f';
}
.icon-house:before {
  content: '\e910';
}
.icon-heart-filled:before {
  content: '\e911';
}
.icon-profile:before {
  content: '\e912';
}
.icon-arrow-right:before {
  content: '\e913';
}
.icon-arrow-bottom:before {
  content: '\e914';
}
.icon-bag:before {
  content: '\e915';
}
.icon-heart:before {
  content: '\e916';
}
.icon-arrow-top:before {
  content: '\e917';
}
.icon-language1:before {
  content: '\e918';
}
.icon-eye:before {
  content: '\e919';
}
.icon-dashboard:before {
  content: '\e91a';
}
.icon-postsimilar:before {
  content: '\e91c';
}
.icon-secure:before {
  content: '\e91d';
}
.icon-briefcase:before {
  content: '\e91e';
}
.icon-arrow-left:before {
  content: '\e91f';
}
.icon-arrow-down:before {
  content: '\e920';
}
.icon-add:before {
  content: '\e95e';
}
.icon-cancel:before {
  content: '\e95f';
}
.icon-play4:before {
  content: '\ed33';
}
.icon-google-plus:before {
  content: '\f0b1';
}
.icon-facebook:before {
  content: '\f0b6';
}
.icon-instagram:before {
  content: '\f0b8';
}
.icon-whatsapp:before {
  content: '\f0b9';
}
.icon-twitter:before {
  content: '\f0bc';
}
.icon-youtube:before {
  content: '\f0c3';
}
.icon-linkedin:before {
  content: '\f0f0';
}
