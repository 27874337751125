.footer-mobile {
  padding-bottom: 85px;
  &__download-apps {
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid $color-grey-2300;
    border-top: 1px solid $color-grey-2300;
    &__title h3 {
      font-size: $fs-16;
      font-weight: bold;
      color: $color-black-true;
      margin-bottom: 12px;
    }
    &__buttons img {
      width: 104px;
      height: 34px;
    }
    &__buttons a:first-child {
      margin-right: 3px;
    }
    &__buttons a:nth-child(2) {
      margin-left: 3px;
    }
  }
  &__payment {
    text-align: center;
    padding: 30px 0 35px;
    font-size: $fs-12;
    &__logo {
      margin-bottom: 20px;
      img {
        width: 133.8px;
        height: 30px;
        aspect-ratio: 67/15;
      }
    }
    &__types {
      margin-bottom: 10px;
      &__icons {
        display: flex;
        justify-content: center;
        .visa-icon img {
          width: 41.7px;
          height: 13.5px;
        }
        .mastercard-icon img {
          width: 27.5px;
          height: 17px;
        }
        .fawry-icon img {
          width: 47.4px;
          height: 15.1px;
        }
        .valu-icon img {
          width: 44px;
          height: 14px;
        }
        .payment-icon {
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
    &__text p {
      color: $color-black-true;
      font-size: 12px;
      font-weight: 500;
    }
  }
  &__info {
    padding: 30px 20px 0;
    &__section {
      margin-bottom: 30px;
    }
    &__collapsed {
      display: flex;
      flex-direction: column;
      a,
      span {
        color: $color-gray-200;
        font-size: $fs-14;
        font-weight: 500;
        margin-bottom: 10px;
      }
      a:hover,
      span:hover {
        color: $color-blue-200;
      }
    }
    .socialMedia {
      .socialMedia-icon {
        margin-right: 15px;
        margin-bottom: initial;
      }
    }
  }
}
body[dir='rtl'] {
  .footer-mobile__download-apps__buttons a:first-child {
    margin-left: 3px;
    margin-right: initial;
  }
  .footer-mobile__download-apps__buttons a:nth-child(2) {
    margin-left: initial;
    margin-right: 3px;
  }
  .footer-mobile__info__collapsed .socialMedia {
    a {
      margin-right: initial;
      margin-left: 10px;
    }
  }
}
