.seller-active-posts {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 0;
}

@media screen and (max-width: 1400px) {
  .seller-active-posts {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1024px) {
  .seller-active-posts {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 768px) {
  .seller-active-posts {
    grid-template-columns: 1fr 1fr;
  }
  .active-posts-mobile-wrapper {
    border-top: 5px solid $color-grey-1600;
    margin-top: 40px;
    padding-top: 40px;
    .scrollable-section__wrapper {
      gap: unset;
      margin-top: 20px;
    }
  }
}
@media screen and (max-width: 690px) {
  .seller-active-posts {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 560px) {
  .active-post-list-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  .active-posts-mobile-wrapper {
    margin-top: 20px;
    padding-top: unset;
    border-top: none;
  }
  .active-border {
    border-top: 5px solid $color-grey-1600;
    margin-top: 40px;
    padding-top: 40px;
  }
}

@media screen and (max-width: 350px) {
  .active-post-list-mobile {
    .post-card-horizontal {
      width: 100%;
    }
  }
}
