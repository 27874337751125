.productsWidget-wrapper {
  margin-top: 15.5px;
  margin-bottom: 10px;

  .viewAllBtn {
    text-decoration: underline;
    color: $color-primary;
    font-size: 10px;
    float: right;
    padding-top: 1.5rem;
    font-weight: 500;
  }
}

.widget-title {
  border-bottom: 2px solid $color-grey-1000;

  &__text {
    margin-bottom: 10px;
    color: $color-black-true;
    justify-content: flex-start;
    display: flex;

    h3 {
      font-size: $fs-14;
      font-weight: 600;
      line-height: 1.5;
    }
  }

  h5 {
    padding-top: 1rem;
    font-size: 1rem;
    color: $color-blue-100;
    font-weight: 700;

    span {
      color: $color-jungle-100;
    }
  }
}
.widget-showMore p {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}
body[dir='rtl'] {
  .productsWidget-wrapper {
    .viewAllBtn {
      text-decoration: underline;
      color: $color-primary;
      font-size: 10px;
      float: left;
      padding-top: 1.5rem;
      font-weight: 500;
    }
  }
}
