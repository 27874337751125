.categories-menu {
  display: flex;
  gap: 5px;
  align-items: center;
  &__icon{
    color: $color-primary;
    font-size: 22px;
  }
  img {
    width: 24px;
    &:hover {
      cursor: pointer;
    }
  }
  &__text {
    font-size: $fs-14;
    font-weight: 600;
  }
}
.burgerMenu-wrapper {
  min-height: 50%;
  position: absolute;
  top: 0;
  display: flex;
  z-index: $z-index-2;
  top: 31px;
  left: 28px;
}
.burgerMenu-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $zindex-overlay-header-category;
  cursor: pointer;
}

body[dir="rtl"] {
  margin: 0;
  padding: 0;
  .categories-menu {
    margin-left: 0;
  }
}
