.button-search {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  padding: unset;
}
.button-search--medium {
  .button-search__icon {
    font-size: 24px;
  }
}
.button-search--small {
  .button-search__icon {
    font-size: 21px;
  }
}
.button-search--light {
  color: $color-grey-3000;
}
.button-search--light:focus {
  color: $color-primary;
}
.button-search--dark {
  color: $color-white-true;
}
.button-search--dark:focus {
  color: $color-primary;
}
