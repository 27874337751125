.card-image-overlay {
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 10px 15px;
  }

  &__main-info {
    display: flex;
    justify-content: space-evenly;
  }
}

.card-image-overlay--light {
  background-color: $color-black-true;
}

.card-image-overlay--primary {
  background-color: $color-primary;
}

.card-image-overlay--secondary {
  background-color: $color-blue-500;
}
