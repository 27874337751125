.quantity {
  display: flex;
  align-items: flex-start;
  padding: 21px 12px 21px 12px;
  width: 50%;
  justify-content: space-between;
  .quantity-progress-bar {
    max-width: 200px;
  }
  &__title {
    font-size: $fs-13;
    font-weight: 600;
    color: $color-black-true;
    text-align: left;

    p {
      width: max-content;
    }
  }
}

.quantity-zero-state {
  width: 321px;
  background-color: $color-tomato-700;
  color: $color-tomato-100;
  font-weight: 600;
  padding: 20px 0;
  border-radius: 10px;
  flex: 1 0;
  display: flex;
  align-items: center;

  .icon-sold_out {
    font-size: $fs-24;
    margin: 0 10px;
  }

  small {
    font-size: $fs-14;
    line-height: normal;
    font-weight: 600;
  }
}

body[dir='rtl'] {
  .quantity {
    &__title {
      text-align: right;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1377px) {
  .quantity {
    width: 100%;
  }
}
