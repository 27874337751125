.description {
  margin-bottom: 0;

  &__content {
    font-size: $fs-14;
    font-weight: 500;
    line-height: 1.5;
    color: $color-grey-3900;
    margin: 15px 0 0;
    overflow-wrap: break-word;
  }
}

@media only screen and (max-width: 560px) {
  .description {
    margin-bottom: 30px;
  }
}