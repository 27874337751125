.noData-container {
    margin: 15px 0 0;
    padding: 18px 0px;
    border-radius: 10px;
    border: solid 1px $color-grey-1000;

    &__icon {
        margin-right: 10px;
    }

    &__label {
        font-weight: 600;
        color: $color-black-true;
        line-height: normal;
        font-size: $fs-14;
    }
}

body[dir='rtl'] {
    .noData-container {
        &__icon {
            margin-right: initial;
            margin-left: 10px;
        }
    }
}

@media only screen and (max-width: 425px) {
    .noData-container {
        &__label {
            font-size: $fs-10;
        }
    }
}