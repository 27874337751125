.post-icon {
  border-radius: 5px;
  text-align: center;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1.5px 3px 0 $color-grey-1000;
  .icon-secure {
    color: $color-white-true;
    font-size: 18px;
  }
}
.post-icon-secure{
  background-color: $color-primary;
  margin-right: 5px;
}

body[dir='rtl'] {
  .post-icon-secure{
    margin-left: 5px;
    margin-right: 0px;
  }
}