.customerService-wrapper {
  display: flex;
  flex-direction: column;
  a {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
    color: $color-gray-200;
  }
  a:hover {
    color: $color-blue-200;
    transition:200ms;
  }
  h5 {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
  }
}
