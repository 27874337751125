.post-details {
  &__content {
    display: flex;
  }

  &__images {
    width: 31.25vw;

    .sticky-top {
      z-index: $z-index-1;
    }
  }

  &__center {
    width: 32.344vw;
    margin: 0 1.615vw 0 1.458vw;
  }

  &__side {
    margin-top: 33px;
    width: 13.333vw;
  }

  &__view {
    display: flex;
    align-items: center;

    &__icon {
      margin: 0 5px;
      font-size: $fs-18;
      color: $color-gray-icon;
    }

    &__label {
      font-size: $fs-12;
      display: inline-block;
    }
  }

  .share-mobile {
    font-size: 21px;
  }

  .discount-small-view__value__old-price {
    display: block;
  }

  .add-to-watch-list-mobile {
    border: none;
    min-height: unset;
    background-color: unset;
    margin: 0 7.5% 0 0;

    span {
      font-size: 21px;
    }
  }

  body[dir='rtl'] {
    margin: 0 0 0 7.5%;
  }

  &__more-button {
    font-size: 24px;
    color: $color-grey-2500;
    height: 24px;
    width: 24px;
    margin-left: 10px;
  }
}

.image-gallery-bullet {
  border: none !important;

  &:hover {
    background: $color-primary !important;
  }
}

.product-description {
  margin-top: 30px;
  &--no-desc {
    margin: 15px 0 0;
    padding: 18px 192px;
    border-radius: 10px;
    border: solid 1px $color-grey-1000;
  }
}

@media only screen and (max-width: 1700px) {
  .post-details {
    &__images {
      width: 32%;
    }

    &__center {
      width: 46%;
      margin: 0 1.615vw 0 1.458vw;
    }

    &__side {
      width: 22%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .post-details {
    &__content {
      display: flex;
      flex-wrap: wrap;
    }

    &__images {
      width: 100%;
    }

    &__center {
      width: 65%;
    }

    &__side {
      width: 30%;
    }
  }
}

@media only screen and (max-width: 425px) {
  .post-details {
    &__side {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 912px) {
  .post-details {
    &__content {
      display: flex;
      flex-direction: column;
    }

    &__images,
    &__center,
    &__side {
      width: 100%;
    }

    &__images .sticky-top {
      z-index: initial;
    }

    &__center {
      margin: 0;
      padding: 20px 0 30px;
    }

    &__side {
      padding: 0 20px 20px 20px;
      margin: 0;
    }
  }
  body[dir='rtl'] {
    .post-details .post-details__more-button {
      margin-left: initial;
      margin-right: 10px;
    }
  }
}
