.search-results-head {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 10px;
  padding: 20px 20px 10px;

  .search-results-title {
    color: $color-black-true;
    font-size: $fs-12;
    font-weight: 600;
  }

  .search-results-viewProducts {
    text-decoration: underline;
    text-underline-offset: 2px;
    color: $color-primary;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
  }
}

.search-results-items {
  overflow: auto;
  overflow-x: hidden;
}

.search-results-items-spacing {
  padding: 10px 20px;
}

.search-item {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  &.ar {
    text-align: right;
  }

  span {
    font-weight: bold;
  }
  &:hover {
    background-color: $color-blue-900;
  }
}
