.offcanvas {
  z-index: $zindex-top-level;
  .offcanvas-header {
    flex-wrap: wrap;
    padding: 13px 20px 20px 20px;
    .offcanvas-title {
      order: 1;
      font-size: $fs-21;
      font-weight: bold;
      color: $color-black-true;
    }
    .btn-close {
      order: 2;
      margin-right: 2px;
    }
  }
  .offcanvas-body {
    padding: 0 10px;
    margin: 0 10px;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: $color-grey-1000;
    height: 1px !important;
  }
}
.offcanvas-backdrop {
  z-index: $zindex-overlay-top-level;
}
// ************
.bidder-list {
  border: 1px solid $color-grey-1000;
  border-radius: 16px;
  margin-bottom: 15px;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  &__user {
    display: flex;
    align-items: center;
    &__info {
      margin-left: 20px;
      width: 100%;
      &__group {
        display: flex;
        justify-content: space-between;
      }
      &__title {
        font-size: $fs-14;
        font-weight: 600;
        text-transform: capitalize;
        padding-bottom: 5px;
        color: $color-black-true;
      }
      &__subTitle {
        font-size: $fs-12;
        color: $color-grey-3400;
        text-transform: capitalize;
        font-weight: 600;
      }
    }
  }
  .avatar {
    width: 42px;
    position: relative;
    img {
      border-radius: 50%;
    }
    &__icon {
      position: absolute;
      top: -7px;
      right: -10px;
      border: 2px solid $color-white-true;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 15px;
      }
    }
  }
  &__date {
    font-size: $fs-10;
    color: $color-gray-200;
    font-weight: 600;
    background-color: $color-jungle-900;
    padding: 4px 6px;
    border-radius: 6px;
    p {
      line-height: 16px;
    }
  }
}

.total-bid {
  display: flex;
  justify-content: space-between;
  order: 3;
  width: 100%;
  margin-top: 20px;
  flex-grow: 1;
  gap: 6px;
}

.total-bids-list {
  text-align: center;
  flex: 1;
  &__total {
    background-color: $color-grey-4400;
    border-radius: 12px 12px 0 0;
    font-size: $fs-24;
    font-weight: bold;
    padding: 12px 10px;
    line-height: 1;
  }
  &__label {
    font-size: $fs-14;
    text-transform: capitalize;
    color: $color-black-true;
    font-weight: 600;
    padding: 10px;
    border-radius: 0 0 12px 12px;
    line-height: 1.71;
    white-space: nowrap;
    p {
      line-height: 12px;
    }
  }
  &.primary {
    .total-bids-list__total {
      color: $color-primary;
    }
    .total-bids-list__label {
      color: white;
      background-color: $color-primary;
    }
  }
  &.purple {
    .total-bids-list__total {
      color: $color-violet-500;
    }
    .total-bids-list__label {
      color: white;
      background-color: $color-violet-500;
    }
  }
  &.secondary {
    .total-bids-list__total {
      color: $color-secondary;
    }
    .total-bids-list__label {
      color: white;
      background-color: $color-secondary;
    }
  }
  &.emptyState {
    .total-bids-list__total,
    .total-bids-list__label {
      color : $color-grey-4000;
      background-color: $color-grey-1000;
    }
  }
}

.bidding-history-zero-state {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50%;
  .gray-box{
    width:33%;
    background-color: $color-grey-1000;
    height: 48px;
  }
  &__img-container {
    width: 120px;
    height: 120px;
    img {
      width: 100%;
    }
  }
  &__text {
    margin-top: 20px;
    h2 {
      font-size: $fs-18;
      font-weight: bold;
      color: $color-black-true;
    }
    p {
      font-size: $fs-16;
      line-height: 1.5;
      padding: 0 65px;
      color: $color-gray-200;
      margin-top: 15px;
      font-weight: 600;
    }
  }
}

body[dir='rtl'] {
  .bidder-list__user__info {
    margin-left: initial;
    margin-right: 20px;
  }
  .offcanvas .offcanvas-header .btn-close {
    margin-right: unset;
    margin-left: 2px;
  }
  .bidder-list .avatar__icon {
    left: -10px;
    right: unset;
  }
}
@media only screen and (max-width: 560px) {
  .offcanvas-start,
  .offcanvas-end {
    width: 94%;
  }
  .total-bids-list__total {
    font-size: $fs-18;
  }
}
