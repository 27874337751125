.widget-wrapper {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  border: solid 1px $color-grey-1000;
  background-color: $color-white-true;

  &__items {
    display: flex;
    align-items: flex-start;

    &__content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      h4 {
        font-size: $fs-14;
        font-weight: 600;
        margin-left: 14px;
        color: $color-black-true;
      }

      p {
        margin-top: 10px;
        margin-left: 10px;
        color: $color-gray-200;
        font-size: $fs-12;
        margin-bottom: 20px;
        font-weight: 500;
      }
    }
  }

  &__items:last-child p {
    margin-bottom: 0;
  }
}

body[dir="rtl"] {
  .widget-wrapper {
    &__items {
      &__content {
        h4 {
          margin-left: unset;
          margin-right: 1.5em;
        }

        p {
          margin-right: 1.628em;
          margin-left: unset;
        }
      }
    }
  }
}