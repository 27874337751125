.bidnow-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &__button-group {
    button {
      height: 36px;
      padding: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  &__label {
    font-size: $fs-12;
  }
  &__price {
    font-size: $fs-18;
    color: $color-primary;
    line-height: 20px;
  }
  &__currency {
    color: $color-primary;
    font-size: $fs-11;
    top: 8px;
    font-weight: 600;
    margin-right: 2px;
  }
}
body[dir='rtl'] {
  .bidnow-mobile__currency{
    top:6px;
    order:2;
  }
}
