.languageSwitcher-item {
  display: flex;
  height: 45px;
  padding: 14px 15px 10px 20px;
  vertical-align: middle;
  justify-content: space-between;
  cursor: pointer;
  &_wrapper {
    font-size: $fs-14;
    font-weight: 600;
    &_img {
      margin-right: 10px;
    }
    &_title {
      color: $color-white-true;
      &_active {
        color: $color-black-true;
      }
    }
  }
}

body[dir='rtl'] {
  .languageSwitcher-item {
    padding: 14px 15px 10px 0px;
    &_wrapper {
      margin-top: -4px;
    }
  }
}

.nav-info .languageSwitcher-item_wrapper_title {
  color: $color-black-true;
}

span.languageSwitcher-item_wrapper_title:hover {
  opacity: 0.5;
  transition: all;
}
