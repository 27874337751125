.post-card-image-horizontal {
  position: relative;
  width: 160px;

  &__header {
    position: absolute;
    width: 100%;
    top: 5px;
    display: flex;
    justify-content: space-between;
    z-index: $z-index-1;

    .first-side,
    .second-side {
      padding: 0 5px;
    }

    .first-side {
      .post-label__condition-wrapper {
        line-height: 1;
      }
    }

    .second-side {
      display: flex;

      .post-icon,
      .add-to-watch-list {
        height: 18px;
        width: 18px;

        .icon {
          font-size: $fs-14;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    height: 22px;
    background-color: $color-black-true;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 0 5px;

    .first-side,
    .second-side {
      padding: 0 10px;
      color: $color-white-700;
    }

    .first-side {
      small {
        color: $color-white-true;
        font-weight: 600 !important;
      }

      .timer-small__end-in,
      .timer-small__end-in__daysCountdown {
        font-size: $fs-10;
        font-weight: 500;
      }
    }
  }

  &__content {
    height: 98px;
    width: 160px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px 0 0 0;
      object-fit: cover;
      object-position: center;
    }
  }
}

body[dir='rtl'] {
  .post-card-image-horizontal__footer {
    border-radius: 0 0 5px 0;
  }

  .post-card-image-horizontal__content img {
    border-radius: 0 5px 5px 0;
  }
}
