.post-Status-Mobile {
  width: 75%;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.1);
  margin: auto;
  background-color: red;
  text-align: center;
  padding: 9px 0 8px 0;
  &__text {
    font-size: $fs-12;
    font-weight: bold;
  }
}

.darkRed {
  background-color: $color-tomato-100;
  color: $color-white-true;
}
.darkBlue {
  background-color: $color-blue-1600;
  color: $color-white-true;
}
body[dir='rtl'] {
  .post-Status-Mobile {
    .box:first-child {
      margin-right: auto;
      border-radius: 0 10px 0 0;
    }
    .box:last-child {
      margin-left: auto;
      border-radius: 10px 0 0 0;
    }
  }
}
