.contactInfo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  span:hover {
    color: $color-blue-200;
    transition: 200ms;
  }
  span,
  .phone {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
    color: $color-gray-200;
    direction: ltr;
  }
  h5 {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
  }
}
.socialMedia {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 540px) {
  .contactInfo-wrapper h5,
  .customerService-wrapper h5,
  .downloadApp-wrapper h5 {
    font-size: 12px;
  }
}
