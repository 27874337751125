.service-point {
  display: flex;
  padding: 0 19.5% 50px;
  gap: 8.5%;
  &__img img {
    width: 534.6px;
    height: 601.7px;
    object-fit: contain;
  }
  &__text {
    padding-top: 75px;
    width: 40%;
    &__title {
      font-size: $fs-32;
      font-weight: 600;
      margin-bottom: 30px;
    }
    &__description {
      color: $color-gray-100;
      font-size: $fs-18;
      font-weight: 500;
      margin-bottom: 31px;
    }
  }
  &__details {
    &__wrapper {
      display: flex;
      margin-bottom: 20px;
      .icon-call,
      .icon-map_address {
        font-size: 24px;
      }
      &__text {
        font-size: 16px;
        margin-left: 15px;
        direction: initial;
      }
    }
  }
  .collapsible {
    width: 100%;
    margin-bottom: 30px;
  }
}
body[dir='rtl'] {
  .service-point {
    &__details {
      &__wrapper {
        .icon-call,
        .icon-map_address {
          transform: scaleX(-1);
        }
        &__text {
          margin-left: unset;
          margin-right: 15px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1440px) {
  .service-point {
    padding: 0 12% 45px;
  }
}
body[dir='rtl'] {
  .service-point__details__list__description {
    padding-right: initial;
    padding-left: 220px;
  }
}
@media only screen and (max-width: 1280px) {
  .service-point {
    padding: 0 33px 45px;
    gap: 4%;
    &__text {
      padding-right: 22px;
      &__title {
        font-size: $fs-25;
      }
      &__description {
        font-size: $fs-16;
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .service-point__text__title {
    margin-bottom: 15px;
  }
  .service-point__text__description {
    margin-bottom: 18px;
  }
  .service-point__img img {
    width: 350px;
    height: auto;
  }
  .service-point__details__list {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 716px) {
  .service-point {
    flex-direction: column;
    padding: 0 10px;
    &__img {
      display: none;
    }
    &__text {
      width: 100%;
      order: 2;
      text-align: start;
      padding: 42px 5px 32px;
      &__title {
        font-size: 24px;
        margin-bottom: 12px;
      }
      &__description {
        font-size: 14px;
        margin-bottom: 21px;
      }
    }
    .location-btn {
      button {
        width: 100%;
      }
    }
  }
  body[dir='rtl'] {
    .service-point__details__list__description {
      padding-left: initial;
    }
  }
}
