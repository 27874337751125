.seller-profile-wrapper {
  display: flex;
  gap: 30px;
  padding: 50px 0;
}

.loader-wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .seller-profile-wrapper {
    display: block;
    padding: 40px 0;
  }
  .seller-profile-section {
    .bread-crumb {
      padding: 10px 20px;
    }

    .container {
      max-width: 95%;
    }
  }
}

@media screen and (max-width: 912px) {
  .bread-crumb .breadcrumb-item:not(:first-child):not(:last-child) {
    display: flex;
  }
}

@media screen and (max-width: 560px) {
  .bread-crumb .breadcrumb-item:not(:first-child):not(:last-child) {
    display: none;
  }
}