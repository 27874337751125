.paymentSection {
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
  flex-wrap: wrap;
  padding: 21px 0 0;
  border-top: 1px solid $color-grey-3000;
  span {
    font-size: $fs-12;
    color: $color-gray-100;
    font-weight: 500;
  }
  .left-side {
    display: flex;
    align-items: baseline;
  }
  .payments-icons {
    margin-right: 1rem;
    display: flex;
    .visa-icon {
      img {
        width: 41px;
        margin-left: 11px;
      }
    }
    .mastercard-icon {
      img {
        width: 27.5px;
        margin-left: 11px;
      }
    }

    .fawry-icon {
      img {
        width: 47.4px;
        margin-left: 11px;
      }
    }
    .valu-icon {
      img {
        width: 44px;
        margin-left: 11px;
      }
    }
  }
  .right-reserved {
    text-align: end;
  }
}
