.post-title {
  display: grid;

  h3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    font-weight: 600;
    line-height: 21px;
    text-transform: capitalize;
    font-size: $fs-14;
  }
}

.post-title--light {
  color: $color-black-true;
}

.post-title--primary,
.post-title--secondary {
  color: $color-white-true;
}
