.post-card-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: start;

  &__horizontal-separator {
    border-top: 1px solid $color-grey-1000;
  }
}

.post-card-info--light {
  background-color: $color-white-true;
  bottom: 72px;
}

.post-card-info--primary {
  background-color: $color-black-true;
}

.post-card-info--secondary {
  background-color: $color-secondary;
}
