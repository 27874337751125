.about-those {
  padding: 40px 0;
  background-color: $color-white-true;

  &_wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: baseline;
    align-items: baseline;

    &_header {
      font-size: $fs-24;
      font-weight: bold;
      line-height: 1.33;
      color: $color-black-true;
    }
  }

  &.blue {
    background-color: $color-jungle-900;
    border-bottom: none;
  }

  body[dir='rtl'] {
    .about-those {
      &_wrapper {
        padding: 0 17%;
        direction: rtl;
      }
    }
  }

  .swiper-inner-wrapper {
    padding: unset;
    margin: 0 74px;
  }
}

@media screen and (max-width: 1550px) {
  .about-those {
    padding: 40px 0;
  }
}

@media screen and (max-width: 1024px) {
  .about-those {
    padding: 40px 0;

    &_wrapper {
      justify-content: center;

      &_header {
        font-size: $fs-24;
        text-align: center;
      }
    }

    .swiper-inner-wrapper {
      padding: unset;
      margin: 0 32px;
    }
  }
}

@media screen and (max-width: 768px) {
  .about-those {
    .swiper-inner-wrapper {
      padding: 0 39px;
      margin: unset;
    }
  }
}

@media screen and (max-width: 560px) {
  .about-those {
    padding: 30px 0 30px 0;
    border-bottom: 6px solid $color-grey-4100;
    border-top: 6px solid $color-grey-1600;

    &.blue {
      background-color: $color-white-true;
      border-bottom: none;
    }

    &:last-child {
      border-bottom: none;
    }

    &_wrapper {
      padding: 0 20px;
      margin-bottom: 0px;
      justify-content: start;

      &_header {
        font-size: $fs-16;
        line-height: 2;
        font-weight: 600;
        border-bottom: 2px solid $color-grey-1000;
        width: 99%;
        text-align: start;
        margin-bottom: 20px;
      }
    }

    .swiper-inner-wrapper {
      padding: 0 20px;
      margin: unset;
    }
  }

  body[dir='rtl'] {
    .about-those {
      &_wrapper {
        padding: 0 20px;
      }
    }
  }
}
