.links-wrapper {
  width: 370px;
  background-color: $color-blue-900;
  height: fit-content;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  &_header {
    font-size: $fs-16;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &_divider {
    height: 2px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: $color-grey-1000;
    opacity: 0.9;
  }
  &_item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      color: $color-jungle-100;
      font-size: $fs-14;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 1280px) {
  .links-wrapper {
    width: 300px;
  }
}

@media screen and (max-width: 1280px) {
  .links-wrapper {
    width: 270px;
  }
}

@media screen and (max-width: 650px) {
  .links-wrapper {
    background-color: $color-white-true;
    width: 100%;
    padding: unset;
  }
}
