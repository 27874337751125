.post-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  flex: 1;
  padding: 10px;

  &__availability {
    display: flex;
    align-items: baseline;

    div::after {
      content: '|';
      margin: 0 9.5px;
      font-size: 10px;
      font-weight: 700;
    }

    div:last-child::after {
      content: '';
      margin: initial;
    }
  }
}

.post-card-content--light {
  min-height: 86px;

  .post-card-content__availability {
    div::after {
      color: $color-grey-2300;
    }
  }
}

.post-card-content--primary,
.post-card-content--secondary {
  min-height: 93px;

  .post-card-content__availability {
    div::after {
      color: $color-grey-2300;
    }
  }
}

body[dir='rtl'] {
  .post-card-content__availability div::after {
    margin: 0 6px 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .post-card-content {
    padding: 15px 15px 5px 15px;
  }
}
