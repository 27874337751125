.post-similar{
    display: flex;
    &__icon{
        height: 18px;
        width: 18px;
        color: $color-gray-icon;
        margin: 0 3px;
    }
    &__label{
        font-size: $fs-12;
        font-weight: 500;
        color: $color-gray-200;
    }
}