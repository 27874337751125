.categories-header {
  box-shadow: 0 3px 6px 0 $color-grey-4100;
  position: relative;
  z-index: 110;
  background-color: white;
  display: flex;
  flex-direction: column;

  &__body {
    background-color: $color-white-true;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  .overlay {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-grey-900;
    cursor: pointer;
  }

  .nav-close {
    align-self: flex-end;
    margin: 15px 1.8rem;
    cursor: pointer;
    color: $color-jungle-1000;
  }

  &__scrollable {
    box-shadow: 0 3px 6px 0 $color-grey-4100;
    z-index: 2;
    background-color: $color-white-true;
  }

  .nav {
    justify-content: center;
    align-items: center;
    height: 50px;
    overflow: hidden;
    width: 90vw;
    margin: auto;
    .nav-link {
      font-size: $fs-14;
      font-weight: 600;
      padding: 0;
      display: flex;
      align-items: center;
    }

    .dropdown-toggle::after {
      opacity: 0.2;
    }

    .nav-links {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .nav-item {
        a {
          color: $color-black-true;
          margin: 15px 0;
          padding: 0;
          position: relative;

          &:hover {
            color: $color-primary;
          }
        }

        &.nav-active {
          a {
            color: $color-primary;
          }

          border-bottom: 3px solid $color-primary;
        }
      }
    }

    .nav-info {
      display: flex;
      align-items: center;

      .dropdown {
        margin-left: 3px;
      }

      .business {
        font-size: $fs-14;
        margin: 0px 30px;
        color: $color-primary;
        font-weight: 600;
        display: flex;
        align-items: center;

        .nav-info__icon-business {
          color: $color-grey-3000;
          font-size: 22px;
          margin: 0 5px;
        }
      }

      .icon-language {
        font-size: 21px;
        color: $color-grey-3000;
        margin-right: 3px;
      }

      &__become-business {
        margin-right: 20px;
      }
    }
  }
}

.mobile-search-container {
  position: sticky !important;
  top: 0;
  background-color: $color-white-true;
  z-index: 110;
  width: 100vw !important;

  .search-mobile {
    width: 100vw;

    &__searchbar {
      position: static;

      .search-results {
        width: 100vw;
      }
    }
  }
}

.categories {
  display: grid;
}

.cardProducts-overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-grey-4300;
  opacity: 1;
  z-index: $zindex-overlay-language;
  cursor: pointer;
}

.slider-container {
  padding: 0 0 30px;
  background-color: white;

  .no-cards-wrapper {
    position: relative;
    background-color: white;
    height: 200px;
    width: 90%;
    z-index: $z-index-2;
    margin: auto;

    .cards-zero-state {
      padding: 31.3px 61px 72.2px 64.8px;
      text-align: center;
    }
  }

  .swiper-inner-wrapper {
    padding: 0 30px;
    margin: unset;
  }
}

body[dir='rtl'] {
  .categories-header {
    .nav {

      .nav-info {
        .dropdown {
          margin-right: 3px;
          margin-left: 0;
        }

        .icon-language {
          margin-left: 3px;
          margin-right: 0;
        }

        .business {
          margin-right: 0.3rem;
        }

        &__become-business {
          margin-right: initial;
          margin-left: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .categories-header {
    .slider-container {
      padding: 6px 0 30px 0;
    }
  }
}

@media only screen and (max-width: 912px) {
  .categories-header {
    .nav{
      height: auto;
      overflow: inherit;
      width: auto;
      margin: unset;
    }
    &__scrollable {
      padding-left: 20px;
    }

    .nav {
      width: max-content;
      padding: initial;

      .nav-links {
        .nav-item a {
          font-size: $fs-13;
          margin: 6px 0;
        }

        .nav-item:first-child a {
          margin: 6px 0;
        }
      }
    }
  }
  body[dir='rtl'] {
    .categories-header .nav {
      margin-left: initial;

      .nav-item:first-child a {
        margin: 6px 0 6px 12px;
      }
    }

    .categories-header {
      &__scrollable {
        padding-left: initial;
        padding-right: 20px;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .no-cards-wrapper .cards-zero-state {
    padding: 31.3px 0 72.2px !important;
    font-size: 10px;
  }
}
