.categories-search {
  padding: 30px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .categories-search-input {
    border-radius: 15px;
    border: 1.5px solid $color-grey-3000;
    height: 48px;
    font-weight: 500;
  }

  .search-icon {
    margin: -36px 16px 0;

    .icon-search {
      font-size: 22px;
      color: $color-gray-200;
    }
  }
}

.categories-search-input::placeholder {
  font-size: $fs-14;
  color: $color-gray-200;
  font-weight: 600;
}

@media screen and (max-width: 560px) {
  .categories-search {
    padding: 30px 20px 10px;
  }
}
