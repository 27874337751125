.zoomed-image-container {
  z-index: 1;
  direction: ltr;
  top: 0%;
}

.rtl .zoomed-image-container {
  left: 0% !important;
  right: 100% !important;
}

.imageLabel {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 7px 7px;
  font-weight: bold;
  line-height: 1.91;
  text-align: center;

  &_content {
    font-size: $fs-11;
    color: $color-white-true;
  }
}

.image-gallery-slide {
  img {
    width: 100%;
    height: 100% !important;
    object-fit: contain;
  }
}

.image-gallery-slide > div {
  aspect-ratio: 4/3;
}

.sticky_top {
  position: sticky;
  top: 0;
}

.view-icons {
  display: flex;
  align-items: center;
}

.image-icons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 3.8px;
  width: 100%;
  padding: 10px;
  z-index: $z-index-1;
}

body[dir='rtl'] {
  .image-icons {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 1400px) {
  .zoomed-image-container {
    z-index: 1;
    direction: ltr;
    top: 0%;
  }

  .rtl .zoomed-image-container {
    left: 0% !important;
    right: 100% !important;
  }

  .sticky_top {
    position: sticky;
    top: 0;
  }
}

@media only screen and (max-width: 1400px) {
  .zoomed-image-container {
    z-index: 1;
    direction: ltr;
    top: 0%;
  }
}

@media only screen and (max-width: 1200px) {
  .image-icons {
    right: unset;
  }
}

@media only screen and (max-width: 1024px) {
  .image-gallery-slide > div {
    max-width: 100% !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 992px) {
  .image-gallery-slide > div {
    max-height: 435px;
  }
}

@media only screen and (max-width: 912px) {
  .imageLabel {
    display: none;
  }

  body[dir='rtl'] {
    .image-icons {
      left: 10px;
    }
  }
}
