.category-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  .category-name {
    span {
      font-weight: 600;
      font-size: 12px;
      padding: 0;
      border: 0;
    }
  }
  .category-children {
    display: flex;
    flex-wrap: wrap;
    .category-child {
      position: relative;
      span {
        font-size: 12px;
        font-weight: 500;
        color: $color-gray-200;
        margin: 0px 10px;
      }
    }
    .category-child::after {
      content: "|";
      color: $color-grey-3100;
      margin-top: 1px;
      position: absolute;
    }
    .category-child:last-child::after {
      content: "" !important;
    }
  }
}

.category-section:last-child {
  margin-bottom: unset;
}
@media only screen and (min-width: 1024px) and (max-width: 1419px) {
  .category-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .category-name {
      margin-bottom: 10px;
    }
    .category-children {
      .category-child {
        span {
          margin: 0px 14px 0px 0px;
        }
      }
    }
    .category-child::after {
      content: "|";
      color: $color-gray-600;
      margin-top: 1px;
      right: 4px;
      position: absolute;
    }
  }
}
body[dir="rtl"] {
  .category-section {
    display: flex;
    align-items: center;

    .category-name {
      span {
        font-weight: 700 !important;
        padding: 0;
        border: 0;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1419px) {
    .category-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .category-name {
        margin-bottom: 10px;
      }
      .category-children {
        .category-child {
          span {
            margin: 0px 14px 0px 0px;
          }
        }
      }
      .category-child::after {
        left: -8px;
        right: unset;
      }
    }
  }
}
