.strength-bar {
  display: flex;
  position: absolute;
  top: 48px;
  width: 30%;
  margin-top: 10px;
  &__list {
    width: 31%;
    height: 3px;
    background-color: $color-grey-2600;
    margin-right: 2px;
  }
}
body[dir='rtl'] {
  .strength-bar {
    top: 52px;
  }
}
