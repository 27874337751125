.bottom-menu {
  display: flex;
  justify-content: space-between;
  height: 60px;
  background-color: $color-white-true;
  text-align: center;
  align-items: center;
  position: relative;
  border: solid 1px $color-grey-3800;
  z-index: 400;

  &_item {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center;
    cursor: pointer;

    &_text {
      color: $color-grey-3900;
      font-size: $fs-11;
      font-weight: 500;
    }

    img {
      width: 24px;
    }

    &.btn-active {
      position: relative;
    }

    &.active-icon {
      .icon-home_bar {
        color: $color-blue-200;
      }

      .icon-categories_bar {
        color: $color-blue-200;
      }
    }

    .add-button {
      margin: auto;
      height: 25px;
      position: relative;

      &__icon-container {
        border-radius: 50%;
        height: unset;
        padding: 8.5px;

        .icon {
          font-size: 35px;
        }
      }

      &__text {
        display: none;
      }
    }

    .icon-home_bar,
    .icon-dashboard,
    .icon-profile,
    .icon-search {
      font-size: 24px;
      color: $color-grey-3900;
    }
  }

  .popover-wrapper {
    width: 280px;
    top: unset;
    bottom: 75px;
    padding: 7px 1px;

    &.popover--bottom::before {
      top: unset;
    }

    left: calc((90% - 280px) / 2);

    &::before {
      left: 50%;
      bottom: -9px;
      top: unset;
      right: unset;
      rotate: 180deg;
    }

    .accountDropdown-item {
      padding: 14px 15px 14px 20px;
    }
  }

  .mazadat-valet.mobile {
    left: calc((90% - 260px) / 2);
  }

  .profile-menu {
    .popover-wrapper {
      padding: 0;

      &::before {
        left: 86%;
      }

      right: 6.3%;
      left: unset;
    }
  }
}

body[dir='rtl'] {
  .bottom-menu_item {
    .add-button {
      &__icon-container {
        border-radius: 50%;
        padding: 8.5px;
      }
    }

    .popover-wrapper {
      left: calc((92% - 280px) / 2);
      right: unset;

      &::before {
        left: 50%;
      }
    }

    .profile-menu {
      .popover-wrapper {
        &::before {
          right: 86%;
          left: unset;
        }

        right: unset;
        left: 6.3%;
      }
    }
  }
}

.login-user-avatar-mobile {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .bottom-menu {
    .profile-menu {
      .popover-wrapper {
        right: 5%;
      }
    }
  }
  body[dir='rtl'] {
    .bottom-menu {
      .mazadat-valet.mobile {
        left: calc((89% - 260px) / 2);
      }

      .popover-wrapper {
        left: calc((89% - 280px) / 2);
      }

      .profile-menu {
        .popover-wrapper {
          left: 4.5%;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .bottom-menu {
    .profile-menu {
      .popover-wrapper {
        right: 4%;
      }
    }
  }
  body[dir='rtl'] {
    .bottom-menu {
      .profile-menu {
        .popover-wrapper {
          left: 4%;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .bottom-menu {
    .mazadat-valet.mobile {
      left: calc((85% - 260px) / 2);
    }

    .popover-wrapper {
      left: calc((85% - 280px) / 2);
    }

    .profile-menu {
      .popover-wrapper {
        right: 4%;
      }
    }
  }
  body[dir='rtl'] {
    .bottom-menu {
      .profile-menu {
        .popover-wrapper {
          left: 3%;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  body[dir='rtl'] {
    .bottom-menu {
      .mazadat-valet.mobile {
        left: calc((85% - 260px) / 2);
      }

      .popover-wrapper {
        left: calc((85% - 280px) / 2);
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .bottom-menu {
    .popover-wrapper {
      left: calc((80% - 280px) / 2);
    }

    .mazadat-valet.mobile {
      left: calc((80% - 260px) / 2);
    }

    .profile-menu {
      .popover-wrapper {
        right: 2.5%;
      }
    }
  }
  body[dir='rtl'] {
    .bottom-menu {
      .profile-menu {
        .popover-wrapper {
          left: 2.5%;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .bottom-menu {
    .popover-wrapper {
      left: calc((75% - 232px) / 2);
    }
  }
}
