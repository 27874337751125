.review-list {
  margin: 0 0 22px 0;
  display: flex;
  justify-content: space-between;

  &__container {
    display: flex;
    justify-content: space-between;
    gap: 59px;
  }

  &__content {
    background-color: $color-jungle-900;
    border-radius: 0 15px 15px 15px;
    padding: 15px;
    position: relative;
    color: $color-black-true;
    font-size: $fs-14;
    max-width: 387px;
    font-weight: 500;
    line-height: 1.5;
  }

  &__zero-state {
    color: $color-grey-2500;
  }

  &__content:before {
    content: '';
    position: absolute;
    left: 0px;
    top: -8px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 10px solid $color-jungle-900;
    border-bottom: 10px solid transparent;
  }

  &__reviews-container {
    margin-bottom: 10px;
  }

  &__reviewer-list {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    &__label {
      font-weight: 500;
      font-size: $fs-12;
      margin-bottom: 5px;
      color: $color-grey-3900;
    }

    &__rate {
      font-weight: 600;
      font-size: $fs-12;
      color: $color-black-true;
    }
  }

  &__rate {
    margin-bottom: 20px;
  }
}

body[dir='rtl'] {
  .review-list {
    &__content {
      border-radius: 25px 0 25px 25px;
    }

    &__content:before {
      left: initial;
      right: 0px;
      top: -9px;
      border-right: 10px solid $color-jungle-900;
      border-left: initial;
    }
  }
}

.mobile-rating-points {
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid $color-grey-1000;

  &:last-child {
    border-bottom: none;
  }

  .view-details-collaspe {
    font-size: $fs-11;
    font-weight: 600;
    line-height: 1.64;
    color: $color-primary;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    span {
      color: $color-black-true;
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .review-list {
    &__avatar,
    &__container {
      display: block;
    }
  }
}
