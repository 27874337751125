.connect-From-Heart {
  padding-top: 74px;
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 39px;
    &__title {
      color: $color-black-true;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    &__description {
      color: $color-black-true;
      font-size: $fs-18;
      font-weight: 500;
    }
  }
  &__social {
    display: flex;
    width: 100vw;
    .social-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25%;
      height: 120px;
      font-size: 42px;
      color: white;
    }
    &__facebook {
      background-color: $color-facebook;
    }
    &__instagram {
      background-color: $color-instagram;
    }
    &__youtube {
      background-color: $color-youtube;
    }
    &__linkedin {
      background-color: $color-linkedin;
    }
  }
}
@media only screen and (max-width: 912px) {
  .connect-From-Heart__social .social-icon {
    height: 84px;
    font-size: $fs-32;
  }
}
@media only screen and (max-width: 560px) {
  .connect-From-Heart {
    padding-top: 40px;

    &__text {
      margin-bottom: 24px;
      &__title {
        font-size: 24px;
      }
      &__description {
        font-size: $fs-16;
        text-align: center;
      }
    }
    &__social .social-icon {
      height: 45px;
      font-size: 16px;
    }
  }
}
