.title-container {
  display: flex;
  background-image: $color-violet-300-gradient;
  height: 142px;
  align-items: center;
  justify-content: center;
  width: 100%;
  &_name {
    color: $color-white-true;
    font-size: $fs-32;
    font-weight: bold;
    line-height: 0.75;
  }
}

@media screen and (max-width: 912px) {
  .title-container {
    display: none;
  }
}
