.large-timer {
  display: flex;
  text-align: left;
  gap:4px;

  &__list {
    display: inline-block;
    text-align: center;

    &__box {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      width: 41px;
      height: 39px;
      font-size: $fs-21;
      font-weight: bold;
      border: 1px solid $color-grey-1000;
      box-shadow: 0 3px 6px 0 $color-grey-3300;
      margin: 0 4px;
      &:first-child {
        margin: 0;
      }
    }

    &__label {
      font-size: $fs-11;
      color: $color-grey-3900;
      text-transform: capitalize;
      font-weight: 600;
      line-height: normal;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .large-timer {
    &__list {
      &__box {
        margin: 0 2px;
      }
    }
  }
}

@media only screen and (max-width: 986px) {
  .large-timer {
    &__list {
      &__box {
        margin: 0 0px;
      }
    }
  }
}
