.phone-wrapper {
  display: flex;
  gap: 30px;
  width: 100%;
  .text-field--phone-input {
    width: 65%;
  }
  .text-field--code-input {
    width: 40%;
    cursor: pointer;
  }
}

@media screen and (max-width: 912px) {
  .phone-wrapper {
    gap: 15px;
    .text-field--phone-input {
      width: calc(100% - 101px);
    }
    .text-field--code-input {
      width: 86px;
    }
  }
}

body[dir='rtl'] {
  .phone-wrapper {
    .text-field--phone-input {
      input {
        direction: rtl;
      }
    }
  }
}
