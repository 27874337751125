.search-mobile {
  &__searchbar {
    background-color: white;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    padding: 6px 20px;
    border-bottom: 1px solid $color-grey-3200;

    form {
      flex: 1;
    }

    input {
      border: none;
      font-size: $fs-12;
      width: 100%;
      padding: 0;
      background-color: $color-white-true;
    }

    input::placeholder {
      font-size: $fs-12;
    }
  }

  &__input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    &.ar {
      input {
        text-align: right;
      }
    }
  }

  &__icon-container {
    background-color: $color-white-200;
    border-radius: 50%;
    width: 32px;
    height: 32px;

    .icon-search {
      color: $color-white-true;
      font-size: $fs-16;
    }
  }
}

body[dir='rtl'] {
  .search-mobile {
    &__input-group {
      &.en {
        input {
          text-align: left;
        }
      }
    }
  }
}

@media only screen and (max-width: 912px) {
  .loader-icon {
    height: 24px;
    display: flex;
    justify-content: center;
  }

  .search-mobile {
    &__icon-container {
      background-color: $color-white-200;
      border-radius: 50%;
      width: 30px;
      height: 30px;

      .icon-search {
        font-size: $fs-16;
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  .search-mobile {
    &__icon-container {
      background-color: $color-white-200;
      border-radius: 50%;
      width: 28px;
      height: 28px;

      .icon-search {
        font-size: $fs-16;
      }
    }
  }
}
