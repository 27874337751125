.all-categories-main {
  border-bottom: 7px solid $color-grey-1600;
  overflow: hidden;

  .all-categories-section {
    padding: 30px;

    h2 {
      font-size: $fs-24;
      font-weight: bold;
      margin-bottom: 20px;
      color: $color-grey-3900;
    }

    .favourte-categories {
      .category-header {
        padding: 0;
      }
    }

    .all-categories {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 24px;

      .category-items {
        .top-category-card {
          flex: 1;
        }

        @media screen and (max-width: 550px) {
          width: 100%;
        }

        @media screen and (max-width: 1025px) and (min-width: 550px) {
          width: 217px;
        }

        @media screen and (min-width: 1025px) {
          width: 206px;
        }
      }
    }
  }
}

.categories-count {
  font-size: $fs-12;
  font-weight: 600;
}

.category-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 0;
}

.no-favourite-selected {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 30px;

  p {
    font-size: $fs-14;
    font-weight: 600;
  }
}

@media only screen and (max-width: 768px) {
  .all-categories-main {
    .all-categories-section {
      .title-wrapper {
        padding: unset;
      }

      .all-categories {
        gap: 24px;
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .all-categories-main {
    .all-categories-section {
      padding: unset;

      .favourte-categories {
        .category-header {
          padding: 0 20px;
        }

        .divider-wrapper {
          padding: 0 20px;
        }

        .empty-view {
          padding: 0 20px 30px;
        }
      }

      h2 {
        font-size: $fs-16;
        margin-bottom: unset;
      }

      .title-wrapper {
        padding: 0 20px;
        text-align: start;
      }

      .all-categories {
        display: grid;
        grid-column-gap: 10px;
        column-gap: 10px;
        row-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        margin: 0 auto;
        padding: 0 20px;
      }
    }
  }
}
