.image-gallery {
  z-index: $z-index-0;
  &-left-nav > &-svg,
  &-right-nav > &-svg {
    height: 48px !important;
    width: 48px !important;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: white;
    border: 1px solid $color-grey-1000;
    transition: 0.3s;
    color: $color-grey-1000;
    polyline {
      color: $color-grey-1000;
    }
  }
  &-left-nav,
  &-right-nav {
    filter: initial;
    padding: 0 !important;
    margin: 0 10px;
  }
  &-left-nav:hover > &-svg,
  &-right-nav:hover > &-svg {
    background-color: $color-primary;
    border: none;
    color: white;
    polyline {
      color: white;
    }
  }
  &-slide {
    border: 1px solid $color-gray-700;
  }

  &-image {
    aspect-ratio: 4/3 !important;
    object-fit: contain !important;
  }
  &-thumbnails {
    padding: 15px 0px !important;
  }
  &-thumbnail {
    padding: 5px 20px !important;
    border: 2px solid $color-gray-700;
    margin: 0 5px !important;
    border-radius: 10px;
    &-image {
      border-radius: 8px;
      aspect-ratio: 4/3;
    }
  }

  &-thumbnail.active,
  &-thumbnail:focus,
  &-thumbnail:hover {
    outline: none;
    border: 2px solid $color-primary;
  }
  &-icon:focus,
  &-icon:hover {
    color: white;
  }

  &__left-container {
    position: absolute;
    top: 50%;
  }
  &-content.fullscreen {
    background: white;
  }
  &-thumbnail-image {
    object-fit: contain;
  }
  .image-gallery-thumbnail-image {
    object-fit: contain;
  }
  &-thumbnail {
    aspect-ratio: 4/3;
    padding: 0px !important;
  }
  &-thumbnails {
    &-container {
      cursor: auto !important;
    }
  }
}

.slider {
  position: relative;
  width: 100%;
  .badge {
    top: 43px;
  }
  &__icon-mobile {
    display: none;
  }
  &__tap {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.38);
    padding: 7px 10px;
    border-radius: 5px;
    z-index: $z-index-1;
    width: max-content;
    color: white;
    left: calc(50% - 50.5px); // 50px represents half the tap width
    top: calc(50% - 15px); // 15px represents half tha tap height
    font-weight: bold;
    border: none;
    p {
      font-size: $fs-11;
    }
  }
}
.fullscreen .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 120px);
}
@media only screen and (max-width: 912px) {
  .image-gallery {
    padding-top: 0;
    .fullscreen {
      z-index: $zindex-top-level;
    }
    .image-gallery-image {
      object-fit: contain !important;
    }
  }
  .slider {
    position: relative;
    &__icon-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0px;
      z-index: $z-index-1;
      width: 100%;
      padding: 10px;
      &__second-part {
        display: flex;
      }
    }
  }
  .image-gallery .image-gallery-slide {
    border: none;
  }
  .slider .image-gallery-thumbnails {
    background-color: black;
  }
}

body[dir='rtl'] {
  .slider {
    .badge {
      right: initial;
      left: 10px;
    }
  }
}
