.counter {
  display: flex;
  font-size: $fs-14;
  font-weight: 600;
  margin-bottom: 15px;
  &__text {
    color: $color-gray-100;
  }
  &__resend-container {
    margin: 0 5px;
  }
  &__resend {
    color: $color-gray-100;
  }
  &__button {
    color: $color-primary;
    margin: 0 5px;
    &:hover {
      border-bottom: 1px solid $color-primary;
      height: 21px;
    }
    &.disabled {
      border-bottom: none;
    }
  }
}
@media only screen and (max-width: 560px) {
  .counter {
    justify-content: center;
    flex-wrap: wrap;
  }
}
