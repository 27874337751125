.comingsoon-view {
  display: grid;
  background: url(../../../assets/images/coming-soon.svg), $color-blue-1500-gradient;
  padding: 40px 0;

  &_title {
    font-size: $fs-60;
    font-weight: 900;
    line-height: 0.93;
    color: $color-white-true;
    text-align: center;
  }
}

.comingSoon-container {
  display: block;
  margin: auto;

  .comingSoon-wrapper {
    display: flex;
    background-color: $color-white-true;
    border-radius: 30px;
    padding: 20px 30px 20px 0;
    width: 510px;
    position: relative;
    align-items: center;
    margin-top: 30px;

    &_img {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      position: absolute;
      left: -150px;
    }

    &_right {
      margin-left: 186px;

      &_header {
        font-size: $fs-29;
        font-weight: bold;
        line-height: 1.31;
        margin-bottom: 20px;
        margin-top: 10px;
      }

      &_text {
        font-size: $fs-14;
        font-weight: 600;
        line-height: 1.14;
      }

      &_btn {
        padding: 16px 20px;
        margin-top: 20px;
        background-color: $color-primary;
        color: $color-white-true;
        border-radius: 15px;
        font-size: $fs-13;
        margin-bottom: 10px;
      }
    }
  }

  .large-timer {
    margin-top: 15px;
    display: block;

    &__list {
      display: inline-block;
      margin: 0 3px;
      text-align: center;

      &__box {
        width: 60px;
        height: 55px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

body[dir='rtl'] {
  .comingSoon-container {
    .comingSoon-wrapper {
      padding: 20px 0 20px 30px;

      &_img {
        right: -150px;
      }

      &_right {
        margin-left: unset;
        margin-right: 186px;
      }
    }

    .large-timer {
      text-align: right;
      margin-top: 10px;

      &__list {
        margin: 0 3px;

        &:first-child {
          margin-right: 0;
          margin-left: 3px;
        }

        &:last-child {
          margin-left: 0;
          margin-right: 3px;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .comingSoon-container .comingSoon-wrapper_right_header {
    font-size: $fs-28;
  }
  .comingSoon-container_title {
    font-size: 50px;
  }
}

@media screen and (max-width: 850px) {
  .comingSoon-container {
    .comingSoon-wrapper {
      width: 400px;

      &_right {
        margin-left: 163px;

        &_header {
          font-size: $fs-21;
        }
      }

      .large-timer {
        &__list {
          margin: 0 2px;

          &__box {
            width: 46px;
          }
        }
      }
    }
  }
  body[dir='rtl'] {
    .comingSoon-container {
      .comingSoon-wrapper {
        &_right {
          margin-right: 163px;

          &_header {
            font-size: $fs-24;
          }
        }

        .large-timer {
          &__list {
            margin: 0 2px;

            &__box {
              width: 46px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .comingsoon-view {
    padding: 30px 0;

    &_title {
      font-size: $fs-32;
    }
  }
  .comingSoon-container {
    width: 95%;
    text-align: center;

    &_title {
      font-size: $fs-32;
    }

    .comingSoon-wrapper {
      width: 100%;
      padding: 0 30px 30px;
      margin-top: 160px;
      display: block;

      &_img {
        top: -130px;
        left: calc((100% - 260px) / 2);
        width: 260px;
        height: 260px;
      }

      &_right {
        margin-left: unset;
        padding-top: 150px;

        &_header {
          margin-top: unset;
          width: 102%;
          font-size: $fs-32;
          text-align: center;
        }

        &_btn {
          margin-top: 30px;
          margin-bottom: unset;
        }
      }
    }

    .large-timer {
      text-align: center;

      &__list {
        margin: 0 3px;
      }
    }
  }
  body[dir='rtl'] {
    .comingSoon-container {
      .comingSoon-wrapper {
        padding: 0 30px 30px;

        &_img {
          right: calc((100% - 260px) / 2);
        }

        &_right {
          margin-right: unset;
          padding-top: 150px;
        }

        display: block;
      }

      .large-timer {
        text-align: unset;

        &__list {
          margin: 0 3px;

          &:first-child {
            margin-right: 0;
            margin-left: 3px;
          }

          &:last-child {
            margin-left: 0;
            margin-right: 3px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 395px) {
  .comingSoon-container {
    width: 97%;

    .comingSoon-wrapper {
      padding: 0 25px 30px;

      &_right {
        &_header {
          font-size: $fs-27;
        }
      }

      .large-timer {
        &__list {
          margin: 0 2px;
        }
      }
    }
  }
  body[dir='rtl'] {
    .comingSoon-container {
      .comingSoon-wrapper {
        padding: 0 25px 30px;
      }
    }
  }
}
