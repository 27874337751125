.seller-list {
  margin: 15px 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 92%;
    flex-wrap: wrap;
  }

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__img-container {
    display: inline-block;
    width: 8%;
    cursor: pointer;

    img {
      border-radius: 50%;
    }
  }

  &__start-container {
    display: flex;
    margin: 0 0 0 10px;
    gap: 4px 0;
    flex-direction: column;
  }

  &__end-container {
    text-align: start;
    gap: 10px 0;
    display: flex;
    flex-direction: column;
  }

  &__name {
    color: $color-black-true;
    font-size: $fs-16;
    font-weight: bold;
    text-transform: capitalize;
    cursor: pointer;
  }

  &__title {
    font-size: 11px;
    color: $color-gray-500;
  }

  &__description {
    font-size: $fs-12;
    color: $color-grey-3900;
    font-weight: 500;
    text-align: end;

    b {
      font-weight: 700;
    }
  }

  &__button {
    width: 48%;
  }

  &__Verified {
    display: flex;
    align-items: center;
    gap: 0 6px;

    .icon-Verified {
      font-size: $fs-20;
      color: $color-green-100;
    }

    .question-mark{
      color: $color-gray-icon;
      font-size: $fs-16;
    }

    p {
      font-size: $fs-12;
      color: $color-grey-3900;
      font-weight: 600;
      line-height: normal;
    }
  }

  &__view-profile {
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: end;

    p {
      font-size: $fs-12;
    }

    .icon-profile,
    .icon-star_empty {
      font-size: 16px;
      color: $color-gray-icon;
    }
    .icon-star_empty{
      margin-right: 5px;
    }
  }

  &__Chat {
    display: flex;
    flex-direction: row;
    gap: 0 6px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 0 20px;
  }
}

body[dir='rtl'] {
  .seller-list {
    &__view-profile {
      .icon-profile,
      .icon-star_empty {
        margin-right: initial;
      }
      .icon-star_empty {
        margin-left: 5px;
      }
    }

    &__start-container {
      margin: 0 12px 1.7px 1.7px;
    }

    &__end-container {
      text-align: -webkit-left;
      text-align: left;
      text-align: -moz-left;
    }
  }
}

@media only screen and (max-width: 560px) {
  .seller-list {
    &__img-container {
      width: 15%;
    }

    &__content {
      width: 85%;
      gap: 8px 0;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: flex-start;
    }

    &__end-container {
      margin: 0;
    }

    &__start-container {
      margin: 0;
    }

    &__container {
      align-items: flex-start;
    }

    &__actions {
      display: none;
    }
  }
}

@media only screen and (max-width: 1170px) and (min-width: 1025px) {
  .seller-list {
    &__img-container {
      width: 15%;
    }

    &__content {
      width: 85%;
    }

    &__end-container {
      margin: 0;
    }

    &__start-container {
      margin: 0;
    }

    &__description {
      text-align: start;
    }
  }
}

@media only screen and (max-width: 912px) and (min-width: 560px) {
  .seller-list {
    display: block;

    &__button-group {
      justify-content: space-between;
    }

    &__view-profile {
      justify-content: unset;
    }

    &__container {
      align-items: flex-start;
      margin-bottom: 12px;
    }

    &__end-container {
      justify-content: space-around;
    }
  }
}

@media only screen and (max-width: 425px) {
  .seller-list {
    &__content {
      margin: 0 0 0 20px;
    }
  }
}
