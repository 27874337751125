.answers__container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.answers-content {
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  width: 80%;
}

.border-left {
  background-color: $color-grey-2300;
  width: 1%;
  z-index: 1;
  width: 1px;
  display: flex;
}

.border-left.last-one {
  margin-bottom: 56%;
}

.border-left.only-one {
  display: none;
}

.answers__container {
  .answer-list {
    &__avatar.only-one {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        border: 0 solid transparent;
        border-radius: 0 0 0 10px;
        left: -20px;
        width: 20px;
        height: 70px;
        top: -50px;
        border-bottom: 1px solid $color-grey-2300;
        border-left: 1px solid $color-grey-2300;
      }
    }
  }
}

.answer-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__avatar {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 10px;
    align-items: center;
    position: relative;

    &::before {
      content: '';
      position: relative;
      border: 0 solid transparent;
      border-radius: 0 0 0 10px;
      left: -20px;
      width: 20px;
      height: 16px;
      top: 0;
      border-bottom: 1px solid $color-grey-2300;
      border-left: 1px solid rgba($color-grey-2300, 1%);
    }

    img {
      width: 32px;
      height: 32px;
      margin: 0px 10px 0px 0;
      border-radius: 50%;
    }
  }

  &__box {
    background-color: $color-jungle-900;
    border-radius: 0px 10px 10px 10px;
    padding: 12px;
    color: $color-black-true;
    font-size: $fs-14;
    font-weight: 600;
    img{
      width:100%;
      border-radius: 5px;
    }
  }

  &__box:before {
    content: '';
    position: absolute;
    left: -8px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $color-jungle-900;
  }

  &__small-Text {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 25px;
    margin-top: 10px;

    &__name {
      font-size: $fs-15;
      color: $color-black-true;
      line-height: 1.6;
      letter-spacing: -0.12px;
      text-align: start;
      font-weight: 600;
    }

    &__time {
      font-size: $fs-11;
      color: $color-gray-200;
      padding: 0 0.25rem;
      font-weight: 500;
    }
  }
}

body[dir='rtl'] {
  .answers__container {
    .border-left.last-one {
      margin-bottom: 58%;
    }

    .answers-content {
      margin-right: 18px;
    }

    .answer-list {
      &__box {
        border-radius: 25px 25px 0 25px;
      }

      &__box:before {
        left: initial;
        right: -3px;
      }

      &__small-Text {
        margin-right: 25px;
      }

      &__avatar.only-one {
        &::before {
          border-right: 1px solid $color-grey-2300;
        }
      }

      &__avatar {
        &::before {
          content: '';
          border-radius: 0px 0px 10px 0px;
          right: -22px;
          border-right: 1px solid rgba($color-grey-2300, 1%);
          border-left: none;
        }

        img {
          margin: 0px 0px 0px 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 786px) {
  .answers-content {
    width: 100%;
  }
}
