.collapsible {
  user-select: none;

  &__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $color-grey-1000;
    transition: 200ms;
    margin-bottom: 16px;
    cursor: pointer;
    &.working-hours {
      margin-bottom: 20px;
    }

    &__label {
      font-weight: bold;
      color: $color-grey-3900;
      font-size: $fs-16;
      padding-bottom: 10px;
    }

    &__icons {
      cursor: pointer;
      line-height: 10px;
    }

    &__icon {
      font-size: $fs-16;
      color: $color-gray-200;
      transition: 200ms;
    }
  }
}

@media only screen and (max-width: 716px) {
  .collapsible {
    &__group {
      &__label {
        padding: 10px 0 10px 0;
      }
    }
  }
}
