.flagsDropdown-container {
  position: relative;
  margin-top: -5px;

  &_wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  p {
    font-weight: 500;
    font-size: $fs-14;
    direction: initial;
  }

  .popover-wrapper {
    left: 0;
    top: 38px;
    width: 270px;
    box-shadow: 0 6px 12px 0 $color-grey-3000;

    &::before {
      right: 17rem;
    }
  }
}

body[dir='rtl'] {
  .flagsDropdown-container {
    .popover-wrapper {
      &::before {
        right: 1rem;
        left: unset;
      }
    }
  }
}

.code-wrapper {
  display: flex;
  cursor: pointer;
  gap: 10px;

  &_img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.menu-wrapper {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &_img {
    width: 20px;
    border-radius: 50%;
  }

  &_code,
  &_country-name {
    font-size: $fs-12;
    color: $color-grey-3900;
    font-weight: 600;
  }

  &_code {
    margin-left: 10px;
  }

  &_country-name {
    margin-left: 30px;
  }

  &:hover {
    opacity: 0.6;
  }
}

@media screen and (max-width: 912px) {
  .flagsDropdown-container {
    &_wrapper {
      gap: 10px;
    }

    .code-wrapper {
      &_img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
  }
}

body[dir='rtl'] {
  .flagsDropdown-container {
    .popover-wrapper {
      right: 0;
    }
  }

  .menu-wrapper {
    &_code {
      margin-left: unset;
      margin-right: 10px;
      direction: initial;
    }

    &_country-name {
      margin-left: unset;
      margin-right: 30px;
    }
  }
}
