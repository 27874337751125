.security-banner {
  align-items: center;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 14px 6px;
  display: flex;
  justify-content: space-between;



  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .security-banner__words {
    font-size: $fs-12;
    font-weight: 600;
    color: $color-black-true;
  }

  &__icon {
    margin-right: 3px;
  }

  .question-mark{
    color: $color-gray-200;
    font-size: $fs-14;
  }

  &__words {
    font-size: $fs-12;
    font-weight: 500;
    margin-bottom: unset;
  }

  .mazadat {
    text-transform: capitalize;
    font-weight: 700;
    margin-left: 3px;
  }
}

.security-banner--secure {
  background-color: $color-jungle-900;

  .security-banner__icon {
    width: 24px;
  }

  .mazadat {
    color: $color-black-true;
  }
}

.security-banner--not-secure {
  background-color: $color-grey-1600;

  .security-banner__icon {
    width: 24px;
    height: 24px;
  }

  .mazadat {
    color: $color-black-true;
  }
}

.security-banner--not-secure {
  background-color: $color-grey-1600;

  .security-banner__icon {
    width: 24px;
    height: 24px;
  }

  .mazadat {
    color: $color-grey-2300;
  }
}

body[dir="rtl"] {
  .security-banner {
    .mazadat {
      margin-left: initial;
      margin-right: 4px;
    }

    &__icon {
      margin-right: 0px;
      margin-left: 3px;
    }

    &__words {
      display: flex;
      align-items: center;
      flex-grow: 0.5;
    }

    a {
      margin-right: 3px;
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 912px) {

  .security-banner {
    padding: 7px 10px;

    &__words {
      font-size: $fs-13;
    }
  }
}

@media only screen and (max-width: 425px) {
  .security-banner {
    margin-left: 0;
    margin-right: 0;
  }
}