.button {
  position: relative;
  border: 1px solid transparent;
  border-radius: 15px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-size: $fs-14;
  color: $color-white-true;
  font-weight: 600;
  transition: 200ms;

  &:focus {
    outline: 0;
  }
}

button:disabled,
button[disabled],
button:disabled:hover,
button[disabled]:hover {
  background-color: $color-grey-1000;
  color: $color-grey-4000;
  cursor: unset;

  p,
  small,
  sup,
  span {
    color: $color-grey-2300;
  }
}

// size
.button--medium {
  padding: 16px 20px;
}

.button--small {
  padding: 8px 10px;
  font-weight: 500;
  font-size: $fs-12;
  border-radius: 5px;
  height: unset;
}

.button--fullWidth {
  display: block;
  width: 100%;
}

// Palette
.button--primary {
  background-color: $color-primary;
}
.button--primary:hover {
  background-color: $color-jungle-1400;
}

.button--primary-bordered {
  background-color: $color-white-true;
  border: 1.5px solid $color-primary;
  color: $color-primary;

  &[disabled] {
    background-color: $color-white-true;
    border-color: $color-grey-4000;
  }
}

.button--primary-bordered:hover {
  background-color: $color-blue-900;
}

.button--secondary {
  background-color: $color-secondary;
}
.button--secondary:hover {
  background-color: $color-blue-2100;
}

.button--secondary-bordered {
  background-color: $color-white-true;
  border-color: $color-secondary;
  color: $color-secondary;

  &[disabled],
  &[disabled]:hover {
    background-color: $color-white-true;
    border-color: $color-grey-1000;
    color: $color-grey-1000;
  }

  &:hover {
    background-color: $color-gray-800;
  }
}

.button--primary-medium {
  background-color: $color-jungle-900;
  color: $color-jungle-100;
}

.button--primary-medium:hover {
  background-color: $color-jungle-1200;
}

.button--secondary-medium {
  background-color: $color-secondary-medium;
}

.button--main {
  background-color: $color-main;
  color: $color-black-true;
}

.button--main:hover {
  background-color: $color-grey-2900;
}

.button--support-primary {
  background-color: $color-support-primary;
}

.button--support-secondary {
  background-color: $color-support-secondary;
}

.button--light {
  background-color: $color-white-true;
  color: $color-primary;
}
