.reset-password {
  padding: 40px 0px;
  &__heading {
    font-size: $fs-32;
    font-weight: bold;
    line-height: 1.5;
    color: $color-grey-3900;
    text-align: center;
    margin-bottom: 30px;
  }
  &__info {
    font-size: $fs-16;
    font-weight: 500;
    color: $color-grey-3900;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 37px;
  }
  &__form {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 550px) {
  .reset-password {
    padding: 0;
    &__heading {
      font-size: $fs-21;
    }
    &__info {
      display: none;
    }
  }
}
