.discount-small-view {
  display: inline-flex;
  align-items: center;

  .old-price-percentage-con {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__old-price {
    font-weight: bold;
    line-height: normal;
  }

  &__percentage {
    font-weight: 600;
    color: $color-discount;
    margin: 0;

    sup {
      font-weight: 700;
    }
  }

  &__value {
    display: flex;
    align-items: center;

    &__old-price {
      font-weight: bold;
      line-height: normal;
      display: none;
    }

    &__save {
      background-color: $color-green-500;
      color: $color-green-100;
      border-radius: 3px;
      width: 32px;
      height: 19px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__label {
      font-weight: bold;
      color: $color-green-100;
      line-height: normal;
    }
  }
}

.discount-small-view--sm {
  .discount-small-view__percentage {
    font-size: $fs-12;

    sup {
      font-size: $fs-8;
      top: -3px;
    }
  }

  .discount-small-view__value {
    &__old-price {
      font-size: $fs-16;
      margin: 0 2px;
    }

    &__save {
      font-size: $fs-10;
    }

    &__label {
      font-size: $fs-16;
      margin: 0 4px;
      display: flex;
      line-height: 1;
    }

    &__currency {
      font-size: $fs-10;
      top: 6px;
      right: 1px;
    }
  }
}

.discount-small-view--medium {
  .discount-small-view__percentage {
    font-size: $fs-16;

    sup {
      font-size: $fs-10;
      top: -3px;
    }
  }

  .discount-small-view__old-price {
    font-size: $fs-16;
    margin: 0 4px;
  }

  .discount-small-view__value {
    &__old-price {
      font-size: $fs-16;
      margin: 0 4px;
    }

    &__save {
      font-size: $fs-10;
      line-height: 2px;
      margin: 0 4px;
    }

    &__label {
      font-size: $fs-16;
      margin: 0 2px;
      display: flex;
      line-height: 1;
    }

    &__currency {
      font-size: $fs-10;
      top: 6px;
      right: 2px;
    }
  }
}

.discount-small-view--light {
  .discount-small-view__value__old-price,
  .discount-small-view__old-price {
    color: $color-grey-4000;
    text-decoration-color: $color-grey-4000;
  }
}                     

.discount-small-view--primary,
.discount-small-view--secondary {
  .discount-small-view__value__old-price,
  .discount-small-view__old-price {
    color: $color-white-600;
    text-decoration-color: $color-white-800;
  }
}

body[dir='rtl'] {
  .discount-small-view--medium {
    .discount-small-view__percentage sup {
      right: 3px;
    }

    .discount-small-view__value__save {
      margin: 0 4px;

      sub {
        left: 2px;
      }
    }
    .discount-small-view__value__label{
      margin: 0;
    }
  }
 .discount-small-view__value__currency{
    order: 2;
    top:4.5px;
  }
}
