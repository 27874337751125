.show-more{
    &__list{
        border-bottom: 1px solid $color-grey-2300;
        display: flex;
        align-self: center;
        justify-content: center;
        button{
            min-height: 47px;
            width: 100%;
            color: $color-black-true;
            font-size: $fs-13;
            font-weight: 600;
            transition: 200ms;
        }
    }
    &__list button:hover{
        background-color: $color-blue-900;
    }
    &__list:last-child{
        border-bottom:none;
    }
}